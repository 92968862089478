import React, { useEffect, useState } from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, Grid, Link, Pagination, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
import { useHistory } from 'react-router-dom';
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import { fetchUser, saveUserMobileNo, setAllUserFilter, setUserActionType, setUserMasterData } from "./redux/actions/user-action";
import { connect } from "react-redux";
import { setHeaderTitle } from '../../store/actions/rootActions';


function UsersMaster({ all_user,all_user_filter, fetchUser, setUserActionType, setUserMasterData, setAllUserFilter, saveUserMobileNo, setHeaderTitle }) {
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
    const [currentPage, setCurrentPage] = useState(0);


    useEffect(() => {
        
        if (searchText === "") {
            setAllUserFilter(all_user);
            setPage(currentPage);
        } else {
            setPage(0);
            const filteredItems = all_user?.filter((item) =>
                item?.name?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
                item?.email?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
                item?.mobile_number.includes(searchText));
                setAllUserFilter(filteredItems);
        }
    }, [all_user, searchText])

    const handleSearch = (e) => {
        const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
        setSearchText(searchTerm, "searchValue");
    };


    const handleChangePage = (newPage) => {
        setPage(newPage);
        setCurrentPage((prevPage) => {
            if(prevPage === 0) {
                return newPage
            } else {
                return prevPage
            }
        });
    };

    const history = useHistory();

    const handleCreateButtonClick = () => {
        setUserActionType(1);
        setHeaderTitle("Create User");
        history.push('/create-users'); // Navigate to Createrole page
    };


    useEffect(() => {
        fetchUser();
    }, [])


    const handleEdit = (row) => {
        console.log("row_updated_data:", row)
        setUserMasterData(row);
        setUserActionType(0);
        saveUserMobileNo(row.mobile_number);
        setHeaderTitle("Edit User");
        history.push('/create-users');

    }



    return (
        <SideBarContent>
            <Box className="custom-card-bg">
                <Box className="mb-4">
                    <Grid container spacing={2} className="align-items-center just-content-between">
                        <Grid item xs={10} md={6} >
                            <TextField
                                sx={{ height: "50px", width: '400px', borderRadius: '6px' }}
                                variant="outlined"
                                placeholder="Search here..."
                                value={searchText}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <Search
                                            sx={{ width: "1em", height: "1em", marginRight: "8px", color: '#864FFD' }}
                                            color="action"
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={"auto"} className="ms-auto">
                            <Button className="btn-create" onClick={handleCreateButtonClick}>
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Paper sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: "450px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    {/* <TableCell>UserName</TableCell> */}
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Roles</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {all_user_filter?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row?.id}>
                                        <TableCell>
                                            {row?.name}
                                        </TableCell>
                                        <TableCell>
                                            {row?.email}
                                        </TableCell>
                                        <TableCell>
                                            {row?.mobile_number}
                                        </TableCell>
                                        <TableCell>
                                            {row?.roles[0]?.name}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography className={row?.is_active ? 'text-status-active' : 'text-status-inactive'}>{row?.is_active ? "Active" : "Inactive"}</Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button onClick={() => {
                                                handleEdit(row)
                                            }
                                            }><EditIcon /></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <CustomPagination page={page} count={all_user_filter?.length || 0} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
                </Paper>
            </Box>
        </SideBarContent>
    );
}

const mapStateToProps = (state) => {
    const { all_user, all_user_filter } = state.user;
    return {
        all_user,
        all_user_filter
    }
}


export default connect(mapStateToProps, { fetchUser, setUserActionType, setUserMasterData, setAllUserFilter, saveUserMobileNo, setHeaderTitle })(UsersMaster);
