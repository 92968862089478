
import api from "./configs/axiosConfig";

const SocialMedia = '/socialmediamaster/';

// const postSocialMedia = '/socialmediamaster';

// const putSocialMedia = '/socialmediamaster';


export const SocialMediaAPI = {
    getSocialMedia: async function (){
        const response = await api.get(SocialMedia);
        // console.log("getSocial links", response)
        // dispatch({type:GET_SOCIAL_MEDIA_SUCCESS, payload: response.data.data})
        return response;
    },

    createSocialMedia: async function(data){
        const response = await api.post(SocialMedia,data);
        return response;
    },

    updateSocialMedia: async function(id, updated_data){
        const response = await api.put(SocialMedia + id,updated_data);
        return response;
    },

    deleteSocialMedia: async function(){
        const response = await api.delete(SocialMedia,{
            
        });
        return response
    }
}