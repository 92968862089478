
import { GET_SOCIAL_MEDIA_REQUEST, GET_SOCIAL_MEDIA_SUCCESS, GET_SOCIAL_MEDIA_FAILURE, POST_SOCIAL_MEDIA_SUCCESS, SET_SOCIAL_ACTION_TYPE, SET_SOCIAL_MEDIA_OBJECT, CLEAR_SOCIAL_MEDIA_OBJ, SET_SOCIAL_LINKS_FILTER, RESET_SOCIAL_LINK } from "../types";


// Get social all media api reducer

const initial_state = {
    loading: false,
    all_links: [],
    error: "",
    media: null,
    action_Type: 1, // 1 is for create and 0 is for edit,
    socialMediaObj: { media_name: '', is_active: true, media_image_path: null },
    all_link_filter: []
}


const socialmediamasterReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_SOCIAL_MEDIA_REQUEST:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_SOCIAL_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                all_links: action.payload,
                error: ""
            };

        case GET_SOCIAL_MEDIA_FAILURE:
            return {
                ...state,
                all_links: [],
                error: action.payload
            };

        case POST_SOCIAL_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                media: action.payload
            }

        case SET_SOCIAL_ACTION_TYPE:
            return {
                ...state,
                action_Type: action.payload
            }
        case SET_SOCIAL_MEDIA_OBJECT:
            return { ...state, socialMediaObj: action.payload }
        case CLEAR_SOCIAL_MEDIA_OBJ:
            return {...state, socialMediaObj: initial_state.socialMediaObj}
        
        case SET_SOCIAL_LINKS_FILTER:
            return {
                ...state,
                all_link_filter: action.payload
            }
        case RESET_SOCIAL_LINK:
            return initial_state
        default:
            return state;
    }
};



// Post social all media api reducer

export default socialmediamasterReducer;