import { Vendors } from '../../../../apis/vendorApi';
import { SET_LOADING } from '../../../../store/types';
import { GET_B2B_DATA, GET_B2B_USER } from '../types';
export const getVendors = () => async (dispatch) => {
  const vendorType = 0;
  const response_vendor = await Vendors.GetVendors(vendorType);
  if (response_vendor.status === 200) {
    dispatch({ type: GET_B2B_DATA, payload: response_vendor?.data?.data });
  } else {
    dispatch({ type: SET_LOADING, payload: false });
  }
};
export const getVendorDetails = (reg_id) => async (dispatch) => {
  const response_vendor = await Vendors.GetVendorDetail(reg_id);
  if (response_vendor.status === 200) {
    dispatch({ type: GET_B2B_USER, payload: response_vendor?.data?.data });
  } else {
    dispatch({ type: SET_LOADING, payload: false });
  }
};
export const updateVendorStatus = (reg_id, status, successCallback=() => {}) => async (dispatch) => {
  const response_vendor = await Vendors.updateVendorStatus(reg_id, status);
  successCallback();
  // dispatch({ type: GET_B2B_USER, payload: response_vendor.data })
  dispatch({ type: SET_LOADING, payload: false });
};

export const deleteVendor = (user_id, sucess, error) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });
    const response_vendor = await Vendors.deleteVendor(user_id);
    console.log('==response_vendor', response_vendor);
    sucess(response_vendor.data?.message);
    // dispatch({ type: GET_B2B_USER, payload: response_vendor.data })
    dispatch({ type: SET_LOADING, payload: false });
  } catch (error) {
    error(error);
    dispatch({ type: SET_LOADING, payload: false });
  }
};
//GetVendorDetail
