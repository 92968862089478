import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import AddIcon from '../../assets/add-icon-white.svg';
import { ReactComponent as WidgetImg } from '../../assets/widget-img.svg';
import { ReactComponent as ProductImage1 } from '../../assets/product1.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as WidgetCheckIcon } from '../../assets/widget-check-icon.svg';
import useValidation from '../../hooks/useValidation';
import AddWidgetModal from './AddWidgetModal';
import { BASE_URL } from '../../apis/configs/axiosConfig';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';

import { useHistory, useLocation } from 'react-router';
import {
  addSingleWidget,
  postWidget,
  postWidgetDetails,
  getWidgetDetails,
  saveSelectedWidgetDetails,
  editWidget,
  deleteWidget,
  clearWidget,
  addSingleModuleWidget,
  putWidgetDetails,
  getAllWebcomponent,
} from './redux/actions/widget-action';
import { connect } from 'react-redux';
import { IMAGE_BASE_URL } from '../../apis/endpoints';
import { ModalStyle } from '../../utils/constants';
import { hasAnyErrorPresent } from '../../utils/validation';
import { ReactComponent as TileImg1 } from '../../assets/tile-img1.svg';
import { SET_LOADING } from '../../store/types';
// const getDefaultTemplateName = () => {};
// widget_name: 'widget_name',
// widget_type: 'widget_type',
// template: 'template',
const predefinedKeys = {
  title: 'title',
  sub_title: 'sub_title',
  button_text: 'button_text',
  button_src: 'button_src',
  widget_bannerSeqNumber: 'widget_bannerSeqNumber',
  image: 'image',
  page_name: 'page_name',
};
function CreateWidgetMaster({
  addSingleWidget,
  createWidget,
  postWidget,
  postWidgetDetails,
  getWidgetDetails,
  saveSelectedWidgetDetails,
  editWidget,
  deleteWidget,
  clearWidget,
  addSingleModuleWidget,
  putWidgetDetails,
  getAllWebcomponent,
  pushMessage,
}) {
  const { errors, validateInput, setErrors } = useValidation();
  // const history = useHistory();
  const location = useLocation();
  // const [openDelete, setOpenDelete] = React.useState(false);
  const isFromEditPage = location.state?.isFromEditPage;
  const [isEditMode, setEditMode] = React.useState(isFromEditPage);
  const single_widget_details = createWidget.single_widget_details;
  const widgetId = createWidget.single_widget?.id;
  const selected_widget_details = createWidget?.selected_widget_details;
  const allWidget_length = single_widget_details?.length;
  console.log('createWidget?.selected_widget_details45', createWidget);
  // const selectedWidgetId = selected_widget_details?.id;
  const [deleteopenModal, setDeleteOpenlModal] = useState(false);
  const handleDeleteOpenModal = () => setDeleteOpenlModal(true);
  const handleCloseDeletModule = () => setDeleteOpenlModal(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (!isFromEditPage) {
      if (createWidget.single_widget?.is_active) setIsActive(true);
      addSingleWidget({ widget_type: 'cards' });
      addSingleWidget({ template: 'template1' });
    } else {
      addSingleWidget({
        template: createWidget?.single_widget_details[0]?.template,
      });
    }
    getAllWebcomponent();
    if (createWidget.single_widget?.is_active) {
      setIsActive(true);
    }
  }, []);
  const onSucess = (message) => {
    console.log('onSucess', message);
    pushMessage(message);
  };
  const onError = (message) => {
    pushMessage(message);
  };
  const handleEditWidgetPopupSave = async () => {
    const {
      image = '',
      title = '',
      // sub_title = '',
      button_text = '',
      button_src = '',
      // rank = 0,
    } = createWidget?.single_module_widget ?? {};
    const widget_name = createWidget?.single_widget?.widget_name;
    const widget_type = createWidget?.single_widget?.widget_type;
    // const template = createWidget?.single_widget?.template;
    if (
      !widget_name ||
      !image ||
      !button_src ||
      // !rank ||
      (widget_type !== 'tiles' && !button_text)
      // (widget_type !== 'tiles' && !title)
      // ||
      // !template
    ) {
      console.log('==if');
      if (!widget_name) validateInput('widget_name');
      if (!widget_type) validateInput('widget_type');
      if (!image) validateInput('image');
      // if (!title) validateInput('title');
      // if (!sub_title) validateInput('sub_title');
      if (!button_text) validateInput('button_text');
      if (!button_src) validateInput('button_src');
      // if (!rank) validateInput('rank');
      // if (!template) validateInput('template');
    } else {
      console.log('==else');
      // Assuming errors and hasAnyErrorPresent are defined elsewhere in your code
      if (!hasAnyErrorPresent(errors)) {
        const form = new FormData();
        form.append('widget_name', widget_name);
        form.append('widget_type', widget_type);
        // form.append('template', template);
        form.append('image', image);
        form.append('title', title);
        // form.append('sub_title', sub_title);
        form.append('button_text', button_text);
        form.append('button_src', button_src);
        // form.append('rank', rank);

        await editWidget(selected_widget_details.id, form, onSucess, onError);
        await getWidgetDetails(createWidget.single_widget.id);
        clearWidget();
        saveSelectedWidgetDetails({});
        handleCloseModal();
      } else {
        console.log('Errors present:', errors);
      }
    }
  };

  const handleWidgetPopupSave = async () => {
    // console.log('handleWidgetPopupSave', createWidget);

    const {
      image = '',
      title = '',
      button_text = '',
      button_src = '',
      page_name,
    } = createWidget?.single_module_widget ?? {};
    // console.log('handleWidgetPopupSave', createWidget.single_widget.page_name);
    console.log(
      'value===',
      createWidget?.single_widget?.widget_type,
      button_text
    );
    if (
      !createWidget?.single_widget?.widget_name ||
      !image ||
      !button_src ||
      !createWidget.single_widget.page_name ||
      (createWidget?.single_widget?.widget_type !== 'tiles' && !button_text)
    ) {
      console.log('==if');
      if (!createWidget.single_widget.widget_name) {
        validateInput('widget_name');
      }
      if (!createWidget?.single_widget?.widget_type) {
        validateInput('widget_type');
      }
      if (!image) {
        validateInput(predefinedKeys.image);
      }
      if (!title) {
        validateInput(predefinedKeys.title);
      }
      if (!page_name) {
        validateInput(predefinedKeys.sub_title);
      }
      if (!button_text) {
        validateInput(predefinedKeys.button_text);
      }
      if (!button_src) {
        validateInput(predefinedKeys.button_src);
      }
      // if (!widget_bannerSeqNumber) {
      //   validateInput(predefinedKeys.widget_bannerSeqNumber);
      // }
      if (!createWidget.single_widget.template) {
        validateInput('template');
      }
    } else {
      console.log('==else');
      // if (!hasAnyErrorPresent(errors)) {
      if (isEditMode) {
        let form = new FormData();
        form.append('widget_name', createWidget.single_widget.widget_name);
        form.append('widget_type', createWidget.single_widget.widget_type);
        form.append('widgetId', createWidget.single_widget.id);
        form.append('template', createWidget.single_widget.template);
        form.append('image', image);
        // form.append('is_active', isActive);
        form.append('title', title);
        // form.append('sub_title', sub_title);
        form.append('button_text', button_text);
        form.append('button_src', button_src);
        // form.append('rank', widget_bannerSeqNumber);
        await postWidgetDetails(form, onSucess, onError);
        await getWidgetDetails(createWidget.single_widget.id);
        clearWidget();
        saveSelectedWidgetDetails(null);
        handleCloseModal();
      } else {
        console.log('===createWidget.single_widget');
        await postWidget(
          {
            widget_name: createWidget.single_widget.widget_name,
            widget_type: createWidget.single_widget.widget_type,
            // widget_color: createWidget.single_widget.widget_color,
            page_name: createWidget.single_widget.page_name,
            // template: createWidget.single_widget.template,
            is_active: isActive,
          },
          async (res) => {
            onChangeOfWidgetKeys('id', res.data.id);
            let form = new FormData();
            form.append('widget_name', createWidget.single_widget.widget_name);
            form.append('widget_type', createWidget.single_widget.widget_type);
            form.append('widgetId', res.data.id);
            form.append('template', createWidget.single_widget.template);
            form.append('image', image);
            form.append('title', title);
            // form.append('sub_title', sub_title);
            form.append('button_text', button_text);
            form.append('button_src', button_src);
            // form.append('rank', widget_bannerSeqNumber);
            const response = await postWidgetDetails(form);
            await getWidgetDetails(res.data.id);
            console.log('===response6734', response);
            if (response) {
              setEditMode(true);
              pushMessage('Added successfully');
            }
            // dispatch({type: SET_LOADING, payload: false});
            clearWidget();
            saveSelectedWidgetDetails(null);
            handleCloseModal();
          },
          (err) => {
            // dispatch({type: SET_LOADING, payload: false});
            console.log('postWidget error', err);
            clearWidget();
            saveSelectedWidgetDetails(null);
            handleCloseModal();
          }
        );
      }
      // }
      // else {
      //   console.log('error', errors);
      // }
    }
  };

  // const [isChecked, setIsChecked] = useState(1);
  // const [borderColor, setBorderColor] = useState('#CCCCCC');
  const [isActive, setIsActive] = useState(false);

  const handleIsActiveCheckbox = async () => {
    setIsActive((prev) => {
      const newIsActive = !prev;
      console.log('==createWidget.single_widget', createWidget.single_widget);
      putWidgetDetails(
        createWidget.single_widget.id,
        {
          is_active: newIsActive,
        },
        onSucess
      );

      return newIsActive;
    });
  };

  // const handleCardClick = (value) => {
  //   setIsChecked(value);
  //   onChangeOfWidgetKeys('template', `template${value}`);
  //   setBorderColor(isChecked ? 'CCCCCC' : '#1843A0');
  // };
  const [openModal, setOpenModal] = useState(false);
  const onChangeOfWidgetKeys = (id, value) => {
    console.log('onChangeOfWidgetKeys 456', id, value);
    if (id && value) {
      validateInput(id, value);
      addSingleWidget({ [id]: value });
    }
  };
  const onChangeOfModuleWidgetKeys = (id, value) => {
    console.log('onChangeOfModuleWidgetKeys', id, value);
    validateInput(id, value);
    if (id && value) {
      addSingleModuleWidget({ [id]: value });
    }
  };
  //addSingleModuleWidget
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    saveSelectedWidgetDetails({});
    setOpenModal(false);
    setErrors(null);
    clearWidget();
  };
  const onEditDetails = (details) => {
    addSingleModuleWidget(details);
    saveSelectedWidgetDetails(details);
    handleOpenModal();
  };
  const [deleteDetailId, setDeletedeleteDetailId] = React.useState();
  const onDeleteDetails = async (id) => {
    setDeletedeleteDetailId(id);
    handleDeleteOpenModal();
  };
  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Modal
          open={deleteopenModal}
          onClose={handleCloseDeletModule}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure want to delete it?
            </Typography>
            <div style={{ float: 'right' }}>
              <Button
                onClick={async () => {
                  await deleteWidget(deleteDetailId);
                  await getWidgetDetails(createWidget.single_widget?.id);
                  handleCloseDeletModule();
                }}
              >
                Yes
              </Button>
              <Button onClick={handleCloseDeletModule}>No</Button>
            </div>
          </Box>
        </Modal>
        <Box className="mb-4">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 10 }}
            className="align-items-center justify-content-between"
          >
            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputPlanName"
              >
                Widget Name
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="widget_name"
                  type="text"
                  disabled={!!isEditMode}
                  defaultValue={
                    isEditMode ? createWidget?.single_widget?.widget_name : ''
                  }
                  placeholder="Widget Name"
                  required
                  autoFocus
                  fullWidth
                  onChange={(event) =>
                    onChangeOfWidgetKeys('widget_name', event.target.value)
                  }
                  margin="none"
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.widget_name}
              </FormHelperText>
            </Grid>
            {/* <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputPlanName"
              >
                Widget Color
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="widget_color"
                  type="text"
                  disabled={!!isEditMode}
                  defaultValue={
                    isEditMode ? createWidget?.single_widget?.widget_color : ''
                  }
                  placeholder="widget color"
                  required
                  autoFocus
                  fullWidth
                  onChange={(event) =>
                    onChangeOfWidgetKeys('widget_color', event.target.value)
                  }
                  margin="none"
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.widget_color}
              </FormHelperText>
            </Grid> */}
            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputPlanName"
              >
                Banner Name
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="page_name"
                  type="text"
                  disabled={!!isEditMode}
                  defaultValue={
                    isEditMode ? createWidget?.single_widget?.page_name : ''
                  }
                  placeholder="banner name"
                  required
                  autoFocus
                  fullWidth
                  onChange={(event) =>
                    onChangeOfWidgetKeys('page_name', event.target.value)
                  }
                  margin="none"
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.page_name}
              </FormHelperText>
            </Grid>
            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputProductCategory"
              >
                Banner Type
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }}>
                <Select
                  id="widget_type"
                  inputProps={{ 'aria-label': 'Without label' }}
                  disabled={!!isEditMode}
                  // value={String(widget_type).toLowerCase()}
                  defaultValue={
                    isEditMode
                      ? createWidget.single_widget?.widget_type
                      : 'cards'
                  }
                  onChange={(event) => {
                    onChangeOfWidgetKeys('widget_type', event.target.value);
                  }}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="cards">Cards</MenuItem>
                  <MenuItem value="tiles">Tiles </MenuItem>
                </Select>
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.widget_type}
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <FormControlLabel
            control={
              <Checkbox
                // checked={isActive}
                checked={isActive}
                onChange={handleIsActiveCheckbox}
                color="primary"
              />
            }
            label="Active"
          />
        </Box>

        {/* <Box>
          <Typography className="fs-18 fw-bold font-skmodernist">
            Select{' '}
            {createWidget?.single_widget?.widget_type === 'cards' ||
            !createWidget?.single_widget?.widget_type
              ? 'Card'
              : 'Tile'}{' '}
            Widget
          </Typography>
          <Divider className="personal-divider mt-2 mb-3"></Divider>
          {createWidget?.single_widget?.widget_type === 'cards' ||
          !createWidget?.single_widget?.widget_type ? (
            <Grid
              container
              rowSpacing={4}
              columnSpacing={{ xs: 1, sm: 2, md: 4 }}
              className="align-items-center justify-content-between mb-4"
            >
              <Grid item xs={10} md={6}>
                <Card
                  sx={{
                    display: 'flex',
                    border: `1px solid ${isChecked === 1 ? '' : borderColor}`, // Change border color dynamically
                    cursor: 'pointer', // Add cursor pointer to indicate clickability
                  }}
                  className="card-widget"
                  onClick={() => handleCardClick(1)}
                >
                  <CardContent className="card-widget-content">
                    <Typography
                      variant="h5"
                      className="fs-15 fw-bold font-skmodernist mb-2"
                    >
                      Heading here
                    </Typography>
                    <Typography className="fs-14 fw-normal font-gotham text-darkgrey mb-3">
                      Lorem Ipsum available, but the is text have suffered
                      alteration
                    </Typography>
                    <Button className="btn-signin">Sign in</Button>
                  </CardContent>

                  <CardMedia className="card-widget-media">
                    <WidgetImg className="widget-img" />
                  </CardMedia>

                  {/* Show check icon only when the card is clicked */}
        {/* {isChecked === 1 && (
                    <Box className="widget-check-icon">
                      <WidgetCheckIcon />
                    </Box>
                  )}
                </Card>
              </Grid>

              <Grid item xs={10} md={6}>
                <Card
                  sx={{
                    display: 'flex',
                    border: `1px solid ${isChecked === 2 ? '' : borderColor}`, // Change border color dynamically
                    cursor: 'pointer', // Add cursor pointer to indicate clickability
                  }}
                  className="card-widget"
                  onClick={() => handleCardClick(2)}
                >
                  <CardMedia className="card-widget-media">
                    <WidgetImg className="widget-img" />
                  </CardMedia>
                  <CardContent className="card-widget-content">
                    <Typography
                      variant="h5"
                      className="fs-15 fw-bold font-skmodernist mb-2"
                    >
                      Heading here
                    </Typography>
                    <Typography className="fs-14 fw-normal font-gotham text-darkgrey mb-3">
                      Lorem Ipsum available, but the is text have suffered
                      alteration
                    </Typography>
                    <Button className="btn-signin">Sign in</Button>
                  </CardContent>

                  {/* Show check icon only when the card is clicked */}
        {/* {isChecked === 2 && (
                    <Box className="widget-check-icon">
                      <WidgetCheckIcon />
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={10}
              md={6}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <Grid item sx={{ marginRight: 5 }}>
                <Card className="card-tiles" onClick={() => handleCardClick(1)}>
                  <CardMedia className="card-tiles-media">
                    <TileImg1 className="card-tiles-img" />
                  </CardMedia>
                  {isChecked === 1 && (
                    <Box className="widget-check-icon">
                      <WidgetCheckIcon />
                    </Box>
                  )}
                </Card>
                <Box className="text-center mt-1">
                  <Typography className="fs-14 fw-normal font-gotham text-darkgrey">
                    Name Here
                  </Typography>
                </Box>
              </Grid>

              <Grid item sx={{ marginRight: 5 }}>
                <Card
                  className="card-tiles"
                  sx={{ borderColor: '#864FFD' }}
                  onClick={() => handleCardClick(2)}
                >
                  <CardMedia className="card-tiles-media">
                    <TileImg1 className="card-tiles-img" />
                  </CardMedia>
                  {isChecked === 2 && (
                    <Box className="widget-check-icon">
                      <WidgetCheckIcon />
                    </Box>
                  )}
                </Card>
                <Box className="text-center mt-1">
                  <Typography className="fs-14 fw-normal font-gotham text-darkgrey">
                    Name Here
                  </Typography>
                </Box>
              </Grid>

              <Grid item sx={{ marginRight: 5 }}>
                <Card
                  className="card-tiles"
                  sx={{ borderColor: '#61A3F2' }}
                  onClick={() => handleCardClick(3)}
                >
                  <CardMedia className="card-tiles-media">
                    <TileImg1 className="card-tiles-img" />
                  </CardMedia>
                  {isChecked === 3 && (
                    <Box className="widget-check-icon">
                      <WidgetCheckIcon />
                    </Box>
                  )}
                </Card>
                <Box className="text-center mt-1">
                  <Typography className="fs-14 fw-normal font-gotham text-darkgrey">
                    Name Here
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>  */}

        <Box className="d-flex align-items-center mb-3">
          <Typography className="fs-18 fw-bold font-skmodernist me-3">
            Add{' '}
            {createWidget?.single_widget?.widget_type === 'cards' ||
            !createWidget?.single_widget?.widget_type
              ? 'Card'
              : 'Tile'}{' '}
            Details
          </Typography>
          <Button
            type="button"
            className="widget-add-btn"
            onClick={handleOpenModal}
            helperText=" "
          >
            <img src={AddIcon} alt="add-btn-icon" className="add-btn-icon" />
          </Button>

          <AddWidgetModal
            onChangeOfWidgetKeys={onChangeOfModuleWidgetKeys}
            widget={createWidget?.single_module_widget}
            widgetType={createWidget?.single_widget?.widget_type}
            open={openModal}
            errors={errors}
            saveSelectedWidgetDetails={saveSelectedWidgetDetails}
            selected_widget_details={selected_widget_details}
            onClose={handleCloseModal}
            isFromEditPage={isFromEditPage}
            handleEdit={handleEditWidgetPopupSave}
            handleSave={handleWidgetPopupSave}
            webComponents={createWidget?.webComponent}
            allWidgetLength={allWidget_length}
          />
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  {createWidget?.single_widget?.widget_type === 'cards' && (
                    <TableCell>Button Name</TableCell>
                  )}
                  {/* <TableCell>
                    {createWidget?.single_widget?.widget_type === 'cards'
                      ? 'Banner' + ' '
                      : 'Tiles' + ' '}{' '}
                    Seq Number
                  </TableCell> */}
                  <TableCell sx={{ width: '80px' }}>Status</TableCell>
                  <TableCell className="text-center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {single_widget_details && single_widget_details?.length > 0 ? (
                  single_widget_details
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              {/* thumbnail_image */}
                              <img
                                src={`${IMAGE_BASE_URL}${row.thumbnail_image}`}
                                alt="product-image"
                                width="80"
                                height="80"
                                style={{
                                  objectFit: 'cover',
                                  objectPosition: 'center',
                                }}
                                className="product-image"
                              />
                            </div>
                            {/* <div component="div">{row.title}</div> */}
                          </div>
                        </TableCell>
                        {createWidget?.single_widget?.widget_type ===
                          'cards' && <TableCell>{row.button_text}</TableCell>}
                        {/* <TableCell>{row.rank}</TableCell> */}
                        <TableCell align="center">
                          <Typography
                            className={
                              row.is_active
                                ? 'text-status-active'
                                : 'text-status-inactive'
                            }
                          >
                            {row.is_active ? 'Active' : ''}
                          </Typography>
                        </TableCell>
                        {/* {createWidget?.single_widget?.widget_type ===
                          'cards' && <TableCell>{row.sub_title}</TableCell>} */}
                        <TableCell>
                          <div className="d-flex justify-content-center">
                            <Button onClick={() => onEditDetails(row)}>
                              <EditIcon />
                            </Button>
                            <Button onClick={() => onDeleteDetails(row.id)}>
                              <DeleteIcon />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={page}
            count={single_widget_details?.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}
const mapStateToProps = ({ createWidget }) => ({ createWidget });
export default connect(mapStateToProps, {
  postWidget,
  postWidgetDetails,
  addSingleWidget,
  getWidgetDetails,
  saveSelectedWidgetDetails,
  editWidget,
  deleteWidget,
  clearWidget,
  addSingleModuleWidget,
  putWidgetDetails,
  pushMessage,
  getAllWebcomponent,
})(CreateWidgetMaster);
