/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
	Box,
	Button,
	Grid,
	Menu,
	MenuItem,
	Modal,
	Pagination,
	Popover,
	TextField,
	Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as OptionsIcon } from '../../assets/options-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
// import List from 'react-virtualized/List';
// import EnquiryUpdateStatusModal from "./EnquiryUpdateStatusModal";
import { setHeaderTitle } from '../../store/actions/rootActions';

import { useHistory } from 'react-router-dom';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';

import {
	getVendors,
	getVendorDetails,
	deleteVendor,
} from './redux/actions/b2b-action';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
};
function B2BProfile({
	getVendors,
	getVendorDetails,
	b2bList,
	setHeaderTitle,
	deleteVendor,
	pushMessage,
}) {
	const [filteredB2bList, setFilteredB2bList] = useState(b2bList);
	const [searchText, setSearchText] = useState('');
	const [page, setPage] = useState(0);
	const [rowsPerPage] = useState(20); // Since data is static, we don't need to change rowsPerPage
	const [currentB2bUser, setCurrentb2bUser] = useState();

	const handleChangePage = (newPage) => {
		setPage(newPage);
	};
	const deleteUser = (user_id) => {
		deleteVendor(
			user_id,
			(res) => {
				getVendors();
				pushMessage(res);
			},
			(err) => {
				pushMessage(err);
			}
		);
	};
	useEffect(() => {
		getVendors();
		console.log('====b2bList787', b2bList);
	}, []);
	useEffect(() => {
		const filteredList = b2bList.filter(
			(row) =>
				row.name?.toLowerCase()?.includes(searchText?.toLowerCase()?.trim()) ||
				row?.user?.email
					?.toLowerCase()
					?.includes(searchText?.toLowerCase()?.trim()) ||
				row?.company_name
					?.toLowerCase()
					?.includes(searchText?.toLowerCase()?.trim()) ||
				row?.freelancer_role
					?.toLowerCase()
					?.includes(searchText?.toLowerCase()?.trim()) ||
				row?.user?.mobile_number?.includes(searchText?.trim()) ||
				row?.city?.toLowerCase()?.includes(searchText?.toLowerCase()?.trim()) ||
				row?.status?.toLowerCase()?.includes(searchText?.toLowerCase()?.trim())
		);
		setFilteredB2bList(filteredList);
		if (filteredList.length > 0) {
			setPage(0);
		}
	}, [searchText, b2bList]);

	const history = useHistory();

	const handleCreateButtonClick = () => {
		history.push('/create-users'); // Navigate to Createrole page
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event, row) => {
		setAnchorEl(event?.currentTarget);
		setCurrentb2bUser(row);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const options = ['View', 'Delete'];

	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const statusClasses = {
		Approved: 'text-status-active',
		Pending: 'text-status-pending',
		Rejected: 'text-status-inactive',
	};
	function toCamelCase(str) {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
				return index === 0 ? word?.toUpperCase() : word?.toLowerCase();
			})
			.replace(/\s+/g, '');
	}
	const [openDelete, setOpenDelete] = useState(false);
	const [userId, setUserId] = useState('');
	return (
		<SideBarContent>
			<Modal
				open={openDelete}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Elynker
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Are you sure you want to delete this Profile?
					</Typography>
					<div style={{ float: 'right' }}>
						<Button
							onClick={() => {
								setOpenDelete(false);
								deleteUser(userId);
							}}
						>
							Yes
						</Button>
						<Button
							onClick={() => {
								setOpenDelete(false);
							}}
						>
							No
						</Button>
					</div>
				</Box>
			</Modal>
			<Box className="custom-card-bg">
				<Box className="mb-4">
					<Grid
						container
						spacing={2}
						className="align-items-center just-content-between"
					>
						<Grid item xs={10} md={6}>
							<TextField
								sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
								variant="outlined"
								placeholder="Search here..."
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								InputProps={{
									startAdornment: (
										<Search
											sx={{
												width: '1em',
												height: '1em',
												marginRight: '8px',
												color: '#864FFD',
											}}
											color="action"
										/>
									),
								}}
							/>
						</Grid>
					</Grid>
				</Box>

				<Paper sx={{ width: '100%' }}>
					<TableContainer sx={{ maxHeight: '450px' }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell>User ID</TableCell>
									<TableCell>Registration Date</TableCell>
									<TableCell>Company Name/ Roles</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Type</TableCell>
									{/* <TableCell>Email</TableCell> */}
									<TableCell>Phone Number</TableCell>
									<TableCell>Location</TableCell>
									<TableCell align="center">Status</TableCell>
									<TableCell align="center">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredB2bList
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => (
										<TableRow key={row.id}>
											<TableCell>{row?.user?.code}</TableCell>
											<TableCell>
												{moment(row.createdAt).format('DD-MM-YYYY')}
											</TableCell>
											<TableCell>
												{row?.registration_type === 'Business'
													? row?.company_name
													: row?.freelancer_role}
											</TableCell>
											<TableCell>
												<Typography
													sx={{ color: '#393939' }}
													className="fs-14 font-gotham"
												>
													{row?.name}
												</Typography>
												<Typography
													sx={{ color: '#868686' }}
													className="fs-13 font-gotham"
												>
													{row?.user?.email}
												</Typography>
											</TableCell>
											<TableCell>{row?.registration_type}</TableCell>
											{/* <TableCell>
                                            {row.user.email}
                                        </TableCell> */}
											<TableCell>{row?.user?.mobile_number}</TableCell>
											<TableCell>{row?.city}</TableCell>

											<TableCell align="center">
												{/* <Typography className={row.status === 'Approved' ? 'text-status-active' : 'text-status-inactive'}>{row.status}</Typography> */}
												<Typography
													className={statusClasses[toCamelCase(row?.status)]}
												>
													{toCamelCase(row?.status)}
												</Typography>
											</TableCell>
											<TableCell align="right">
												<Button onClick={(e) => handleClick(e, row)}>
													{<OptionsIcon />}
												</Button>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
					<Menu
						id="long-menu"
						MenuListProps={{
							'aria-labelledby': 'long-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						{options.map((option, optionIdx) => (
							<MenuItem
								// elevation={0}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								sx={{
									boxShadow:
										'0px 0px 0px 1px rgba(0,0,0,-1.8), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 4px -1px rgba(0,0,0,0.10) !important',
								}}
								key={option}
								// selected={option === 'View'}
								onClick={() => {
									if (optionIdx === 0) {
										handleClose();
										handleOpenModal();
										getVendorDetails(currentB2bUser.id);
										console.log('=====currentB2bUser click', currentB2bUser);
										//freelancer-profile-view-reason
										if (currentB2bUser.registration_type === 'Freelancer') {
											setHeaderTitle('Freelancer');
											history.push(
												'/freelancer-profile-view-reason' +
													`/${currentB2bUser.id}`
											);
										} else {
											setHeaderTitle('Business');
											history.push(
												'/b2b-profile-view' + `/${currentB2bUser.id}`
											);
										}
									}
									if (optionIdx === 1) {
										console.log('option index 1');
										setUserId(currentB2bUser?.user.id);
										handleClose();
										setOpenDelete(true);
									}
								}}
							>
								{option}
							</MenuItem>
						))}
					</Menu>
					{/* <EnquiryUpdateStatusModal open={openModal} onClose={handleCloseModal} /> */}
					<CustomPagination
						page={page}
						count={filteredB2bList.length}
						rowsPerPage={rowsPerPage}
						onPageChange={handleChangePage}
					/>
				</Paper>
			</Box>
		</SideBarContent>
	);
}
const mapStateToProps = ({ b2bUser }) => {
	console.log('====mapStateToProps b2bUser', b2bUser);
	return {
		b2bList: b2bUser.b2bList,
		b2bloading: b2bUser.loading,
	};
};

export default connect(mapStateToProps, {
	getVendors,
	getVendorDetails,
	setHeaderTitle,
	deleteVendor,
	pushMessage,
})(B2BProfile);

