import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const CustomDropdown = ({
  id = 'customAutocompleteInput',
  value,
  options = [],
  onChange = () => {},
  inputPlaceholder = '',
  labelKey = 'name',
  optionKey = 'name',
  autoComplete = null,
  disabled = false,
}) => {
  let inputProps = {};
  if (autoComplete) {
    inputProps.autoComplete = autoComplete;
  }
  return (
    <Autocomplete
      disablePortal
      id={id}
      options={options}
      fullWidth
      value={value}
      disabled={disabled}
      onChange={onChange}
      getOptionKey={(option) => option[optionKey]}
      getOptionLabel={(option) => option[labelKey] || ''}
      isOptionEqualToValue={(option, value) =>
        option[labelKey] === (value && value[labelKey])
      }
      disableClearable
      sx={{
        '.MuiAutocomplete-input': {
          paddingLeft: '14px !important',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled
          fullWidth
          variant="outlined"
          placeholder={inputPlaceholder}
          {...inputProps}
        />
      )}
    />
  );
};

CustomDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  inputPlaceholder: PropTypes.string,
  id: PropTypes.string,
  labelKey: PropTypes.string,
  autoComplete: PropTypes.string,
  optionKey: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CustomDropdown;
