import api from "./configs/axiosConfig";


const HomePageWidget = '/widgets';

export const HomePageWidgetApi = {
    getHomepageWidget: async function(){
        const response = await api.get(HomePageWidget);
        return response;
    },

    updateHomepageWidget: async function(id, data, header){
        const response = await api.put(HomePageWidget + "/" + id, data, header);
        return response;
    },

    postHomepageWidget: async function(data){
        const response = await api.post(HomePageWidget, data);
        return response;
    }
}