import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
// import Resizer from 'react-image-file-resizer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { ModalStyle } from '../../utils/constants';
import { IMAGE_BASE_URL } from '../../apis/endpoints';
import {
  Box,
  FormControl,
  FormHelperText,
  // IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import ProductCloseIcon from '../../assets/product-close-icon.svg';
import { useHistory } from 'react-router';

const AddWidgetModal = ({
  open,
  onClose,
  onChangeOfWidgetKeys,
  widget,
  widgetType,
  handleSave,
  selected_widget_details,
  errors,
  isFromEditPage,
  handleEdit,
  webComponents,
  allWidgetLength,
  saveSelectedWidgetDetails,
}) => {
  console.log('allWidgetLength6543', allWidgetLength);
  const banner_sequences = Array.from(
    { length: allWidgetLength ? allWidgetLength + 1 : 1 },
    (_, index) => index + 1
  );

  const widget_type = widgetType;
  const [planName, setPlanName] = useState('');
  const [redirect, setRedirect] = useState('');
  const [bannerSeqNumber, setBannerSeqNumber] = useState('');
  const [isRank, setIsRank] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [addWidgetForm, setAddWidgetForm] = React.useState({});
  const [openModal, setOpenlModal] = useState(false);

  const handleOpenModal = () => setOpenlModal(true);
  const handleCloseModule = () => setOpenlModal(false);
  const history = useHistory();
  const widgetImage = React.useRef(null);
  React.useEffect(() => {
    if (selected_widget_details?.main_image) {
      onChangeOfWidgetKeys('image', selected_widget_details?.main_image);
    }
  }, [selected_widget_details]);
  const save = () => {
    handleSave();
  };
  const edit = () => {
    handleEdit();
  };
  const handleCancel = () => {
    onClose(true);
  };
  const handleWidgetButtonClick = () => {
    if (widget?.image && !errors.image) {
      setOpenlModal(true);
    } else {
      widgetImage.current.click();
    }
  };
  const handleWidgetFileChange = async (e) => {
    const selectedFile = e.target.files;
    onChangeOfWidgetKeys('image', selectedFile[0]);
    e.target.value = null;
  };
  const [viewImagedialogOpen, setViewImagedialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const handleViewImageClick = (image) => {
    console.log('===handleViewImageClick', image);
    setSelectedImage(image);
    setViewImagedialogOpen(true);
  };
  // const compressImages = (files, height = 500, width = 500, quality = 75) => {
  //   return Promise.all(
  //     Array.from(files).map((file) => {
  //       return new Promise((resolve) => {
  //         Resizer.imageFileResizer(
  //           file,
  //           height, // New width
  //           width, // New height
  //           'JPEG', // Format
  //           quality, // Quality
  //           0, // Rotation
  //           (uri) => {
  //             resolve(uri);
  //           },
  //           'file'
  //         );
  //       });
  //     })
  //   );
  // };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Modal
        open={openModal}
        onClose={handleOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            are you sure want to replace current image?
          </Typography>
          <div style={{ float: 'right' }}>
            <Button
              onClick={() => {
                handleCloseModule();
                widgetImage.current.click();
              }}
            >
              Yes
            </Button>
            <Button onClick={handleCloseModule}>No</Button>
          </div>
        </Box>
      </Modal>
      <DialogTitle className="custom-border-header">
        {selected_widget_details &&
        Object.keys(selected_widget_details).length > 0
          ? 'Edit'
          : 'Add'}{' '}
        Widget Details
      </DialogTitle>
      <DialogContent>
        <div className="row align-items-end">
          <div className="col-md-6 mb-2">
            <InputLabel
              htmlFor="inputCIN"
              className="text-start fs-14 text-black font-skmodernist"
            >
              Add Image
            </InputLabel>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              className="add-button mt-2 fs-15 font-skmodernist fw-bold"
              sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
              onClick={handleWidgetButtonClick}
            >
              <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
            </Button>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={widgetImage}
              onChange={handleWidgetFileChange}
            />
          </div>
          <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
            {errors?.image}
          </FormHelperText>
          <div className="col-md-6 d-flex justify-content-between align-items-center mb-3">
            {!errors?.image && widget?.image && (
              <div>
                <img
                  style={{ width: '30%', height: '100%' }}
                  onClick={() => handleViewImageClick(widget.image)}
                  src={
                    widget?.image instanceof File
                      ? URL.createObjectURL(widget.image)
                      : IMAGE_BASE_URL + widget?.image
                  }
                  alt="product-image"
                />
                <img
                  src={ProductCloseIcon}
                  alt="product-close-icon"
                  className="product-close-icon"
                  onClick={() => {
                    console.log('on delete');
                    const {
                      main_image = '',
                      image = '',
                      ...rest
                    } = selected_widget_details;
                    // saveSelectedWidgetDetails(rest);
                    onChangeOfWidgetKeys('image', null);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="row">
          {/* {(widget_type === 'cards' || !widget_type) && (
            <div className="col-md-6 mb-2">
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor=""
              >
                Text
              </InputLabel>
              <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                {/* <OutlinedInput
                                id=""
                                type="text"
                                placeholder="Enter text"
                                required
                                autoFocus
                                fullWidth
                                margin='none'
                            /> */}
          {/* <TextareaAutosize
                  id="sub_title"
                  placeholder="Enter text"
                  className="widget-textarea"
                  onChange={(event) =>
                    onChangeOfWidgetKeys('sub_title', event.target.value)
                  }
                  // onChange={r => setReason(r.target.value)}
                  minRows={0} // You can adjust the number of rows as needed
                  maxRows={10} // You can adjust the number of rows as needed
                  defaultValue={selected_widget_details?.sub_title}
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.sub_title}
              </FormHelperText>
            </div>
          )}  */}

          {(widget_type === 'cards' || !widget_type) && (
            <div className="col-md-6 mb-2">
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor=""
              >
                Button Name
              </InputLabel>
              <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                <OutlinedInput
                  id="button_text"
                  type="text"
                  placeholder="Enter button name"
                  required
                  autoFocus
                  fullWidth
                  margin="none"
                  defaultValue={selected_widget_details?.button_text}
                  onChange={(event) =>
                    onChangeOfWidgetKeys('button_text', event.target.value)
                  }
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
                {errors?.button_text}
              </FormHelperText>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-6 mb-2">
            <InputLabel
              className="text-start fs-14 text-black font-skmodernist"
              htmlFor="inputProductCategory"
            >
              Redirect To
            </InputLabel>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <Select
                id="button_src"
                defaultValue={selected_widget_details?.button_src}
                onChange={(event) =>
                  onChangeOfWidgetKeys('button_src', event.target.value)
                }
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">Select</MenuItem>
                {webComponents &&
                  webComponents?.map((wc) => (
                    <MenuItem
                      key={wc.id}
                      value={String(wc?.page_name).toLowerCase()}
                    >
                      {wc.page_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
              {errors?.button_src}
            </FormHelperText>
          </div>

          {/* <div className="col-md-6 mb-2">
            <InputLabel
              className="text-start fs-14 text-black font-skmodernist"
              htmlFor="inputProductCategory"
            >
              {widget_type === 'cards' || !widget_type
                ? 'Banner Seq Number'
                : 'Tile Seq Number'}
            </InputLabel>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <Select
                id="widget_bannerSeqNumber"
                defaultValue={selected_widget_details?.rank}
                onChange={(event) => {
                  // console.log('==on change of banner sequence', event.target);
                  onChangeOfWidgetKeys(
                    'widget_bannerSeqNumber',
                    event.target.value
                  );
                  onChangeOfWidgetKeys('rank', event.target.value);
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">Select</MenuItem>
                {banner_sequences &&
                  banner_sequences?.map((b) => (
                    <MenuItem value={b}>{b}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: 'red' }} id="master-helper-text">
              {errors?.widget_bannerSeqNumber}
            </FormHelperText>
          </div> */}
        </div>

        <div className="row">
          <div className="col-4 custom-control custom-checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  id="is_active"
                  defaultChecked={selected_widget_details?.is_active}
                  checked={
                    selected_widget_details?.is_active
                      ? selected_widget_details.is_active
                      : isActive
                  }
                  onChange={(event) => {
                    onChangeOfWidgetKeys('is_active', event.target.checked);
                    setIsActive((prev) => !prev);
                  }}
                  color="primary"
                />
              }
              label="Active"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className="btn-cancel me-2">
          Cancel
        </Button>
        <Button
          onClick={
            selected_widget_details &&
            Object.keys(selected_widget_details).length > 0
              ? edit
              : save
          }
          className="btn-save"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWidgetModal;
