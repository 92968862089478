import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextareaAutosize } from '@mui/material';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import RequirementsMaster from './RequirementsMaster';
import { editRequirementData, getRequirementDetails, getStatusDetails, setClearRequirementData, setRequirementData } from './redux/actions/requirement-action';
import { connect } from 'react-redux';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import CustomDropdown from '../../component/CustomDropdown';
import useValidation from '../../hooks/useValidation';


const RequirementsUpdateStatusModal = ({
    open,
    onClose,
    getStatusDetails,
    requirementObj,
    setRequirementData,
    status_data,
    pushMessage,
    setClearRequirementData,
    editRequirementData,
    getRequirementDetails,
    userSelect

}) => {
    const [planName, setPlanName] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [count, setCount] = useState(0);
    const { validateInput, errors } = useValidation();
    const [previousState, setPreviousState] = useState('')
    const [newState, setNewState] = useState('');

    useEffect(() => {
        getStatusDetails();
    }, [])

    const handleSave = async () => {
        let jsonData;
        if(requirementObj?.status?.toLowerCase() === "fulfilled"){
            if (!requirementObj?.name) {
                validateInput('name', requirementObj?.name);
                return;
            }
            if (!requirementObj?.mobile_number || requirementObj?.mobile_number?.length < 10) {
                console.log("Mobileeeeeeeeeeeeeeeee :",requirementObj?. mobile_number);
                validateInput('mobileNumber', requirementObj?.mobile_number);
                return;
            }
            // Handle save logic here
            jsonData = JSON.stringify({
                status: requirementObj?.status?.toLowerCase(),
                name: requirementObj?.name,
                mobile_number: requirementObj?.mobile_number,
                comments: requirementObj?.comments,
            }
            );

        }
        else {
            jsonData = JSON.stringify({
                status: requirementObj?.status?.toLowerCase(),
            }
            );
        }

        console.log("jsonData@@@@:", jsonData)
        console.log("requirementObj?.id@@@@:", requirementObj?.id)
        const customConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        await editRequirementData(requirementObj?.id, jsonData, customConfig, () => {
            getRequirementDetails();
            pushMessage("Updated Successfully");
            setClearRequirementData();
        }, (error) => { pushMessage(error) })
        onClose();
    };

    const handleCancel = () => {
        setClearRequirementData();
        onClose(true); // Close the modal
    };
    const statusOptions = status_data || [];
    const getRequirementStatusObject = () => {
        let statusObj = null;
        console.log("RequirementObjs : ", requirementObj?.status)
        const valuesArray = Object.values(statusOptions);
        statusObj = valuesArray.find(item => item?.value?.toLowerCase().includes(requirementObj?.status?.toLowerCase()))
        console.log("statusObj_data :", statusObj);
        if (statusObj) {
            return statusObj;
        }
        else {
            return null
        }
    }

    const handleDropDown = (e, value) => {
        let obj = { ...requirementObj }
        obj.status = value.value;
        setNewState(value?.value);
        console.log("obj.statsus @@@:", obj.status)
        setRequirementData(obj);
    }

    const handleReqName = (e) => {
        if (e.target.value.length >= 151) {
            pushMessage("The number of characters exceeded.");
            return;
        } else {
            let obj = { ...requirementObj }
            obj.name = e.target.value;
            validateInput("name", obj.name)
            // console.log("name_of_user :",requirementObj?.name);
            setCount(obj?.name?.length);
            setRequirementData(obj);
        }
    }
    const handleReqMobileNo = (e) => {
        if (e.target.value.length >= 11) {
            pushMessage("The number of characters exceeded.");
            return;
        } else {
            let obj = { ...requirementObj }
            obj.mobile_number = e.target.value;
            validateInput("mobileNumber", e.target.value)
            // console.log("number_of_user :",requirementObj?.mobile_number);
            setRequirementData(obj);
        }
    }

    const handleReqComment = (e) => {
        if (e.target.value.length >= 151) {
            pushMessage("The number of characters exceeded.");
            return;
        } else {
            let obj = { ...requirementObj }
            obj.comment = e.target.value;
            setCount(obj?.comment?.length);
            setRequirementData(obj);
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="custom-border-header">Update Status</DialogTitle>
            <DialogContent sx={{ minHeight: "200px" }}>
                <div className="col-md-6 mb-1">
                    <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputProductCategory">Status</InputLabel>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        {/* <Select
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="Select 1">Pending</MenuItem>
                            <MenuItem value="Select 2">Fullfilled </MenuItem>
                        </Select> */}
                        <CustomDropdown
                            id="enquiryInput"
                            disabled={userSelect === "view"}
                            value={getRequirementStatusObject()}
                            options={statusOptions}
                            inputPlaceholder="Select Status"
                            onChange={handleDropDown
                            }
                            labelKey="value"
                            optionKey="id"
                            inputProps={{ disabled: true }}
                        />
                    </FormControl>
                </div>

                {requirementObj?.status?.toLowerCase() === "fulfilled" && <div className="row">
                    <div className="col-md-6 mb-1">
                        <InputLabel
                            htmlFor="inputCompanyName"
                            className="text-start fs-14 text-black font-skmodernist">
                            Name
                        </InputLabel>
                        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">

                            <OutlinedInput
                                id="inputName"
                                // label="Mobile Number"
                                type="text"
                                placeholder="Enter name"
                                required
                                fullWidth
                                margin='none'
                                onChange={handleReqName}
                                value={requirementObj?.name}
                                disabled={userSelect === "view"}
                            />
                        </FormControl>
                        <FormHelperText sx={{ color: 'red' }} id="name-helper-text">
                            {errors.name}
                        </FormHelperText>
                    </div>
                    <div className="col-md-6 mb-1">
                        <InputLabel
                            htmlFor="inputPhone"
                            className="text-start fs-14 text-black font-skmodernist">
                            Mobile No.
                        </InputLabel>
                        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">

                            <OutlinedInput
                                id="inputName"
                                // label="Mobile Number"
                                type="Number"
                                placeholder="Enter mobile no."
                                required
                                fullWidth
                                margin='none'
                                onChange={handleReqMobileNo}
                                value={requirementObj?.mobile_number}
                                disabled={userSelect === "view"}
                            />
                        </FormControl>
                        <FormHelperText sx={{ color: 'red' }} id="mobile-helper-text">
                            {errors.mobileNumber}
                        </FormHelperText>
                    </div>
                </div>}

                {requirementObj?.status?.toLowerCase() === "fulfilled" && <div className="col-md-12 mb-2">
                    <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputPlanName">Comment</InputLabel>
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                        <TextareaAutosize
                            id="inputAdditionalInfo"
                            placeholder="Enter Comment"
                            minRows={3} // You can adjust the number of rows as needed
                            maxRows={2} // You can adjust the number of rows as needed
                            value={requirementObj?.comments}
                            onChange={handleReqComment}
                            disabled={userSelect === "view"}
                        />
                    </FormControl>
                </div>}






            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} className="btn-cancel me-2">
                    Cancel
                </Button>
                {userSelect !== "view" && (
                    <Button onClick={handleSave} className="btn-save" disabled={previousState == newState}>
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { all_requiremnt, all_requirement_filter, requirementObj, status_data } = state.requiremnt;
    return {
        all_requiremnt,
        all_requirement_filter,
        requirementObj,
        status_data

    };
}
export default connect(mapStateToProps, { getStatusDetails, setRequirementData, pushMessage, setClearRequirementData, editRequirementData, getRequirementDetails })(RequirementsUpdateStatusModal);