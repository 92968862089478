import React, { useEffect, useState } from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, Grid, Menu, MenuItem, Pagination, Popover, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as OptionsIcon } from "../../assets/options-icon.svg";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
// import EnquiryUpdateStatusModal from "./EnquiryUpdateStatusModal";
import { useHistory } from 'react-router-dom';
import SideBarContent from "../../component/SideBarContent/SideBarContent";





function FreelancerProfile() {
    //const navigate=useNavigate();
    const [searchText, setSearchText] = useState("");
    // const [rows, setRows] = useState(data);


    // useEffect(() => {
    //     console.log("Filtered items:", rows);
    // }, [rows]);

    // const handleSearch = (e) => {
    //     const searchTerm = e.target.value.toLowerCase();
    //     setSearchText(searchTerm, "searchValue");
    //     if (searchTerm === "") {
    //         // If search term is empty, display the original list
    //         setRows(data);
    //     } else {
    //         // Filter the list based on the search term
    //         const filteredItems = rows.filter((item) =>
    //             item.name.toLowerCase().includes(searchTerm)
    //         );
    //         setRows(filteredItems);
    //     }
    // };
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const history = useHistory();

    const handleCreateButtonClick = () => {
        history.push('/create-users'); // Navigate to Createrole page
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = [
        'View',
        // 'Update Status2',
        // 'Update Status3',
    ];

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const data = [
        { id: 1, name: 'Shubham', type: 'B2B', email: 'shubham@abc.com', phoneNumber: '8090152240', location: 'Mumbai', status: 'Approved', optionsIcon: <OptionsIcon /> },
        { id: 2, name: 'Varsha Mali', type: 'Freelancer', email: 'varsha@abc.com', phoneNumber: '7465238492', location: 'Pune', status: 'Pending', optionsIcon: <OptionsIcon /> },
        { id: 3, name: 'Jay Vishwakarma', type: 'B2B', email: 'jay@abc.com', phoneNumber: '7465238789', location: 'Bangalore', status: 'Rejected', optionsIcon: <OptionsIcon /> },
        { id: 4, name: 'Gaurav Mehta', type: 'Freelancer', email: 'gaurav@abc.com', phoneNumber: '7465238123', location: 'Delhi', status: 'Approved', optionsIcon: <OptionsIcon /> },
        { id: 5, name: 'Rohit Kahar', type: 'B2B', email: 'rohit@abc.com', phoneNumber: '7465238619', location: 'Lucknow', status: 'Rejected', optionsIcon: <OptionsIcon /> },
        { id: 6, name: 'Shubham', type: 'B2B', email: 'shubham@abc.com', phoneNumber: '8090152240', location: 'Mumbai', status: 'Approved', optionsIcon: <OptionsIcon /> },
        { id: 7, name: 'Varsha Mali', type: 'Freelancer', email: 'varsha@abc.com', phoneNumber: '7465238492', location: 'Pune', status: 'Pending', optionsIcon: <OptionsIcon /> },
        { id: 8, name: 'Jay Vishwakarma', type: 'B2B', email: 'jay@abc.com', phoneNumber: '7465238789', location: 'Bangalore', status: 'Rejected', optionsIcon: <OptionsIcon /> },
        { id: 9, name: 'Gaurav Mehta', type: 'Freelancer', email: 'gaurav@abc.com', phoneNumber: '7465238123', location: 'Delhi', status: 'Approved', optionsIcon: <OptionsIcon /> },
        { id: 10, name: 'Rohit Kahar', type: 'B2B', email: 'rohit@abc.com', phoneNumber: '7465238619', location: 'Lucknow', status: 'Rejected', optionsIcon: <OptionsIcon /> },
    ];

    const statusClasses = {
        'Approved': 'text-status-active',
        'Pending': 'text-status-pending',
        'Rejected': 'text-status-inactive',
    };
    return (
        <SideBarContent>
            <Box className="custom-card-bg">
                <Box className="mb-4">
                    <Grid container spacing={2} className="align-items-center just-content-between">
                        <Grid item xs={10} md={6} >
                            <TextField
                                sx={{ height: "50px", width: '400px', borderRadius: '6px' }}
                                variant="outlined"
                                placeholder="Search here..."
                                value={searchText}
                                // onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <Search
                                            sx={{ width: "1em", height: "1em", marginRight: "8px", color: '#864FFD' }}
                                            color="action"
                                        />
                                    ),
                                }}
                            />
                        </Grid>

                    </Grid>
                </Box>

                <Paper sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: "450px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            {row.name}
                                        </TableCell>
                                        <TableCell>
                                            {row.type}
                                        </TableCell>
                                        <TableCell>
                                            {row.email}
                                        </TableCell>
                                        <TableCell>
                                            {row.phoneNumber}
                                        </TableCell>
                                        <TableCell>
                                            {row.location}
                                        </TableCell>

                                        <TableCell align="center">
                                            {/* <Typography className={row.status === 'Approved' ? 'text-status-active' : 'text-status-inactive'}>{row.status}</Typography> */}
                                            <Typography className={statusClasses[row.status]}>{row.status}</Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button onClick={handleClick}>{row.optionsIcon}</Button>
                                        </TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}

                    >
                        {options.map((option, optionIdx) => (
                            <MenuItem
                                // elevation={0}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                sx={{ boxShadow: '0px 0px 0px 1px rgba(0,0,0,-1.8), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 4px -1px rgba(0,0,0,0.10) !important' }}
                                key={option} selected={option === 'View'} onClick={() => {
                                    if (optionIdx === 0) {
                                        handleClose()
                                        handleOpenModal()
                                    }

                                }}>
                                {option}

                            </MenuItem>

                        ))}

                    </Menu>
                    {/* <EnquiryUpdateStatusModal open={openModal} onClose={handleCloseModal} /> */}
                    <CustomPagination page={page} count={data.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
                </Paper>
            </Box>
        </SideBarContent>
    );
}

export default FreelancerProfile;
