import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as MenuIcon } from "../../assets/menu-icon.svg";
import { ReactComponent as BellIcon } from "../../assets/bell-icon.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as React from "react";
import { Badge } from "@mui/material";
import { AccountCircle, Height } from "@mui/icons-material";
// import { ReactComponent as ProfileImg } from "../../assets/profile-img.svg";
import { ReactComponent as LogoImg } from "../../assets/elynker-small-purple-logo.svg"
import "../../index.css";
import "../../common/fontsize.css";
import { connect } from "react-redux";
import { setHeaderTitle, setOpen } from "../../store/actions/rootActions";
import { useLocation } from "react-router";
import { navigationConfig , newnavigationconfig } from "../../pages/Dashboard/SideList";
import { useHistory } from 'react-router-dom';

const drawerWidth = 250;
const closedDrawerWidth = 65;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    width: closedDrawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: `calc(${theme.spacing(7)} + 1px)`,
    // [theme.breakpoints.up("sm")]: {
    //   width: `calc(${theme.spacing(8)} + 1px)`,
    // },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

function SideBarContent({ open, setOpen, headerTitle, children, setHeaderTitle, userData }) {
    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
        // console.log("Loaction :", location?.pathname)
        //   navigationConfig
        for (const menuItem of navigationConfig) {
            if (menuItem?.submenus?.length > 0) {
                for (const subMenuItem of menuItem.submenus) {
                    if (location?.pathname === `/${subMenuItem.link}`) {
                        setHeaderTitle(subMenuItem.title)
                    }
                }
            } else {
                if (location?.pathname === `/${menuItem.link}`) {
                    setHeaderTitle(menuItem.title)
                }
            }
        }

        for (const menuItem of newnavigationconfig) {
            if (location?.pathname === `/${menuItem.link}`) {
                setHeaderTitle(menuItem.title)
            }
        }
    }, [location?.pathname])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            {/* <CssBaseline /> */}
            <AppBar sx={{ background: '#F7F7F7 !important', boxShadow: 'none !important', width: open ? 'calc(100% - 225px)' : 'calc(100% - 65px)' }} position="fixed" open={open}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        className="fs-26 text-black font-skmodernist fw-bold"
                        sx={{ display: { xs: "none", md: "flex" }, flexGrow: 1 }}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={open ? handleDrawerClose : handleDrawerOpen}
                            sx={{
                                marginRight: "20px",
                            }}
                        >
                            <MenuIcon />
                        </IconButton>

                        {headerTitle}
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center", marginLeft: 2 }}>
                        <IconButton
                            size="large"
                            aria-label="show 2 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={2} color="error">
                                <BellIcon />
                            </Badge>
                        </IconButton>
                        <Box sx={{ display: "flex", alignItems: "center", marginLeft: 2, cursor: "pointer" }} onClick={() => {history.push("/profile")
                            setHeaderTitle("Profile")
                        }}>
                            <LogoImg  style={{width: "25px", height: "25px"}}/>
                            <Box sx={{ marginLeft: 2 }}>
                                <Typography variant="subtitle1" className="fs-13 text-black font-skmodernist" >
                                    {userData?.user?.name}
                                </Typography>
                                <Typography variant="body2" className="fs-12 text-grey font-gotham">
                                    {userData?.roles[0]?.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>


            </AppBar>
            <Box sx={{ background: '#F7F7F7 !important', boxShadow: 'none !important', marginLeft: open ? '225px' : '60px' }} open={open}>
                {children}
            </Box>
        </Box>
    );
}

const mapStateToProps = state => {
    const { open, headerTitle } = state.root;
    const { userData } = state.signin;
    return { open, headerTitle, userData}
}

export default connect(mapStateToProps, { setOpen, setHeaderTitle })(SideBarContent);
