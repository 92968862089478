import React, { useEffect, useState } from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, Grid, Link, Pagination, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
import { useHistory } from 'react-router-dom';
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import { connect } from "react-redux";
import { getMiscData, postMiscData } from "./redux/actions/masters-action";


function Masters({ misc_data, getMiscData, postMiscData }) {
    misc_data.sort((a, b) => b.id - a.id);
    const [searchText, setSearchText] = useState("");
    const [items, setItems] = useState(
        misc_data
    );

    useEffect(() => {
        misc_data.map(item => {
            if (item.is_active===true) {
                item.status = 'Active'
            }
            else {
                item.status = 'InActive'
            }
        } )
        setItems(misc_data);
    }, [misc_data])

    

    const handleSearchChange = (e) => {    
        let filteredItems = [];
        filteredItems = misc_data.filter(item => item.name.toLowerCase().includes(e.target.value.trim().toLowerCase()) ||
            item.status.toLowerCase().startsWith(e.target.value.trim().toLowerCase())
        );
        setItems(filteredItems);  
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const history = useHistory();

    const handleCreateButtonClick = () => {
        history.push('/create-masters'); // Navigate to Createrole page
    };

    useEffect(() => {
        getMiscData();
    }, [])


    const handleEdit = (row) => {
        let miscData = {admin: row, module: []}
        postMiscData(miscData);
        history.push('/edit-masters',
            { rid: row.id });
    };
    

    return (
        <SideBarContent>
            <Box className="custom-card-bg">
                <Box className="mb-4">
                    <Grid container spacing={2} className="align-items-center just-content-between">
                        <Grid item xs={10} md={6} >
                            <TextField
                                sx={{ height: "50px", width: '400px', borderRadius: '6px' }}
                                variant="outlined"
                                placeholder="Search here..."
                                
                                // onChange={handleSearch}
                                onChange={ handleSearchChange }
                                InputProps={{
                                    startAdornment: (
                                        <Search
                                            sx={{ width: "1em", height: "1em", marginRight: "8px", color: '#864FFD' }}
                                            color="action"
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={"auto"} className="ms-auto">
                                <Button className="btn-create" onClick={handleCreateButtonClick}>
                                    Create
                                </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Paper sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: "450px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Master Name</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography className={row?.is_active ? 'text-status-active' : 'text-status-inactive'}>{row?.is_active ? "Active" : "Inactive"}</Typography>
                                            {/* <Typography className={row.is_active === 'Active' ? 'text-status-active' : 'text-status-inactive'}>{row?.is_active ? "Active" : "Inactive"}</Typography> */}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button onClick={()=> handleEdit(row)}><EditIcon/></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <CustomPagination page={page} count={items.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
                </Paper>
            </Box>
        </SideBarContent>
    );
}

const mapStateToProps = state => {
    return {
        misc_data: state.master.misc_data,
    }
}

export default connect
  (mapStateToProps, {getMiscData, postMiscData})(Masters);
