import { useState } from 'react';
import {
  addressValidation,
  cityValidation,
  companyAboutValidation,
  companCINValidation,
  companPanValidation,
  companyNameValidation,
  emailValidation,
  nameValidation,
  companGstValidation,
  companGstReasonValidation,
  productPhotosValidation,
  productNameValidation,
  subCategoryValidation,
  productDescriptionValidation,
  priceValidation,
  unitValidation,
  orderQuantityValidation,
  logoPhotoValidation,
  coverPhotoValidation,
  locationValidation,
  socialLinkValidation,
  stateValidation,
  mobileNumberValidation,
  otpValidation,
  roleValidation,
  roleValidationCheckbox,
  masterNameValidation,
  moduleValuesValidation,
  masterValuesArrayValidation,
  assignRoleUser,
  assignRoleUserArr,
  categoryNameValidation,
  uploadHomepageIcon,
  uploadCategoryImage,
  setRanking,
  parentCategory,
  categoryNameChildValidation,
  adminUserValidation,
  pageTitleValidation,
  pageContentValidation,
  socialMediaNameValidation,
  socialMediaImageValidation,
  widgetNameValidation,
  widgetTypeValidation,
  widgetTemplate,
  widgetImage,
  widget_bannerSeqNumber,
  widgetButton_src,
  widgetButton_text,
  widgetSub_title,
  widgetTitle,
  isActive,
  rank,
  plan_name,
  plan_name_,
  plan_price,
  plan_services,
  plan_tax,
  plan_discount,
  plan_total_ammount,
  plan_name_DescriptionValidation,
  bannerName,
  widgetColor,
  taxNameValidation,
  templateNameValidation,
  subjectNameValidation,
  selectTemplateValidation,
} from '../utils/validation';

const useValidation = () => {
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    city: '',
    state: '',
    address: '',
    mobileNumber: '',
    company_name: '',
    about_company: '',
    cin: '',
    gst: '',
    photos: '',
    product_name: '',
    product_subCategory: '',
    product_description: '',
    product_price: '',
    product_unit: '',
    product_orderQuantity: '',
    logoImage: '',
    coverPhoto: '',
    location: '',
    socialLink: '',
    otp: '',
    role: '',
    check: '',
    master_name: '',
    values_field: '',
    values_array: [],
    assign: '',
    assignArr: [],
    category_name: '',
    homepage_icon: [],
    category_image: [],
    set_ranking: '',
    parent_category: '',
    category_name_child: '',
    onlyAdmin: '',
    pageTitle: '',
    pageContent: '',
    social_name: '',
    social_image: '',
    widget_name: '',
    widget_type: '',
    template: '',
    title: '',
    sub_title: '',
    button_text: '',
    button_src: '',
    widget_bannerSeqNumber: '',
    image: '',
    is_active: '',
    rank: '',
    duration: '',
    price: '',
    services: '',
    tax: '',
    discount: '',
    total_amount: '',
    description: '',
    page_name: '',
    widget_color: '',
    tax_name: '',
    templateName: '',
    subject: '',
    selectType: '',
  });

  const validateInput = async (name, value) => {
    try {
      const validationSchema = {
        name: nameValidation,
        email: emailValidation,
        city: cityValidation,
        address: addressValidation,
        mobileNumber: mobileNumberValidation,
        location: locationValidation,
        company_name: companyNameValidation,
        about_company: companyAboutValidation,
        cin: companCINValidation,
        pan: companPanValidation,
        gst: companGstValidation,
        gst_reason: companGstReasonValidation,
        photos: productPhotosValidation,
        logoImage: logoPhotoValidation,
        coverPhoto: coverPhotoValidation,
        product_name: productNameValidation,
        product_subCategory: subCategoryValidation,
        product_description: productDescriptionValidation,
        product_price: priceValidation,
        product_unit: unitValidation,
        product_orderQuantity: orderQuantityValidation,
        socialLink: socialLinkValidation,
        state: stateValidation,
        otp: otpValidation,
        role: roleValidation,
        check: roleValidationCheckbox,
        master_name: masterNameValidation,
        values_field: moduleValuesValidation,
        values_array: masterValuesArrayValidation,
        assign: assignRoleUser,
        assignArr: assignRoleUserArr,
        category_name: categoryNameValidation,
        homepage_icon: uploadHomepageIcon,
        category_image: uploadCategoryImage,
        set_ranking: setRanking,
        parent_category: parentCategory,
        category_name_child: categoryNameChildValidation,
        onlyAdmin: adminUserValidation,
        pageTitle: pageTitleValidation,
        pageContent: pageContentValidation,
        social_name: socialMediaNameValidation,
        social_image: socialMediaImageValidation,
        widget_name: widgetNameValidation,
        widget_type: widgetTypeValidation,
        template: widgetTemplate,
        title: widgetTitle,
        sub_title: widgetSub_title,
        button_text: widgetButton_text,
        button_src: widgetButton_src,
        widget_bannerSeqNumber: widget_bannerSeqNumber,
        image: widgetImage,
        is_active: isActive,
        rank: rank,
        duration: plan_name_,
        price: plan_price,
        total_amount: plan_total_ammount,
        services: plan_services,
        tax: plan_tax,
        discount: plan_discount,
        description: plan_name_DescriptionValidation,
        page_name: bannerName,
        widget_color: widgetColor,
        tax_name: taxNameValidation,
        templateName: templateNameValidation,
        subject: subjectNameValidation,
        selectType: selectTemplateValidation,
      };

      await validationSchema[name].validate(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  return { errors, validateInput, setErrors };
};

export default useValidation;
