import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Grid,
  Link,
  Pagination,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import IOSSwitch from '../../component/Switch/Switch';
import { useHistory } from 'react-router-dom';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import {
  editHomepageWidget,
  getHomepageWidgetDetails,
  setWidgetData,
} from './redux/actions/homepage-widget-action';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';

function HomepageWidgetForm({
  all_widget,
  getHomepageWidgetDetails,
  setWidgetData,
  editHomepageWidget,
  pushMessage,
}) {
  //const navigate=useNavigate();
  const [searchText, setSearchText] = useState('');

  const banner_sequences = Array.from(
    { length: all_widget?.length },
    (_, index) => index + 1
  );
  console.log('==home widget banner_sequences', banner_sequences);
  const [isChanged, setIsChanged] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  // const history = useHistory();

  useEffect(() => {
    getHomepageWidgetDetails();
  }, []);
  // console.log("all_widget+++++++", all_widget);

  const handleRank = (e, item) => {
    console.log('items_data', item);
    // We search if theres a item with this selected rank (e.target.value)
    const existingRankIdx = all_widget.findIndex(
      (itm) => itm.rank == e.target.value
    );
    if (existingRankIdx !== -1) {
      // if theres and existing item with this selected rank (e.target.value) we will update them as below
      const existingItem = all_widget[existingRankIdx];
      console.log('existingItem_data :', existingItem);
      // First changing founded Existing Rank Item
      updateAllWidgetState(existingItem, 'rank', item.rank);
      setIsChanged(true);

      // Now setting this new Rank value to current Item
      updateAllWidgetState(item, 'rank', e.target.value);
      setIsChanged(true);
    } else {
      // Else this new selected rank (e.target.value) is not being used and can be set directly
      updateAllWidgetState(item, 'rank', e.target.value);
      setIsChanged(true);
    }
  };

  const handleStatusChange = (checked, item) => {
    updateAllWidgetState(item, 'is_active', checked);
    setIsChanged(true);
  };

  const updateAllWidgetState = (item, key, value) => {
    let newAllWidgets = [...all_widget];
    const itmIdx = all_widget.findIndex((itm) => itm.id === item.id);
    if (itmIdx !== -1) {
      newAllWidgets[itmIdx][key] = value;
    }
    setWidgetData(newAllWidgets);
  };

  const handleUpdateWidget = async () => {
    editHomepageWidget(
      all_widget,
      () => {
        pushMessage('Successfully updated');
        getHomepageWidgetDetails();
      },
      (error) => {
        pushMessage(error);
      }
    );
    setIsChanged(false);
  };

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table
              stickyHeader
              className="homepage-widget-table"
              aria-label="sticky table "
            >
              <TableBody>
                {all_widget.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {/* Profile Completion */}
                      {item.widget_name}
                    </TableCell>
                    <TableCell>
                      {/* <FormControl sx={{ width: "250px" }}>
                                                <Select
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="">Select</MenuItem>
                                                    <MenuItem value="Select 1">Pending</MenuItem>
                                                    <MenuItem value="Select 2">Contacted </MenuItem>
                                                </Select>
                                            </FormControl> */}
                      <FormControl fullWidth sx={{ width: '250px' }}>
                        <Select
                          value={item?.rank}
                          onChange={(e) => handleRank(e, item)}
                          inputProps={{ 'aria-label': 'Without label' }}
                          // disabled={!is_rank}
                          // defaultValue="0"
                        >
                          {/* <MenuItem value="0" disabled selected> */}
                          <MenuItem value="0" disabled selected>
                            Select Rank
                          </MenuItem>
                          {banner_sequences?.map((rank) => (
                            <MenuItem
                              key={rank}
                              value={rank}
                              // disabled={used_rank_array?.includes(rank)}
                              // disabled={handleCheckRank(rank)}
                            >
                              {rank}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        checked={item?.is_active}
                        onChange={(e, checked) =>
                          handleStatusChange(checked, item)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <CustomPagination page={page} count={data.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} /> */}
        </Paper>
        {
          all_widget?.length > 0 && (
            <Box className="d-flex justify-content-end mt-5">
          <div className="d-flex">
            <Button
              className={!isChanged ? 'btn-cancel' : 'btn-save'}
              onClick={handleUpdateWidget}
              disabled={!isChanged}
            >
              Save
            </Button>
            {/* btn-cancel */}
          </div>
        </Box>
          )
        }
        
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = (state) => {
  const { all_widget } = state.widget;
  return {
    all_widget,
  };
};

export default connect(mapStateToProps, {
  getHomepageWidgetDetails,
  setWidgetData,
  editHomepageWidget,
  pushMessage,
})(HomepageWidgetForm);
