import { GET_REQUIREMENT_DATA, SET_REQUIREMENT_FILTER, EDIT_REQUIREMENT_DATA, STATUS_DATA } from '../types';
const INITIAL_STATE = {
    all_requiremnt: [],
    all_requirement_filter: [],
    requirementObj: {},
    status_data: {}
}

const requirementListData = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case GET_REQUIREMENT_DATA:
            return {
                ...state,
                all_requiremnt: action.payload
            }
        case SET_REQUIREMENT_FILTER:
            return{
                ...state,
                all_requirement_filter: action.payload
            }
        case EDIT_REQUIREMENT_DATA:
            return{
                ...state,
                requirementObj: action.payload
            }
        case STATUS_DATA:
            return {
                ...state,
                status_data: action.payload
            }
        default:
            return state;
    }
}

export default requirementListData;