import { GET_B2B_DATA, GET_B2B_USER } from '../types';
const initial = {
  b2bList: [],
  b2bUser: [],
  loading: true,
};
export default function b2bReducer(state = initial, action) {
  switch (action.type) {
    case GET_B2B_DATA:
      return { ...state, b2bList: action.payload, loading: false };
    case GET_B2B_USER:
      return { ...state, b2bUser: action.payload, loading: false };
    default:
      return state;
  }
}
