import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextareaAutosize, Typography } from '@mui/material';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import { connect } from 'react-redux';
import CustomDropdown from '../../component/CustomDropdown';
import { editEnquiryData, getEnquiryDetails, getStatusDetails, setClearEnquiryData, setEnquiryData } from './redux/actions/enquiry-action';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import CloseIcon from '@mui/icons-material/Close';


const CustomModal = ({ open, onClose, enquiryObj, setEnquiryData, editEnquiryData, setClearEnquiryData, getEnquiryDetails, pushMessage, userSelect, getStatusDetails, status_data, oldStatus }) => {

  const [count, setCount] = useState(0);


  const handleSave = async () => {
    const jsonData = JSON.stringify({
      status: enquiryObj?.status,
      user_comment: enquiryObj?.user_comment,
    }
    );

    const customConfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    await editEnquiryData(enquiryObj?.id, jsonData, customConfig, () => {
      getEnquiryDetails();
      pushMessage("Updated Successfully");
      setClearEnquiryData();
    }, (error) => { pushMessage(error) })
    // Handle save logic here
    onClose();
  };

  const handleCancel = () => {
    setClearEnquiryData();
    onClose(true); // Close the modal
  };

  const handleDropDown = (e, value) => {
    let obj = { ...enquiryObj }
    obj.status = value.value;
    console.log("obj.statsus @@@:", obj.status)
    setEnquiryData(obj);
  }

  useEffect(() => {
    getStatusDetails();
  }, [])

  const statusOptions = status_data || {};
  console.log("statusOptions$$$$$$$$$ :", statusOptions)
  const getEnquiryStatusObject = () => {
    let statusObj = null;
    console.log("enquiryObjs : ", enquiryObj?.status)
    const valuesArray = Object.values(statusOptions);
    statusObj = valuesArray.find(item => item?.value?.toLowerCase().includes(enquiryObj?.status?.toLowerCase()))
    console.log("statusObj_data :", statusObj);
    if (statusObj) {
      return statusObj;
    }
    else {
      return null
    }

  }


  useEffect(() => {
    console.log("data_status_enquiry :",enquiryObj?.status);
  },[enquiryObj?.status])

  const handleEnquiryComment = (e) => {
    if (e.target.value.length >= 151) {
      pushMessage("The number of characters exceeded.");
      return;
    } else {
      let obj = { ...enquiryObj }
      obj.user_comment = e.target.value;
      setCount(obj?.user_comment?.length);
      setEnquiryData(obj);
    }
  }


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="custom-border-header">
        <Grid container className="d-flex justify-content-between align-items-center">
          Update Status
          <IconButton aria-label="close" onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className="col-md-12 mb-3">
          <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputProductCategory">Status</InputLabel>
          <FormControl fullWidth sx={{ my: 1 }}>
            <CustomDropdown
              id="enquiryInput"
              disabled={userSelect === "view"}
              value={getEnquiryStatusObject()}
              options={statusOptions}
              inputPlaceholder="Select Status"
              onChange={handleDropDown
              }
              labelKey="value"
              optionKey="id"
              inputProps={{ disabled: true }}
            />
          </FormControl>
        </div>

        <div className="col-md-12 mb-2">
          <div className="d-flex justify-content-between">
            <InputLabel className="text-start fs-14 text-black mb-0 font-skmodernist" htmlFor="inputPlanName">Comment</InputLabel>
            <Typography
              variant="body1"
              align="left"
              className="fs-12 text-grey fw-normal mb-0 font-gotham"
              gutterBottom>
              {150 - count} Characters Left
            </Typography>
          </div>

          <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
            <TextareaAutosize
              id="inputAdditionalInfo"
              placeholder="Enter Comments"
              disabled={userSelect === "view"}
              minRows={3} // You can adjust the number of rows as needed
              maxRows={2} // You can adjust the number of rows as needed
              maxLength={150}
              value={enquiryObj?.user_comment}
              onChange={handleEnquiryComment}
            />
          </FormControl>
        </div>


      </DialogContent>
      {oldStatus !== 'closed' &&<DialogActions>
        <Button onClick={handleCancel} className="btn-cancel me-2">
          Cancel
        </Button>
        <Button onClick={handleSave} className="btn-save">
          Save
        </Button>
      </DialogActions>}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { enquiryObj, all_enquiry, comments, status, status_data } = state.enquiry;
  return {
    enquiryObj,
    all_enquiry,
    comments,
    status,
    status_data
  }
}

export default connect(mapStateToProps, { setEnquiryData, editEnquiryData, setClearEnquiryData, getEnquiryDetails, pushMessage, getStatusDetails })(CustomModal);
