import { MiscellaneousAPI } from '../../../../apis/MiscellaneousAPI';
import { SET_LOADING } from '../../../../store/types';
import {
	ADD_TO_LIST,
	USER_INPUT,
	CHECKBOX_STATUS,
	SET_VALUES_ARRAY,
	GET_MISC_DATA,
	POST_MISC_DATA,
	MISC_MODULE_TITLE,
	MISC_MODULE_VALUE,
	RESET_VALUES_ARRAY,
	RESET_CREATE_MISC_DATA,
	RESET_MISC_ALL_DATA,
	GET_MODULE_DATA,
	MISC_MODULE_ADMIN,
	MISC_MODULE_ADMIN_VALUES,
	MISC_STATUS,
	GET_SINGLE_MISC_DATA,
} from '../types';

export const addToList = (input) => ({
	type: ADD_TO_LIST,
	payload: input,
});

export const addInput = (input) => ({
	type: USER_INPUT,
	payload: input,
});

export const updateCheckbox = (input) => ({
	type: CHECKBOX_STATUS,
	payload: input,
});

export const setValuesArray = (arr) => ({
	type: SET_VALUES_ARRAY,
	payload: arr,
});

export const setMastersName = (input) => ({
	type: MISC_MODULE_TITLE,
	payload: input,
});

export const setMastersValue = (input) => ({
	type: MISC_MODULE_VALUE,
	payload: input,
});

export const resetMiscData = () =>
	function (dispatch) {
		dispatch({ type: RESET_CREATE_MISC_DATA });
	};

export const postMiscData = (input) => {
	return (dispatch) => {
		dispatch({ type: POST_MISC_DATA, payload: input });
	};
};

export const toggleStatus = (input) => ({
	type: MISC_STATUS,
	payload: input,
});

export const getMiscData = (sucessCallBack = () => {}) => {
	return async (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: true,
		});
		try {
			const response = await MiscellaneousAPI.getMisc();
			console.log('getMiscData Response_data :', response);
			if (response.status === 200) {
				sucessCallBack(response.data.data);
				dispatch({ type: GET_MISC_DATA, payload: response.data.data });
				dispatch({ type: SET_LOADING, payload: false });
			} else {
				dispatch({ type: SET_LOADING, payload: false });
			}
		} catch (error) {
			dispatch({ type: SET_LOADING, payload: false });
			console.log('error :', error);
		}
	};
};
export const getSingleMiscData = (id) => {
	return async (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: true,
		});
		try {
			const response = await MiscellaneousAPI.getSingleMisc(id);
			console.log('Response_data :', response.data.module);
			if (response.status === 200) {
				dispatch({ type: GET_SINGLE_MISC_DATA, payload: response.data.module });
				dispatch({ type: SET_LOADING, payload: false });
			} else {
				dispatch({ type: SET_LOADING, payload: false });
			}
		} catch (error) {
			dispatch({ type: SET_LOADING, payload: false });
			console.log('error :', error);
		}
	};
};
export const createMiscdata = (
	data,
	successCallback = () => {},
	errorCallback = () => {}
) => {
	return async (dispatch) => {
		dispatch({ type: SET_LOADING, payload: true });
		try {
			const response = await MiscellaneousAPI.createMisc(data);
			console.log('response data is', response);
			if (response.status === 201) {
				successCallback(response.data);
				dispatch({ type: SET_LOADING, payload: false });
				dispatch({ type: RESET_VALUES_ARRAY });
				dispatch({ type: RESET_CREATE_MISC_DATA });
			} else {
				dispatch({ type: SET_LOADING, payload: false });
			}
		} catch (error) {
			dispatch({ type: SET_LOADING, payload: false });
			console.log('error is', error);
			errorCallback(error.header.data);
		}
	};
};

export const getModuleDataByAdminId = (id) => {
	return async (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: true,
		});
		try {
			const response = await MiscellaneousAPI.editMisc(id);
			console.log('Response_data :', response);
			if (response.status === 200) {
				let miscData = { ...response.data };
				if (response.data?.module) {
					let newModuleArr = [...response.data?.module].map((itm) => {
						let newItm = { ...itm };
						newItm.checkid = itm.id;
						return newItm;
					});
					miscData.module = newModuleArr;
				}

				dispatch({ type: POST_MISC_DATA, payload: miscData });
			} else {
				dispatch({ type: SET_LOADING, payload: false });
			}
			dispatch({ type: SET_LOADING, payload: false });
		} catch (error) {
			console.log('error :', error);
			dispatch({ type: SET_LOADING, payload: false });
		}
	};
};

export const putMiscdata = (
	data,
	successCallback = () => {},
	errorCallback = () => {}
) => {
	return async (dispatch) => {
		dispatch({ type: SET_LOADING, payload: true });
		try {
			const response = await MiscellaneousAPI.putMisc(data);
			console.log('response data is', response);
			if (response.status === 200) {
				successCallback(response.data);
				dispatch({ type: SET_LOADING, payload: false });
			} else {
				dispatch({ type: SET_LOADING, payload: false });
			}
		} catch (error) {
			dispatch({ type: SET_LOADING, payload: false });
			console.log('error is', error);
			errorCallback(error.header.data);
		}
	};
};
