import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Box, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import { ReactComponent as HomepageIcon } from '../../assets/homepage-icon.svg';
import { ReactComponent as TileImage } from '../../assets/tile-img1.svg';
import { ReactComponent as EditIcon } from '../../assets/cat-edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';


const AddTilesModal = ({ open, onClose }) => {
    const [planName, setPlanName] = useState('');
    const [redirect, setRedirect] = useState('');
    const [bannerSeqNumber, setBannerSeqNumber] = useState('');
    const [isRank, setIsRank] = useState(true);
    const [isActive, setIsActive] = useState(true);

    const handleSave = () => {
        // Handle save logic here
        onClose();
    };

    const handleCancel = () => {
        onClose(true); // Close the modal
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="custom-border-header">Add Tiles Details</DialogTitle>
            <DialogContent>
                <div className="row align-items-end">
                    <div className="col-md-6 mb-2">
                        <InputLabel
                            htmlFor="inputCIN"
                            className="text-start fs-14 text-black font-skmodernist">
                            Add Image
                        </InputLabel>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}>
                            <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
                        </Button>
                    </div>

                    <div className="col-md-6 d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex align-items-center">
                            <Box className="modal-tile-field">
                                <TileImage className="modal-tile-media" />
                            </Box>
                            <Typography
                                variant="body1"
                                align="left"
                                className="fs-14 text-darkgrey fw-normal mb-0 ms-3 font-skmodernist"
                                gutterBottom
                            >
                                image.jpg
                            </Typography>
                        </div>
                        <div className="d-flex">
                            {/* <IconButton className="cat-edit-icon " >
                                <EditIcon />
                            </IconButton> */}
                            <IconButton className="cat-delete-icon" >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-2">
                        <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputPlanName">Tile Name</InputLabel>
                        <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                            <OutlinedInput
                                id="inputPlanName"
                                type="text"
                                value={planName}
                                onChange={(e) => setPlanName(e.target.value)}
                                placeholder="Enter tile name"
                                required
                                autoFocus
                                fullWidth
                                margin='none'
                            />
                        </FormControl>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-2">
                        <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputProductCategory">Redirect To</InputLabel>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <Select
                                value={redirect}
                                onChange={(e) => setRedirect(e.target.value)}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value="Select 1">Select 1</MenuItem>
                                <MenuItem value="Select 2">Select 2</MenuItem>
                                <MenuItem value="Select 3">Select 3</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="col-md-6 mb-2">
                        <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputProductCategory">Tiles Seq Number</InputLabel>
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <Select
                                value={bannerSeqNumber}
                                onChange={(e) => setBannerSeqNumber(e.target.value)}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value="Select 1">Select 1</MenuItem>
                                <MenuItem value="Select 2">Select 2</MenuItem>
                                <MenuItem value="Select 3">Select 3</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>











                <div className="row">
                    <div className="col-4 custom-control custom-checkbox">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isActive}
                                    onChange={(e) => setIsActive(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Active"
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} className="btn-cancel me-2">
                    Cancel
                </Button>
                <Button onClick={handleSave} className="btn-save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddTilesModal;