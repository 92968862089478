import {
    LOADING,
    SAVE_MOBILE_NO,
    SAVE_COUNTRY_CODE,
    SAVE_COUNTRY_CODE_NAME,
    RESET_LOGIN_DATA,
    CHECKED_IS_ADMIN,
  } from '../types';
  
  const INITIAL_STATE = {
    mobile_number: '',
    country_code: '+91',
    loading: false,
    error: '',
    country_code_name: 'IN',
    // admin_access: null
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case LOADING:
        return {
          ...state,
          loading: action.payload,
          error: '',
        };
      case SAVE_MOBILE_NO:
        return { ...state, mobile_number: action.payload };
  
      case SAVE_COUNTRY_CODE:
        return { ...state, country_code: action.payload };
  
      case SAVE_COUNTRY_CODE_NAME:
        return { ...state, country_code_name: action.payload };
      case RESET_LOGIN_DATA:
        return INITIAL_STATE
      // case CHECKED_IS_ADMIN:
      //   return {
      //     ...state,
      //     admin_access: action.payload
      //   }
      default:
        return state;
    }
  };
  