import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as OptionsIcon } from '../../assets/options-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import RequirementsUpdateStatusModal from './RequirementsUpdateStatusModal';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  getRequirementDetails,
  setRequirementData,
  setRequirementFilter,
} from './redux/actions/requirement-action';
import { connect } from 'react-redux';
import moment from 'moment';


function RequirementsMaster({
  getRequirementDetails,
  all_requirement_filter,
  setRequirementFilter,
  all_requiremnt,
  setRequirementData,
  requirementObj
}) {
  //const navigate=useNavigate();
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  // const [rows, setRows] = useState(data);

  // useEffect(() => {
  //     console.log("Filtered items:", rows);
  // }, [rows]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchText(searchTerm, "searchValue");
    //   if (searchTerm === "") {
    //       // If search term is empty, display the original list
    //       setRows(data);
    //   } else {
    //       // Filter the list based on the search term
    //       const filteredItems = rows.filter((item) =>
    //           item.name.toLowerCase().includes(searchTerm)
    //       );
    //       setRows(filteredItems);
    //   }
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
  const [popperText, setPopperText] = useState('');
  const [type, setType] = useState("view")

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setCurrentPage((prevPage) => {
      if (prevPage === 0) {
        return newPage
      } else {
        return prevPage
      }
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setRequirementData(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    'Update Status',
    'View'
  ];

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (searchText === '') {
      setRequirementFilter(all_requiremnt);
      setPage(currentPage);
    } else {
      setPage(0);
      const filteredItems = all_requiremnt?.filter(
        (item) =>
          item?.product_service_name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          item?.category?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          item?.phone_number?.includes(searchText)
      );
      setRequirementFilter(filteredItems);
    }
  }, [all_requiremnt, searchText]);

  useEffect(() => {
    getRequirementDetails();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [popper, setPopper] = React.useState(null);

  const handleClickPopper = (event, description) => {
    setPopper(event.currentTarget);
    setPopperText(description);
  };

  const handleClosePopper = () => {
    setPopper(null);
    setPopperText('');
  };
  const openPopper = Boolean(popper);
  const idd = openPopper ? 'simple-popover' : undefined;

  function truncateText(text, length = 16) {
    if (text?.length <= length) {
      return text;
    }

    return text.substr(0, length) + '\u2026';
  }

  const data = [
    {
      id: 1,
      businessType: 'Product',
      productServiceName: 'Stabilizer',
      category: 'Electronic',
      orderQtyBudget: '20',
      location: 'Mumbai',
      description: 'This is a dummy text read it more',
      status: 'Pending',
      optionsIcon: <OptionsIcon />,
    },
    {
      id: 2,
      businessType: 'Service',
      productServiceName: 'Facebook Ad',
      category: 'Social Media',
      orderQtyBudget: '₹ 1000',
      location: 'Pune',
      description: 'Dummy text test case...',
      status: 'Fullfilled',
      optionsIcon: <OptionsIcon />,
    },
    {
      id: 3,
      businessType: 'Product',
      productServiceName: 'Stabilizer',
      category: 'Electronic',
      orderQtyBudget: '20',
      location: 'Mumbai',
      description: 'Dummy text...',
      status: 'Pending',
      optionsIcon: <OptionsIcon />,
    },
    {
      id: 4,
      businessType: 'Service',
      productServiceName: 'Facebook Ad',
      category: 'Social Media',
      orderQtyBudget: '₹ 1000',
      location: 'Pune',
      description: 'This is a dummy text read it more',
      status: 'Fullfilled',
      optionsIcon: <OptionsIcon />,
    },
    {
      id: 5,
      businessType: 'Product',
      productServiceName: 'Stabilizer',
      category: 'Electronic',
      orderQtyBudget: '20',
      location: 'Mumbai',
      description: 'Dummy text...',
      status: 'On Hold',
      optionsIcon: <OptionsIcon />,
    },
    {
      id: 6,
      businessType: 'Service',
      productServiceName: 'Facebook Ad',
      category: 'Social Media',
      orderQtyBudget: '₹ 1000',
      location: 'Pune',
      description: 'This is a dummy text read it more',
      status: 'Fullfilled',
      optionsIcon: <OptionsIcon />,
    },
  ];

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="align-items-center just-content-between"
          >
            <Grid item xs={10} md={6}>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <Search
                      sx={{
                        width: '1em',
                        height: '1em',
                        marginRight: '8px',
                        color: '#864FFD',
                      }}
                      color="action"
                    />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                <TableCell >Req. ID</TableCell>   
                <TableCell >User Details</TableCell>
                  <TableCell sx={{ minWidth: "120px !important" }}>Date</TableCell>
                  <TableCell >Requirement Type</TableCell>     
                  <TableCell>Product/Service Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Order Qty/Budget</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {all_requirement_filter
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row?.code}</TableCell>
                      <TableCell>
                        <Typography sx={{ color: "#393939" }} className="fs-13 font-gotham">{row?.registration?.user?.name}</Typography>
                        <Typography sx={{ color: "#393939" }} className="fs-13 font-gotham">{row?.registration?.user?.email}</Typography>
                        <Typography sx={{ color: "#393939" }} className="fs-13 font-gotham">{row?.registration?.user?.mobile_number}</Typography>
                      </TableCell>
                      <TableCell>
                        {moment(row.createdAt).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>{row?.product_type}</TableCell>

                      <TableCell>{row?.product_service_name}</TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>
                        {row?.product_type.toLowerCase() == 'product'
                          ? row.order_quantity
                          : row.budget}
                      </TableCell>
                      <TableCell>{row.location}</TableCell>
                      <TableCell>
                        <Typography>
                          <span className='desc-text'
                            style={{
                              width: '175px',
                              display: 'inline-block',
                              fontFamily: 'Gotham !important',
                            }}
                          >
                            {truncateText(row?.description || '')}{' '}
                          </span>
                          {row?.description?.length > 16 ? (
                            <Button
                              aria-describedby={idd}
                              className="ps-2 justify-content-start btn-tooltip"
                              onClick={(e) =>
                                handleClickPopper(e, row.description)
                              }
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  width: '19px',
                                  height: '19px',
                                  color: '#864FFD',
                                }}
                              />
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* <Typography className={row.status === 'Pending' ? 'text-status-pending' : 'text-status-contacted'}>{row.status}</Typography> */}
                        <Typography
                          className={
                            row.status === 'pending'
                              ? 'text-status-pending'
                              : row.status === 'fulfilled'
                                ? 'text-status-contacted'
                                : row.status === 'deleted'
                                  ? 'text-status-inactive'
                                  : 'text-status-hold'
                          }
                        >

                          {row.status}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {/* <Button onClick={handleClick}>{row.optionsIcon}</Button> */}
                        <Button onClick={e => handleClick(e, row)}><OptionsIcon /></Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Popover
            id={idd}
            open={openPopper}
            anchorEl={popper}
            onClose={handleClosePopper}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography className="fs-16 fw-normal font-gotham text-black">
                {popperText}
              </Typography>
            </Box>
          </Popover>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option, optionIdx) => {
              let renderOption = false;
              if (optionIdx === 0 && requirementObj?.status === "pending") {
                renderOption = true; // Hide "Update Status" when status is not "pending"
              }
              if (optionIdx === 0 && requirementObj?.status === "hold") {
                renderOption = true; // Hide "View" when status is not "contacted"
              }
              if (optionIdx === 1 && requirementObj?.status === "fulfilled") {
                renderOption = true; // Hide "Update Status" when status is not "closed"
              }
              if (optionIdx === 1 && requirementObj?.status === "cancelled") {
                renderOption = true; // Hide "Update Status" when status is not "closed"
              }
              if (renderOption) {
                return (
                  <MenuItem
                    // elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    sx={{
                      boxShadow:
                        '0px 0px 0px 1px rgba(0,0,0,-1.8), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 4px -1px rgba(0,0,0,0.10) !important',
                    }}
                    key={option}
                    selected={option === 'Updated Status'}
                    onClick={() => {
                      if (optionIdx === 0) {
                        setType("edit")
                        handleClose();
                        handleOpenModal();
                      }
                      if (optionIdx === 1) {
                        setType("view")
                        handleClose()
                        handleOpenModal()
                      }
                    }}
                  >
                    {option}
                  </MenuItem>
                )
              }
            })}
          </Menu>
          <RequirementsUpdateStatusModal
            open={openModal}
            onClose={handleCloseModal}
            userSelect={type}
          />
          <CustomPagination
            page={page}
            count={all_requirement_filter.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = (state) => {
  const { all_requiremnt, all_requirement_filter, requirementObj } = state.requiremnt;
  return {
    all_requiremnt,
    all_requirement_filter,
    requirementObj
  };
};

export default connect(mapStateToProps, {
  getRequirementDetails,
  setRequirementFilter,
  setRequirementData
})(RequirementsMaster);
