import { RESET_LOADING_DATA, SET_HEADER_TITLE, SET_LOADING, SET_OPEN_DRAWER } from "../types";


export const setLoading = (load) => function (dispatch) {
    dispatch({
        type: SET_LOADING,
        payload: load
    })
}

export const setHeaderTitle = (title) => function (dispatch) {
    dispatch({ type: SET_HEADER_TITLE, payload: title })
}

export const setOpen = (open) => function (dispatch) {
    dispatch({ type: SET_OPEN_DRAWER, payload: open })
}

export const resetLoading = () => function (dispatch) {
    dispatch({
        type: RESET_LOADING_DATA
    })
}