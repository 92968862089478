import React, { useEffect } from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import { connect } from "react-redux";
import { editProfileUserMaster, setProfileMasterData } from "./redux/actions/profile-action";
import { pushMessage } from "../../component/MessageBox/redux/actions/message-box-action";
import { useHistory } from "react-router";
import useValidation from "../../hooks/useValidation";


function ProfileForm({ userData, profileObj, setProfileMasterData, editProfileUserMaster, pushMessage }) {

    const history = useHistory();
    const { validateInput, errors } = useValidation();


    const handleName = (e) => {
        let obj = { ...profileObj }
        obj.name = e.target.value;
        validateInput("name", obj?.name);
        setProfileMasterData(obj);
    }


    const handleEmail = (e) => {
        let obj = { ...profileObj }
        obj.email = e.target.value;
        validateInput("email", obj?.email);
        setProfileMasterData(obj);
    }


    const handleProfileEdit = async() => {
        validateInput("name", profileObj?.name);
        validateInput("email", profileObj?.email);
        if (profileObj?.name === '') {
            return
        }
        if (profileObj?.email === '') {
            return
        }
        const jsonData = JSON.stringify({
            name: profileObj?.name,
            email: profileObj?.email,
        }
        );
        const customConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        await editProfileUserMaster(userData?.user?.id,jsonData, customConfig, userData , () => {
            pushMessage("Updated Successfully");
            // setClearUserData();
            // history.push('/');
        }, (error) => { pushMessage(error) });
    }

    const handleProfileCancel = () => {
        history.push("/")
    } 

    useEffect(() => {
        setProfileMasterData({name: userData?.user?.name, email: userData?.user?.email})
    },[userData])

    return (
        <SideBarContent>
            <Box className="custom-card-bg">
                <Box className="mb-4">
                    {/* <Box className="d-flex justify-content-end">
                        <Button className="btn-edit">Edit</Button>
                    </Box> */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 10 }} className="align-items-center justify-content-between">
                        <Grid item xs={10} md={6} >
                            <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputName">Name</InputLabel>
                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                <OutlinedInput
                                    id="inputName"
                                    type="text"
                                    placeholder="Input"
                                    required
                                    autoFocus
                                    fullWidth
                                    margin='none'
                                    defaultValue={userData?.user?.name}
                                    value={profileObj?.name}
                                    onChange={handleName}
                                />
                            </FormControl>
                            <FormHelperText sx={{ color: 'red' }} id="name-helper-text">
                                {errors.name}
                            </FormHelperText>
                        </Grid>
                        {/* <Grid item xs={10} md={6} >
                            <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputName">UserName</InputLabel>
                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                <OutlinedInput
                                    id="inputName"
                                    type="text"
                                    placeholder="Input"
                                    required
                                    autoFocus
                                    fullWidth
                                    margin='none'
                                />
                            </FormControl>
                        </Grid> */}

                        <Grid item xs={10} md={6} >
                            <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputName">Email</InputLabel>
                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                <OutlinedInput
                                    id="inputName"
                                    type="text"
                                    placeholder="Input"
                                    required
                                    autoFocus
                                    fullWidth
                                    margin='none'
                                    defaultValue={userData?.user?.email}
                                    value={profileObj?.email}
                                    onChange={handleEmail}
                                />
                            </FormControl>
                            <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                                {errors.email}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={10} md={6} >
                            <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputName">Phone</InputLabel>
                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                <OutlinedInput
                                    id="inputName"
                                    type="text"
                                    placeholder="Input"
                                    required
                                    autoFocus
                                    fullWidth
                                    margin='none'
                                    value={userData?.user?.mobile_number}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} md={6} >
                            <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputName">Role</InputLabel>
                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                <OutlinedInput
                                    id="inputName"
                                    type="text"
                                    placeholder="Input"
                                    required
                                    autoFocus
                                    fullWidth
                                    margin='none'
                                    value={userData?.roles[0]?.name}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={10} md={6}>
                            <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputProductCategory">Role</InputLabel>
                            <FormControl fullWidth sx={{ my: 1 }}>
                                <Select
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="Select 1">Designer</MenuItem>
                                    <MenuItem value="Select 2">Web Developer </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                    </Grid>


                    <Box className="d-flex justify-content-end my-4">
                        <Button className="btn-cancel me-3" onClick={handleProfileCancel}>Cancel</Button>
                        <Button className="btn-save" onClick={handleProfileEdit}>Update</Button>
                    </Box>
                </Box>
            </Box>
        </SideBarContent>
    );
}


const mapStateToProps = (state) => {
    const { userData } = state.signin;
    const { profileObj } = state.profile;
    return {
        userData,
        profileObj
    }
}

export default connect(mapStateToProps,{ setProfileMasterData, editProfileUserMaster, pushMessage })(ProfileForm);