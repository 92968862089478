import { Widget } from '../../../../apis/widgetApi';
import { SET_LOADING } from '../../../../store/types';
import {
  ADD_SINGLE_WIDGET,
  GET_ALL_WIDGET,
  ADD_WIDGET,
  GET_WIDGET_DETAILS,
  SET_SELECTED_WIDGET,
  CLEAR_SINGLE_WIDGET,
  ADD_SINGLE_MODULE_WIDGET,
  ADD_WEBCOMPONENT,
} from '../types';
export const addSingleWidget = (widget) => (dispatch) =>
  dispatch({ type: ADD_SINGLE_WIDGET, payload: widget });
export const addSingleModuleWidget = (widget) => (dispatch) =>
  dispatch({ type: ADD_SINGLE_MODULE_WIDGET, payload: widget });
export const postWidget = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await Widget.postWidget(data);
      console.log('====postWidget response', response);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      }else{
        dispatch({type: SET_LOADING, payload: false});
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
    }
  };
};
export const clearWidget = () => (dispatch) =>
  dispatch({ type: CLEAR_SINGLE_WIDGET });
export const editWidget = (
  id,
  widgetData,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await Widget.editWidget(id, widgetData);
      console.log('====editWidget response', response);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data?.message);
        dispatch({ type: SET_LOADING, payload: false });
      }else{
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
    }
  };
};
export const deleteWidget = (
  id,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await Widget.deleteWidget(id);
      console.log('====deleteWidget response', response);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data?.message);
        dispatch({ type: SET_LOADING, payload: false });
      }else{
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
    }
  };
};
export const postWidgetDetails = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await Widget.postWidgetDetails(data);
      if (response.status === 201) {
        successCallback(response.data?.message);
        dispatch({ type: SET_LOADING, payload: false });
        return true;
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
        return false;
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
      return false;
    }
  };
};
export const getAllWebcomponent = (
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await Widget.getWebComponent();
      if (response.status === 200) {
        successCallback(response.data);
        dispatch({
          type: ADD_WEBCOMPONENT,
          payload: response.data?.data,
        });
        dispatch({ type: SET_LOADING, payload: false });
        return true;
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
        return false;
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
      return false;
    }
  };
};

export const putWidgetDetails = (
  id,
  widget,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await Widget.editWidgetDetails(widget, id);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data?.message);
        dispatch({ type: ADD_SINGLE_WIDGET, payload: widget });
        dispatch({ type: SET_LOADING, payload: false });
        return true;
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
        return false;
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
      return false;
    }
  };
};
export const saveSelectedWidgetDetails = (widget_details) => (dispatch) =>
  dispatch({ type: SET_SELECTED_WIDGET, payload: widget_details });
export const getAllWidget = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await Widget.getAllWidget(data);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data.data);
        dispatch({ type: GET_ALL_WIDGET, payload: response.data.data });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
      dispatch({ type: GET_ALL_WIDGET, payload: [] });
    }
  };
};
export const resetWidgetDetails = () => (dispatch) => {
  dispatch({ type: GET_WIDGET_DETAILS, payload: null });
  dispatch({ type: ADD_WIDGET, payload: null });
};
export const getWidgetDetails = (
  id,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await Widget.getWidgetDetails(id);
      console.log('==getWidgetDetails response', response);
      if (response.status === 201 || response.status === 200) {
        successCallback(response.data.data);
        dispatch({ type: GET_WIDGET_DETAILS, payload: response.data.data });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
      dispatch({ type: GET_WIDGET_DETAILS, payload: [] });
    }
  };
};
export const saveWidget = (widget) => (dispatch) =>
  dispatch({ type: ADD_WIDGET, payload: widget });
