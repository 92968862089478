import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  Popover,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import AddIcon from '../../assets/add-icon-white.svg';
import { ReactComponent as WidgetImg } from '../../assets/widget-img.svg';
import { ReactComponent as ProductImage1 } from '../../assets/product1.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as WidgetCheckIcon } from '../../assets/widget-check-icon.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import IOSSwitch from '../../component/Switch/Switch';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import { ModalStyle } from '../../utils/constants';
import { connect } from 'react-redux';
import {
  postSubscriptions,
  addSingleSubscription,
  addParentSubscription,
  clearSingleSubscriptionAfterEdit,
  getSingleSubscriptions,
  putSingleSubscriptions,
  deleteSubscriptions,
  putParentSubscriptions,
  // clearSingleSubscription,
} from './redux/actions/subscription-actions';
import useValidation from '../../hooks/useValidation';
import {
  fetchSubscriptionServices,
  fetchUploadFile,
} from '../ToolsServices/redux/actions/tools-services-action';
import { hasAnyErrorPresent } from '../../utils/validation';
import ErrorFormHelperText from '../../component/ErrorFormHelperText';
import {
  getMiscData,
  getSingleMiscData,
} from '../Masters/redux/actions/masters-action';
import { useLocation } from 'react-router';
import { setHeaderTitle } from '../../store/actions/rootActions';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const REGEX = {
  duration: /^[0-9]{0,2}$/,
  price: /^[0-9]{0,7}$/,
  tax: /^[0-9]{0,2}$/,
  services: /\b\w+\b/,
  discount: /^[0-9]{0,2}$/,
  name: /^[a-zA-Z0-9\s]*$/,
  description: /^[a-zA-Z0-9\s]*$/,
};
const returnPlanType = (plan_type) => {
  let plan_name = '';
  switch (plan_type) {
    case 'monthly':
      plan_name = 'monthly';
      break;
    case 'quarterly':
      plan_name = 'quarterly';
      break;
    case 'half_yearly':
      plan_name = 'half_yearly';
      break;
    case 'annually':
      plan_name = 'annually';
      break;
    default:
      break;
  }
  return plan_name;
};
const planTypes = [
  { title: 'Monthly', plan_type: 'monthly', duration: 1 },
  { title: 'Quarterly', plan_type: 'quarterly', duration: 3 },
  { title: 'Half Yearly', plan_type: 'half_yearly', duration: 6 },
  { title: 'Annual', plan_type: 'annually', duration: 12 },
];

const getPlanDurationtByValue = (value) => {
  const requiredPlan = planTypes.filter((plan) => plan.plan_type === value)[0];
  console.log('===requiredPlan', requiredPlan);
  return requiredPlan ? requiredPlan.duration : 0;
};
function CreateSubscriptionMaster({
  postSubscriptions,
  addSingleSubscription,
  singleSubscription,
  // addParentSubscription,
  parentSubscription,
  pushMessage,
  clearSingleSubscriptionAfterEdit,
  putSingleSubscriptions,
  getSingleSubscriptions,
  deleteSubscriptions,
  // fetchUploadFile,
  putParentSubscriptions,
  // misc_data,
  // getMiscData,
  // getSingleMiscData,
  taxDetails,
  subscription_services,
  fetchSubscriptionServices,
  setHeaderTitle,
  // clearSingleSubscription
}) {
  // State and hooks
  const [subscription_services_list, setsubscription_services_list] =
    React.useState([]);
  const { validateInput, errors, setErrors } = useValidation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [isEditMode, setEditMode] = React.useState(false);
  const [deleteopenModal, setDeleteopenModal] = React.useState(false);
  const [deleteopenId, setDeleteopenId] = React.useState();
  const total_amount = calculateComplexSubscriptionCost();
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [popper, setPopper] = React.useState(null);
  const [popperText, setPopperText] = useState('');
  //
  // Effects
  const { state } = useLocation();
  const comingAsEditMode = state?.isEditMode;
  React.useEffect(() => {
    if (comingAsEditMode) setHeaderTitle('Edit Subscription');
    else setHeaderTitle('Create Subscription');
  }, [comingAsEditMode, setHeaderTitle]);
  React.useEffect(() => {
    fetchSubscriptionServices();
  }, [fetchSubscriptionServices]);

  // useEffect(() => {
  //   getMiscData((res) => {
  //     const filterd_misc = res.filter((n) => n?.name === 'Tax')[0];
  //     getSingleMiscData(filterd_misc.id);
  //   });
  // }, [getMiscData, getSingleMiscData]);

  React.useEffect(() => {
    setsubscription_services_list(
      subscription_services?.map((a) => ({
        title: a.title,
        value: a.title,
      }))
    );
  }, [subscription_services]);
  React.useEffect(() => {
    addSingleSubscription({ total_amount: Number(total_amount) });
  }, [total_amount]);
  //
  React.useEffect(() => {
    console.log('====taxDetails', taxDetails);
  }, [taxDetails]);
  // Functions
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const getLanguageArr = () => {
    let valueArr = [];
    if (subscription_services?.length > 0) {
      subscription_services.forEach((itm) => {
        let langObj = subscription_services_list?.find(
          (itmm) => itmm.value === itm
        );
        if (langObj) {
          valueArr.push(langObj);
        }
      });
    }
    return valueArr;
  };
  const getExicitingLanguageArr = () => {
    // console.log(
    //   'getExicitingLanguageArr singleSubscription',
    //   singleSubscription?.services
    // );
    if (singleSubscription?.services) {
      const newArr = singleSubscription?.services
        .split(',')
        .map((r) => ({ title: r, value: r }));
      return newArr;
    } else {
      return [];
    }
  };

  const handleServiceChange = (e, newChips) => {
    console.log('===handleServiceChange', newChips);
    validateInput('services', newChips?.length);
    // if (newChips?.length > 3) {
    //   pushMessage('You can add up to 3 languages.');
    //   return;
    // }
    let extractedServices = newChips?.map((s) => s.value).join(',') || '';
    addSingleSubscription({ services: extractedServices });
    console.log('===singleSubscription', singleSubscription);
  };

  const handleTaxChange = (e) => {
    console.log('handleTaxChange', e.target);
    validateInput('tax_name', e.target.value);
    addSingleSubscription({ tax_name: e.target.value });
  };
  const handlePlanNameChange = (e) => {
    console.log('handlePlanNameChange', e.target);
    validateInput('duration', e.target.value);
    addSingleSubscription({
      duration: getPlanDurationtByValue(e.target.value),
    });
    addSingleSubscription({
      plan_type: e.target.value,
    });
  };
  const onEditChildScubscription = (details) => {
    setErrors(null);
    addSingleSubscription(details);
    setEditMode(true);
  };
  React.useEffect(() => {
    console.log('===singleSubscription', singleSubscription);
  }, [singleSubscription]);
  const onChange = (event) => {
    const { name, value } = event.target;
    console.log('==onChange here', name, value);
    if (REGEX[name].test(value)) {
      validateInput(name, value);
      // if (name === 'duration') {
      //   const new_price = value
      //     ? value * singleSubscription?.price
      //     : singleSubscription?.price;
      //   console.log('==onChange new price,duration', new_price, name, value);
      //   addSingleSubscription({ duration: value });
      //   addSingleSubscription({ price: new_price });
      // } else {
      //   addSingleSubscription({ [name]: value });
      // }

      addSingleSubscription({ [name]: value });
    }
  };
  const [refresh, setRefresh] = React.useState(false);
  const onSucess = (message) => {
    console.log('===onSucess', message);
    setRefresh((prev) => !prev);
    pushMessage(message);
  };

  const onError = (message) => {
    pushMessage(message);
  };

  const updateStatus = (data) => {
    console.log('updateStatus==', data);
    putSingleSubscriptions(
      data,
      data?.id,
      (r) => {
        getSingleSubscriptions(data?.subscriptionDetailId);
        onSucess(r);
      },
      onError
    );
  };

  const onSubmit = async () => {
    console.log('onSubmit singleSubscription', errors);

    const { duration, price, tax, tax_name, services, discount, id, name } =
      singleSubscription || {};

    if (
      !duration ||
      !price ||
      !tax ||
      !services?.length ||
      !discount ||
      !tax_name
    ) {
      validateInput('duration', duration);
      validateInput('price', price);
      validateInput('tax', tax);
      validateInput('tax_name', tax_name);
      validateInput('services', services?.length);
      validateInput('discount', discount);
    } else {
      if (!hasAnyErrorPresent(errors)) {
        if (isEditMode) {
          const { id, ...rest } = singleSubscription;
          await putSingleSubscriptions(
            { ...rest, id },
            parentSubscription?.subsDetails?.id,
            onSucess,
            onError
          );
          addSingleSubscription({});
          clearSingleSubscriptionAfterEdit();
          await getSingleSubscriptions(parentSubscription?.subsDetails?.id);
        } else {
          if (id) {
            const { id, ...rest } = singleSubscription;
            await putSingleSubscriptions(
              { ...rest },
              parentSubscription?.subsDetails?.id,
              async (msg) => {
                clearSingleSubscriptionAfterEdit();
                await getSingleSubscriptions(
                  parentSubscription?.subsDetails?.id
                );
                fetchSubscriptionServices();
                onSucess(msg);
              },
              onError
            );
          } else {
            if (!name) {
              validateInput('name', name);
            } else {
              if (!hasAnyErrorPresent(errors)) {
                await postSubscriptions(
                  singleSubscription,
                  async (id, message) => {
                    console.log('postSubscriptions success', id);
                    addSingleSubscription({ id });
                    clearSingleSubscriptionAfterEdit();
                    await getSingleSubscriptions(id);
                    onSucess(message);
                  }
                );
              }
            }
          }
        }
      }
    }

    setEditMode(false);
  };
  const deleteChildSubscription = (id) => {
    setDeleteopenId(id);
    setDeleteopenModal(true);
  };
  function calculateComplexSubscriptionCost() {
    const duration = singleSubscription?.duration || 0;
    const price = Number(singleSubscription?.price || 0) * Number(duration);
    const discount = Number(singleSubscription?.discount || 0);
    const tax = Number(singleSubscription?.tax || 0);
    const discountedPrice = price - (price * discount) / 100;
    const taxAppliedPrice = discountedPrice + (discountedPrice * tax) / 100;
    // addSingleSubscription({ total_amount: taxAppliedPrice });
    return Math.abs(taxAppliedPrice).toFixed(2);
  }
  const updateParentDescription = () => {
    const { description, id } = singleSubscription;
    console.log('parentSubscription==', description, id);
    if (parentSubscription?.subs?.length > 0) {
      putParentSubscriptions({ description }, id, onSucess, onError);
      setShowSaveButton(false);
    } else {
      pushMessage('Atleast one plan should be added');
    }
  };

  const handleClickPopper = (event, comments) => {
    setPopper(event.currentTarget);
    setPopperText(comments);
  };

  const handleClosePopper = () => {
    setPopper(null);
    setPopperText('');
  };
  const openPopper = Boolean(popper);
  const idd = openPopper ? 'simple-popover' : undefined;
  const getParentSubscriptionValues = React.useCallback(() => {
    console.log('===parentSubscription?.subs', parentSubscription?.subs);
    return (
      <>
        {parentSubscription?.subs
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.duration}</TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>{row.tax}</TableCell>
              <TableCell>
                {' '}
                {row?.services?.length > 16 ? (
                  <>
                    {row?.services.slice(0, 15)}...
                    <Button
                      aria-describedby={idd}
                      className="ps-2 justify-content-start btn-tooltip"
                      onClick={(e) => handleClickPopper(e, row?.services)}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          width: '19px',
                          height: '19px',
                          color: '#864FFD',
                        }}
                      />
                    </Button>
                  </>
                ) : (
                  row?.services
                )}
              </TableCell>
              <TableCell>{row.discount}</TableCell>
              <TableCell>{Number(row.total_amount)?.toFixed(2)}</TableCell>
              <TableCell>
                <FormControlLabel
                  control={<IOSSwitch sx={{ m: 1 }} />}
                  checked={row?.is_active}
                  onChange={(r) => {
                    console.log('==r row', row, r.target.checked);
                    updateStatus({
                      ...row,
                      is_active: r.target.checked,
                    });
                  }}
                />
              </TableCell>
              <TableCell>
                <div className="d-flex justify-content-around">
                  {
                    <Button onClick={() => onEditChildScubscription(row)}>
                      {' '}
                      <EditIcon />{' '}
                    </Button>
                  }{' '}
                  {
                    <Button onClick={() => deleteChildSubscription(row.id)}>
                      {' '}
                      <DeleteIcon />
                    </Button>
                  }
                </div>
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  }, [parentSubscription?.subs, refresh]);
  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <div className="col-md-4 mb-2">
            <InputLabel
              className="text-start fs-14 text-black font-skmodernist"
              htmlFor="inputPlanName"
            >
              Plan Name
            </InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <OutlinedInput
                id="inputPlanName"
                name="name"
                type="text"
                value={parentSubscription?.subsDetails?.name}
                disabled={!!parentSubscription?.subsDetails?.name}
                placeholder="Plan Name"
                onChange={onChange}
                required
                autoFocus
                fullWidth
                margin="none"
              />
            </FormControl>
            <ErrorFormHelperText>{errors?.name}</ErrorFormHelperText>
          </div>

          <div className="col-md-8 mb-2">
            <InputLabel
              className="text-start fs-14 text-black font-skmodernist"
              htmlFor="inputPlanName"
            >
              Description
            </InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <TextareaAutosize
                id="inputAdditionalInfo"
                placeholder="Enter Plan description"
                name="description"
                defaultValue={parentSubscription?.subsDetails?.description}
                onChangeCapture={() => setShowSaveButton(true)}
                // disabled={!!parentSubscription?.subsDetails?.description}
                minRows={3} // You can adjust the number of rows as needed
                maxRows={2} // You can adjust the number of rows as needed
                onChange={onChange}
                // value={reason}
              />
            </FormControl>
            <ErrorFormHelperText>{errors?.description}</ErrorFormHelperText>
          </div>

          <div style={{ overflowX: 'auto' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ md: 2 }}
              flexWrap={'nowrap'}
              alignItems={'end'}
            >
              <Grid item xs={10} md={3}>
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputPlanName"
                >
                  Price(Per Month)
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="inputPlanName"
                    type="number"
                    name="price"
                    value={singleSubscription?.price || ''}
                    onChange={onChange}
                    placeholder="eg. ₹1000"
                    required
                    autoFocus
                    fullWidth
                    margin="none"
                  />
                </FormControl>
                <ErrorFormHelperText>{errors?.price}</ErrorFormHelperText>
              </Grid>
              <Grid item md={3} style={{ minWidth: '130px' }}>
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputPlanName"
                >
                  Plan Type
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <Select
                    defaultValue={returnPlanType(singleSubscription?.plan_type)}
                    value={returnPlanType(singleSubscription?.plan_type)}
                    placeholder={singleSubscription?.plan_type}
                    // value={'monthly'}
                    onChange={handlePlanNameChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {planTypes &&
                      planTypes.map((t) => (
                        <MenuItem value={t.plan_type}>{t.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <ErrorFormHelperText>{errors?.duration}</ErrorFormHelperText>
              </Grid>

              <Grid item xs={10} md={3}>
                <InputLabel
                  htmlFor="inputCIN"
                  className="text-start fs-14 text-black font-skmodernist"
                >
                  Tax Name
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="inputPlanName"
                    value={singleSubscription?.tax_name}
                    onChange={handleTaxChange}
                    placeholder="Tax Name"
                    required
                    autoFocus
                    fullWidth
                    margin="none"
                  />
                </FormControl>
                {/* <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <Select
                    value={singleSubscription?.tax_name}
                    onChange={handleTaxChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {taxDetails &&
                      taxDetails.map((t) => (
                        <MenuItem value={t.value}>{t.value}</MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
                <ErrorFormHelperText>{errors?.tax_name}</ErrorFormHelperText>
              </Grid>
              <Grid item md={3}>
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputPlanName"
                >
                  Tax (%)
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="inputPlanName"
                    type="number"
                    name="tax"
                    placeholder="eg. 20"
                    value={singleSubscription?.tax || ''}
                    onChange={onChange}
                    required
                    autoFocus
                    fullWidth
                    margin="none"
                  />
                </FormControl>
                <ErrorFormHelperText>{errors?.tax}</ErrorFormHelperText>
              </Grid>

              <Grid item xs={10} md={3} style={{ minWidth: '200px' }}>
                <InputLabel
                  htmlFor="inputCIN"
                  className="text-start fs-14 text-black font-skmodernist"
                >
                  Services
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    name="services"
                    options={subscription_services_list || []}
                    value={getExicitingLanguageArr()}
                    onChange={handleServiceChange}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.value}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.value}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          getLanguageArr()?.length > 0 ? '' : 'Services'
                        }
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <></>
                        // <Chip
                        //   key={index}
                        //   variant="outlined"
                        //   label={option.value}
                        //   {...getTagProps({ index })}
                        // />
                      ))
                    }
                  />
                </FormControl>
                <ErrorFormHelperText>{errors?.services}</ErrorFormHelperText>
              </Grid>

              <Grid item xs={10} md={3}>
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputPlanName"
                >
                  Plan Discount (%){' '}
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="inputPlanName"
                    type="number"
                    name="discount"
                    onChange={onChange}
                    value={singleSubscription?.discount || ''}
                    placeholder="eg. 18"
                    required
                    autoFocus
                    fullWidth
                    margin="none"
                  />
                </FormControl>
                <ErrorFormHelperText>{errors?.discount}</ErrorFormHelperText>
              </Grid>

              <Grid item md={3}>
                <InputLabel
                  className="text-start fs-14 text-black font-skmodernist"
                  htmlFor="inputPlanName"
                >
                  Total AMT (with Tax)
                </InputLabel>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="inputPlanName"
                    name="total_amount"
                    type="number"
                    value={calculateComplexSubscriptionCost()}
                    // onChange={(event) => {
                    //   console.log(
                    //     'OutlinedInput total_amount',
                    //     event.target.value
                    //   );
                    //   addSingleSubscription({
                    //     total_amount: event.target.value,
                    //   });
                    // }}
                    disabled={true}
                    placeholder="₹0"
                    required
                    autoFocus
                    fullWidth
                    margin="none"
                  />
                </FormControl>
                <ErrorFormHelperText> {errors?.totalPrice}</ErrorFormHelperText>
              </Grid>
              <Grid item md={'auto'}>
                <Button
                  type="button"
                  className="subscription-add-btn"
                  onClick={onSubmit}
                  sx={{ my: 1 }}
                >
                  <img
                    src={AddIcon}
                    alt="add-btn-icon"
                    className="add-btn-icon"
                  />
                </Button>
                <ErrorFormHelperText />
              </Grid>
            </Grid>
          </div>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Duration (in month)</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Tax (%)</TableCell>
                  <TableCell>Services</TableCell>
                  <TableCell>Plan Discount (%)</TableCell>
                  <TableCell>Total AMT(with Tax)</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell className="text-center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getParentSubscriptionValues()}</TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={deleteopenModal}
            onClose={() => setDeleteopenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={ModalStyle}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure want to delete it?
              </Typography>
              <div style={{ float: 'right' }}>
                <Button
                  onClick={async () => {
                    setDeleteopenModal(false);
                    await deleteSubscriptions(deleteopenId);
                    await getSingleSubscriptions(
                      parentSubscription?.subsDetails?.id
                    );
                  }}
                >
                  Yes
                </Button>
                <Button onClick={() => setDeleteopenModal(false)}>No</Button>
              </div>
            </Box>
          </Modal>

          <CustomPagination
            page={page}
            count={parentSubscription?.subs?.length || 0}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: 20,
          }}
        >
          {showSaveButton && (
            <Button
              variant="contained"
              className="subscription-update-btn"
              onClick={updateParentDescription}
            >
              Save
            </Button>
          )}
        </div>
      </Box>
      <Popover
        id={idd}
        open={openPopper}
        anchorEl={popper}
        onClose={handleClosePopper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              maxHeight: 60,
            }}
          >
            {popperText &&
              popperText.split(',').map((pt) => (
                <>
                  <Typography className="fs-16 fw-normal font-gotham text-black">
                    {pt}
                  </Typography>
                  <Divider className="personal-divider my-1"></Divider>
                </>
              ))}
          </Box>
        </Box>
      </Popover>
    </SideBarContent>
  );
}
const mapStateToProps = ({ subscription, tools, master }) => ({
  subscriptions: subscription.subscriptions,
  singleSubscription: subscription.singleSubscription,
  parentSubscription: subscription.parentSubscription,
  subscription_services: tools.get_subscription_services,
  misc_data: master.misc_data,
  taxDetails: master?.single_misc_data,
});
export default connect(mapStateToProps, {
  postSubscriptions,
  addSingleSubscription,
  addParentSubscription,
  pushMessage,
  clearSingleSubscriptionAfterEdit,
  putSingleSubscriptions,
  getSingleSubscriptions,
  deleteSubscriptions,
  fetchUploadFile,
  putParentSubscriptions,
  getMiscData,
  getSingleMiscData,
  fetchSubscriptionServices,
  setHeaderTitle,
  // clearSingleSubscription
})(CreateSubscriptionMaster);
