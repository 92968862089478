import React, { useEffect, useRef, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	OutlinedInput,
	Typography,
} from '@mui/material';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import StarterKit from '@tiptap/starter-kit';
import RichTextEditorComponent from '../../component/RichTextEditor/RichTextEditor';
import { connect } from 'react-redux';
import {
	addPageTemplate,
	editTemplateMaster,
	getTemplateTypeDetails,
	setClearTemplate,
	setTemplateData,
} from './redux/actions/template-action';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import { useHistory } from 'react-router';
import useValidation from '../../hooks/useValidation';
import { Editor } from '@monaco-editor/react';
import CustomDropdown from '../../component/CustomDropdown';

function TemplateForm({
	action_Type,
	templateMasterObj,
	setTemplateData,
	addPageTemplate,
	pushMessage,
	setClearTemplate,
	editTemplateMaster,
	all_template,
	getTemplateTypeDetails,
	set_template_type
}) {
	const history = useHistory();
	const rteRef = useRef(null);
	const { validateInput, errors } = useValidation();
	const [charCount, setCharCount] = useState(5000);
	const [contentHTML, setcontentHTML] = useState('');
	const [contentText, setcontentText] = useState('');
	const [alreadyExistTemplate, setAlreadyExistTemplate] = useState(false);
	const [isHTMLEditor, setIsHTMLEditor] = useState(false);

	const handlePageTitle = (e) => {
		let obj = { ...templateMasterObj };
		obj.page_title = e.target.value;
		obj.page_slug = e.target.value.toLowerCase().replaceAll(" ", "-");
		validateInput('pageTitle', obj.page_title);
		setTemplateData(obj);
	};

	const handleSubject = (e) => {
		let obj = { ...templateMasterObj };
		obj.page_slug = e.target.value;
		validateInput('subject', obj.page_slug)
		setTemplateData(obj);
	}

	const handleEditEditor = (value) => {
		let obj = { ...templateMasterObj };
		obj.page_content = value;
		validateInput('pageContent', rteRef.current?.editor?.getText());
		setTemplateData(obj);
	};

	const handleTemplateName = (e) => {
		let obj = { ...templateMasterObj };
		obj.page_title = e.target.value;
		validateInput('templateName', obj.page_title);
		setTemplateData(obj);
	}

	useEffect(() => {
		getTemplateTypeDetails();
	},[])


	const handleTemplateType = (e, value) => {
		let obj = { ...templateMasterObj }
		obj.type = value.value.toLowerCase();
		validateInput('selectType', value.value)
		console.log("obj.statsus @@@:", obj.type)
		setTemplateData(obj);
	}

	const getTemplateTypeObject = () => {
		let statusObj = null;
		if (!templateMasterObj || !set_template_type) {
			return null;
		}
		console.log("enquiryObjs : ", templateMasterObj?.type)
		const valuesArray = Object.values(set_template_type);
		statusObj = valuesArray.find(item => item?.value?.toLowerCase().includes(templateMasterObj?.type?.toLowerCase()))
		console.log("statusObj_data :", statusObj);
		if (statusObj) {
		  return statusObj;
		}
		else {
		  return null
		}
	
	}

	useEffect(() => {
		if (action_Type === 1) {
			for (let i = 0; i < all_template.length; i++) {
				if (
					all_template[i]?.page_title?.toLowerCase() ===
					templateMasterObj?.page_title?.toLowerCase()
				) {
					setAlreadyExistTemplate(true);
					return;
				} else {
					setAlreadyExistTemplate(false);
				}
			}
		}
	}, [all_template, templateMasterObj?.page_title]);

	const handleSaveTemplate = async () => {
		await validateInput('pageTitle', templateMasterObj?.page_title);
		await validateInput('templateName', templateMasterObj?.pageTitle);
		await validateInput('subject', templateMasterObj?.page_slug);
		await validateInput('selectType', templateMasterObj?.type);
		await validateInput('pageContent', rteRef.current?.editor?.getText());
		if (!templateMasterObj?.page_title || !rteRef.current?.editor?.getText() || !templateMasterObj?.page_slug || !templateMasterObj?.type) {
			return;
		}
		if (action_Type === 1) {
			const jsonData = JSON.stringify({
				type: templateMasterObj?.type.toLowerCase(),
				page_title: templateMasterObj?.page_title,
				page_slug: templateMasterObj?.page_slug,
				page_content: rteRef.current?.editor?.getHTML() ?? '',
				status: templateMasterObj?.status,
			});
			const customConfig = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			await addPageTemplate(
				jsonData,
				customConfig,
				() => {
					pushMessage('Created Successfully');
					setClearTemplate();
					history.push('/template-list');
				},
				(error) => {
					pushMessage(error);
				}
			);
		} else {
			const jsonData = JSON.stringify({
				type: templateMasterObj?.type.toLowerCase(),
				page_title: templateMasterObj?.page_title,
				page_slug: templateMasterObj?.page_slug,
				page_content: rteRef.current?.editor?.getHTML() ?? '',
				status: templateMasterObj?.status,
			});
			const customConfig = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			await editTemplateMaster(
				templateMasterObj?.id,
				jsonData,
				customConfig,
				() => {
					pushMessage('Updated Successfully');
					setClearTemplate();
					history.push('/template-list');
				},
				(error) => {
					pushMessage(error);
				}
			);
		}
	};

	// useEffect(() => {
	// 	validateInput('pageContent', templateMasterObj?.page_content);
	// }, [templateMasterObj?.page_content]);

	const handleCancelTemplate = () => {
		setClearTemplate();
		history.push('/template-list');
	};

	const handleEditorChange = (value) => {
		setcontentHTML(value);
		handleEditEditor(value);
	};

	return (
		<SideBarContent>
			<Box className="custom-card-bg">
				<Box className="mb-4">
					<Grid container rowSpacing={1} columnSpacing={10} className="mb-3">
						<Grid item xs={10} md={6}>
							<div className="col-md-12 mb-3">
								<InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputProductCategory">Template Type</InputLabel>
								<FormControl fullWidth sx={{ my: 1 }}>
									<CustomDropdown
										id="templateInput"
										// disabled={userSelect === "view"}
										value={getTemplateTypeObject()}
										options={set_template_type}
										inputPlaceholder="Select type"
										onChange={handleTemplateType}
										labelKey="value"
										optionKey="id"
										inputProps={{ disabled: true }}
									/>
								</FormControl>
								<FormHelperText sx={{ color: 'red' }} id="pagetitle-helper-text">
								{errors.selectType}
							</FormHelperText>
							</div>
							{ templateMasterObj?.type == "page" && <div>
							<InputLabel
								className="text-start fs-14 text-black font-skmodernist"
								htmlFor="inputName"
							>
								Page Title
							</InputLabel>
							<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
								<OutlinedInput
									id="inputName"
									type="text"
									placeholder="Page Title"
									required
									value={templateMasterObj?.page_title}
									fullWidth
									margin="none"
									onChange={handlePageTitle}
								/>
							</FormControl>
							<FormHelperText sx={{ color: 'red' }} id="pagetitle-helper-text">
								{errors.pageTitle}
							</FormHelperText>
							{alreadyExistTemplate && (
								<div style={{ color: 'red' }}>{'Template Already Exist'}</div>
							)}
							<InputLabel
								className="text-start fs-14 text-black font-skmodernist"
								htmlFor="inputName"
							>
								Page Slug
							</InputLabel>
							<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
								<OutlinedInput
									id="inputName"
									type="text"
									placeholder="Page Slug"
									required
									value={templateMasterObj?.page_slug}
									fullWidth
									margin="none"
									disabled={true}
									// onChange={handlePageSlug}
								/>
							</FormControl>
							</div>}
							{templateMasterObj?.type == "email" && <div>
							<InputLabel
								className="text-start fs-14 text-black font-skmodernist"
								htmlFor="inputName"
							>
								Template Name
							</InputLabel>
							<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
								<OutlinedInput
									id="inputName"
									type="text"
									placeholder="Template Name"
									required
									value={templateMasterObj?.page_title}
									fullWidth
									margin="none"
									onChange={handleTemplateName}
								/>
							</FormControl>
							<FormHelperText sx={{ color: 'red' }} id="pagetitle-helper-text">
								{errors.templateName}
							</FormHelperText>
							{alreadyExistTemplate && (
								<div style={{ color: 'red' }}>{'Template Already Exist'}</div>
							)}
							<InputLabel
								className="text-start fs-14 text-black font-skmodernist"
								htmlFor="inputName"
							>
								Subject
							</InputLabel>
							<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
								<OutlinedInput
									id="inputName"
									type="text"
									placeholder="Subject"
									required
									value={templateMasterObj?.page_slug}
									fullWidth
									margin="none"
									onChange={handleSubject}
								/>
							</FormControl>
							<FormHelperText sx={{ color: 'red' }} id="pagetitle-helper-text">
								{errors.subject}
							</FormHelperText>
							</div>}
						</Grid>
					</Grid>



					<Box className="mb-3">
						<InputLabel
							className="text-start fs-14 text-black font-skmodernist mb-1"
							htmlFor="inputName"
						>
							Page Content
						</InputLabel>
						<Box className="d-flex justify-content-between align-items-end">
							<Button
								onClick={() => {
									setIsHTMLEditor(!isHTMLEditor);
								}}

								className="switch-editor-btn"
								sx={{
									background: '#864ffd',
									color: '#fff',
									textTransform: 'capitalize',
									fontSize: '16px'
								}}
							>
								Switch Editor
							</Button>
							<Typography
								variant="body1"
								align="right"
								className="fs-12 text-grey fw-normal mb-0 font-gotham"
								gutterBottom
							>
								{charCount} Characters
							</Typography>
						</Box>

						{!isHTMLEditor && (
							<RichTextEditorComponent
								ref={rteRef}
								extensions={[StarterKit]}
								content={templateMasterObj?.page_content}
								onChange={handleEditEditor}
								setCharCount={setCharCount}
								setcontentHTML={setcontentHTML}
								setcontentText={setcontentText}
								contentText={contentText}
							/>
						)}
						{isHTMLEditor && (
							<Editor
								height="50vh"
								theme="light"
								value={templateMasterObj?.page_content}
								defaultLanguage="html"
								defaultValue={contentHTML}
								onChange={handleEditorChange}
								options={{ minimap: { enabled: false } }}
								className='html-editor'
							/>
						)}
					</Box>
					<FormHelperText sx={{ color: 'red' }} id="pageContent-helper-text">
						{errors.pageContent}
					</FormHelperText>
					{/* value={templateMasterObj?.page_content} */}

					<Grid container rowSpacing={1} columnSpacing={10}>
						<Grid
							item
							xs={10}
							md={6}
							className="col-4 custom-control custom-checkbox"
						>
							<FormControlLabel
								control={
									<Checkbox
										checked={templateMasterObj?.status}
										onChange={(e) => {
											let obj = { ...templateMasterObj };
											obj.status = e.target.checked;
											setTemplateData(obj);
										}}
										color="primary"
									/>
								}
								label="Active"
							/>
						</Grid>
					</Grid>

					<Box className="d-flex justify-content-end">
						<Button className="btn-cancel me-3" onClick={handleCancelTemplate}>
							Cancel
						</Button>
						<Button
							className="btn-save"
							// disabled={errors.pageTitle || !rteRef.current?.editor?.getText()}
							onClick={handleSaveTemplate}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</SideBarContent>
	);
}

const mapStateToProps = (state) => {
	const { action_Type, templateMasterObj, all_template, set_template_type } = state.template;
	return {
		action_Type,
		templateMasterObj,
		all_template,
		set_template_type
	};
};

export default connect(mapStateToProps, {
	setTemplateData,
	addPageTemplate,
	pushMessage,
	setClearTemplate,
	editTemplateMaster,
	getTemplateTypeDetails,
})(TemplateForm);
