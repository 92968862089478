import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Link, Pagination, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
import { useHistory } from 'react-router-dom';
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import { connect } from "react-redux";
import { fetchRole, setActionType, getName, getPermission, setAllRoleFilter, getAssignModule, setRowId } from "./redux/actions/role-action";
import { setHeaderTitle } from '../../store/actions/rootActions';



function RoleMaster({ all_role, fetchRole, all_module, getAssignModule, setActionType, getName, getPermission, setAllRoleFilter, all_role_filter, setRowId, setHeaderTitle }) {

    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
    const [currentPage, setCurrentPage] = useState(0);


    useEffect(() => {
        
        if (searchText === "") {
            setAllRoleFilter(all_role);
            setPage(currentPage);
        } else {
            setPage(0);
            const filteredItems = all_role.filter((item) =>
                item.name.toLowerCase().includes(searchText.toLowerCase().trim()));

            setAllRoleFilter(filteredItems);
        }
    }, [all_role, searchText])

    const handleSearch = (e) => {
        const searchTerm = e.target.value.replace(/\s\s+/g, ' ')
        setSearchText(searchTerm, "searchValue");
    };



    const handleChangePage = (newPage) => {
        setPage(newPage);
        setCurrentPage((prevPage) => {
            if(prevPage === 0) {
                return newPage
            } else {
                return prevPage
            }
        });
    };


    const history = useHistory();

    useEffect(() => {
        getAssignModule([]);
    }, []);

    const handleCreateButtonClick = () => {
        getName('')
        getPermission([])
        setActionType(1);
        setHeaderTitle('Create Role');
        history.push('/create-role'); // Navigate to Createrole page
    };

    const handleEdit = async (row) => {
        console.log('row', row);
        getName(row.name)
        setRowId(row.id)
        if (row?.permissions) {
            const permission = row?.permissions?.split(',')?.map(Number);

            if (permission?.length > 0) {
                let existingPermissionsArray = []

                for (const permmodule of all_module) {
                    const permissionItem = permission?.findIndex(itm => permmodule?.id === itm);
                    if (permissionItem !== -1) {
                        existingPermissionsArray.push({ ...permmodule, is_active: true });
                    } else {
                        // existingPermissionsArray.push({...permmodule, is_active: false});
                    }
                }
                getPermission(existingPermissionsArray)
                await getAssignModule(existingPermissionsArray);
            } else {
                getPermission([])
                await getAssignModule([]);
            }
        } else {
            getPermission([])
            await getAssignModule([]);
        }

        setActionType(0);
        setHeaderTitle('Edit Role');
        history.push('/create-role');
    }

    useEffect(() => {
        console.log("hello")
        fetchRole();

    }, [])



    return (
        <SideBarContent>
            <Box className="custom-card-bg">
                <Box className="mb-4">
                    <Grid container spacing={2} className="align-items-center just-content-between">
                        <Grid item xs={10} md={6} >
                            <TextField
                                sx={{ height: "50px", width: '400px', borderRadius: '6px' }}
                                variant="outlined"
                                placeholder="Search here..."
                                value={searchText}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <Search
                                            sx={{ width: "1em", height: "1em", marginRight: "8px", color: '#864FFD' }}
                                            color="action"
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={"auto"} className="ms-auto">
                            <Link to="./CreateRole" >
                                <Button className="btn-create" onClick={handleCreateButtonClick}>
                                    Create
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>

                <Paper sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: "450px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Role Name</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {all_role_filter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button onClick={() => {
                                                handleEdit(row)
                                            }}><EditIcon /></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <CustomPagination page={page} count={all_role_filter.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
                </Paper>
            </Box>
        </SideBarContent>
    );
}


const mapStateToProps = (state) => {
    const { all_role, all_module, all_role_filter } = state.role;
    return {
        all_role, all_module, all_role_filter
    }
}

export default connect(mapStateToProps, { fetchRole, setActionType, getPermission, getName, setAllRoleFilter, getAssignModule, setRowId, setHeaderTitle })(RoleMaster);
