import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';

const CustomModal = ({ open, onClose }) => {
  const [planName, setPlanName] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [planFeatures, setPlanFeatures] = useState('');
  const [subscriptionPrice, setSubscriptionPrice] = useState('');
  const [isActive, setIsActive] = useState(true);

  const handleSave = () => {
    // Handle save logic here
    onClose();
  };

  const handleCancel = () => {
    onClose(true); // Close the modal
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="custom-border-header">Create Plan</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-md-6 mb-3">
            <InputLabel htmlFor="inputPlanName">Plan Name</InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <OutlinedInput
                id="inputPlanName"
                type="text"
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
                placeholder="Enter plan name"
                required
                autoFocus
                fullWidth
                margin='none'
              />
            </FormControl>
          </div>
          <div className="col-md-6 mb-3">  
            <InputLabel htmlFor="inputProductCategory">Product Category</InputLabel>
            <FormControl fullWidth sx={{ my: 1 }}>
              <Select
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Select 1">Select 1</MenuItem>
                <MenuItem value="Select 2">Select 2</MenuItem>
                <MenuItem value="Select 3">Select 3</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <InputLabel htmlFor="inputPlanFeatures">Plan Features</InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <OutlinedInput
                id="inputPlanFeatures"
                type="text"
                value={planFeatures}
                onChange={(e) => setPlanFeatures(e.target.value)}
                placeholder="Enter plan features"
                required
                autoFocus
                fullWidth
                margin='none'
              />
            </FormControl>
          </div>
          <div className="col-md-6 mb-3">
            <InputLabel htmlFor="inputSubscriptionPrice">Subscription Price</InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <OutlinedInput
                id="inputSubscriptionPrice"
                type="text"
                value={subscriptionPrice}
                onChange={(e) => setSubscriptionPrice(e.target.value)}
                placeholder="Enter subscription price"
                required
                autoFocus
                fullWidth
                margin='none'
              />
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col-4 custom-control custom-checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  color="primary"
                />
              }
              label="Active"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className="btn-cancel me-2">
          Cancel
        </Button>
        <Button onClick={handleSave} className="btn-save">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
