import {
  CLEAR_FILE_DATA_OBJ,
  GET_FILE_DATA,
  GET_SUBSCRIPTION_SERVICES,
  SET_UPLOAD_FILE,
  UPLOAD_FILE_TO_SERVER,
} from '../types';

const initial_state = {
  upload_file: [],
  get_file_rowData: [],
  get_subscription_services: [],
};

const elynkerToolsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_UPLOAD_FILE:
      return {
        ...state,
        upload_file: action.payload,
      };
    // case UPLOAD_FILE_TO_SERVER:
    //     return {
    //         ...state,

    //     }
    case GET_FILE_DATA:
      return {
        ...state,
        get_file_rowData: action.payload,
      };

    case GET_SUBSCRIPTION_SERVICES:
      return {
        ...state,
        get_subscription_services: action.payload,
      };

    case CLEAR_FILE_DATA_OBJ:
      return {
        ...state,
        upload_file: [],
      };
    default:
      return state;
  }
};

export default elynkerToolsReducer;
