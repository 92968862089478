import { AdminToolsAPI } from '../../../../apis/AdminToolsAPi';
import { SET_LOADING } from '../../../../store/types';
import {
  CLEAR_FILE_DATA_OBJ,
  GET_FILE_DATA,
  GET_SUBSCRIPTION_SERVICES,
  SET_UPLOAD_FILE,
} from '../types';

export const setUploadFileData = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_UPLOAD_FILE, payload: filter });
  };
};

export const clearFileObject = () => {
  return (dispatch) => {
    console.log('clear file data');
    dispatch({ type: CLEAR_FILE_DATA_OBJ });
  };
};

export const setStatusData = (data) => {
  return (dispatch) => {
    dispatch({ type: GET_FILE_DATA, payload: data });
  };
};

export const fetchUploadFile = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await AdminToolsAPI.getAdminTools();
      console.log('Response_data_upload ####### :', response.data);
      if (response.status === 200) {
        // const sortedArray = response?.data?.data?.sort((a,b)=> moment(b.updatedAt) - moment(a.updatedAt))
        dispatch({ type: GET_FILE_DATA, payload: response.data?.data });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      console.log('error', error);
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
};

export const fetchSubscriptionServices = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await AdminToolsAPI.getSubscriptionServices();
      console.log('Response_data_upload ####### :', response.data);
      if (response.status === 200) {
        // const sortedArray = response?.data?.data?.sort((a,b)=> moment(b.updatedAt) - moment(a.updatedAt))
        dispatch({
          type: GET_SUBSCRIPTION_SERVICES,
          payload: response.data?.data,
        });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      console.log('error', error);
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
};

export const addUploadFileToServer = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await AdminToolsAPI.postAdminTools(data);
      if (response.status === 200) {
        successCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        errorCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
      dispatch({ type: SET_LOADING, payload: false });
    }
  };
};

export const editAdminToolsData = (
  id,
  data,
  header,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await AdminToolsAPI.updateAdminTools(id, data, header);
      if (response.status === 200) {
        successCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        errorCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
      // errorCallback(error?.response?.data.error);
    }
  };
};
