import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Pagination,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { ReactComponent as ProductImage1 } from '../../assets/product1.svg';
import B2BRejectModal from './B2BRejectModal';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	getVendorDetails,
	updateVendorStatus,
} from './redux/actions/b2b-action';
import { ReactComponent as InfoBtn } from '../../assets/info-btn.svg';
import Popover from '@mui/material/Popover';
import { useHistory } from 'react-router-dom';
import { PRODUCT_IMAGE_BASE_URL } from '../../apis/endpoints';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

export function extractDocNameAndNumber(documents) {
	if (!documents || !Array.isArray(documents)) {
		console.error('Invalid input: documents must be an array');
		return [];
	}

	const result = [];

	documents.forEach((doc) => {
		const { document_name, document_number } = doc;
		result.push({ document_name, document_number });
	});

	return result;
}
export function getCategoryFromTitles(arrayOfObjects) {
	const titles = arrayOfObjects.map((item) => item.title);
	const category = titles.join(', ');
	return category;
}

function B2BProfileView({
	b2bUser,
	updateVendorStatus,
	regId,
	status,
	rejected_reason,
	pushMessage,
	getVendorDetails,
}) {
	//const navigate=useNavigate();

	const [searchText, setSearchText] = useState('');
	const [page, setPage] = useState(0);
	const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
	const location = useLocation();
	const [reason, setReason] = React.useState('');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
	const [popperText, setPopperText] = useState('');
	const [popper, setPopper] = React.useState(null);

	const { id: userId } = useParams();

	// const currentB2bUser = location.state.currentB2bUser;
	// console.log("=====B2BProfileView props", currentB2bUser);
	//name,user.mobile_number,user.mobile,company_name,about_company,products[0].title,business_details-contain array

	useEffect(() => {
		// console.log('[FreelancerProfileViewReason] userId', userId);
		if (userId) getVendorDetails(userId);
	}, [userId]);

	const {
		name,
		user,
		company_name,
		about_company,
		business_details,
		registration_type,
		products,
		categories,
	} = b2bUser;
	console.log('===categories567', b2bUser, categories);
	let mobile_number, email, categoriList;
	if (user) {
		const { mobile_number: userMobileNumber, email: userEmail } = user;
		mobile_number = userMobileNumber;
		email = userEmail;
	} else {
		mobile_number = '';
		email = '';
	}
	if (categories) {
		categoriList = getCategoryFromTitles(categories);
	} else {
		categoriList = '';
	}

	const productType = registration_type === 3 ? 'Service' : 'Product';
	console.log('===B2BProfileView products', b2bUser, products);
	const businessDetails = extractDocNameAndNumber(business_details);
	const handleChangePage = (newPage) => {
		setPage(newPage);
	};
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};
	function updateVendorStatusDetails(status) {
		//approved or rejected
		//rejected_reason
		updateVendorStatus(regId, { status }, () => {
			pushMessage('Status Updated Successfully');
		});
	}
	function handleRejectSave() {
		console.log('====handleRejectSave', reason);
		updateVendorStatus(
			regId,
			{ status: 'rejected', rejected_reason: reason },
			() => {
				pushMessage('Rejected Successfully');
				history.goBack();
			}
		);
		setOpenModal(false);
	}
	const handleInfIconClick = (event) => {
		// setAnchorEl(rejected_reason);
		setAnchorEl(event.currentTarget);
	};

	const handleInfIconClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	function toCamelCase(str) {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
				return index === 0 ? word.toUpperCase() : word.toLowerCase();
			})
			.replace(/\s+/g, '');
	}

	function truncateText(text = '', length = 50) {
		if (text?.length <= length) {
			return text || '';
		} else if (text?.length == undefined) {
			return text;
		}
		return text?.substr(0, length) + '\u2026' || '';
	}

	const handleClickPopper = (event, about_company) => {
		setPopper(event.currentTarget);
		setPopperText(about_company);
	};
	const handleClosePopper = () => {
		setPopper(null);
		setPopperText('');
	};

	const openPopper = Boolean(popper);
	const idd = openPopper ? 'simple-popover' : undefined;

	//default_image
	const ac = truncateText(about_company);
	console.log(typeof ac);
	return (
		<SideBarContent>
			<Box className="custom-card-bg">
				<Box className="mb-4">
					<Box className="d-flex justify-content-between mb-4 align-items-center">
						{status && (
							<div className="ribbon mb-5">
								{/* <a href="#">{toCamelCase(status)}</a>
								 */}
								<a className={'' + status} href="#">
									{toCamelCase(status)}
								</a>
							</div>
						)}
						{status === 'pending' && (
							<Box className="d-flex justify-content-end mb-4">
								<Button
									className="btn-approve me-3"
									onClick={() => {
										updateVendorStatusDetails('approved');
										history.goBack();
									}}
								>
									Approve
								</Button>
								{/* <Button className="btn-hold me-3">Hold</Button> */}
								<Button className="btn-reject" onClick={handleOpenModal}>
									Reject
								</Button>
								<B2BRejectModal
									handleSave={handleRejectSave}
									open={openModal}
									reason={reason}
									setReason={setReason}
									onClose={handleCloseModal}
								/>
							</Box>
						)}
						{status === 'rejected' && (
							<Box className="d-flex justify-content-end mb-4">
								<Button
									className="btn-approve me-3"
									onClick={() => {
										updateVendorStatusDetails('approved');
										history.goBack();
									}}
								>
									Approve
								</Button>

								<Button
									className="btn-info"
									aria-describedby={id}
									variant="contained"
									onClick={handleInfIconClick}
								>
									<InfoBtn />
								</Button>
							</Box>
						)}
						{status === 'approved' && (
							<Box className="d-flex justify-content-end mb-4">
								<Button className="btn-reject" onClick={handleOpenModal}>
									Reject
								</Button>
								<B2BRejectModal
									handleSave={handleRejectSave}
									open={openModal}
									reason={reason}
									setReason={setReason}
									onClose={handleCloseModal}
								/>
							</Box>
						)}
					</Box>

					<Box className="mb-3">
						<Typography className="fs-20 fw-bold text-black font-skmodernist">
							Personal Details
						</Typography>
						<Divider className="personal-divider my-3"></Divider>
						<Grid container columnSpacing={4} rowSpacing={4}>
							<Grid
								item
								md={4}
								className="d-flex justify-content-between align-items-end mb-2"
							>
								<InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
									Name
								</InputLabel>
								<Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
									{name}
								</Typography>
							</Grid>
							<Grid
								item
								md={4}
								className="d-flex justify-content-between align-items-end mb-2"
							>
								<InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
									Mobile No.
								</InputLabel>
								<Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
									{mobile_number}
								</Typography>
							</Grid>
							<Grid
								item
								md={4}
								className="d-flex justify-content-between align-items-end mb-2"
							>
								<InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
									Email
								</InputLabel>
								<Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
									{email}
								</Typography>
							</Grid>
						</Grid>

						<Divider className="main-divider my-3"></Divider>
						<Grid
							container
							columnSpacing={4}
							rowSpacing={4}
							className="align-items-start"
						>
							<Grid
								item
								md={4}
								className="d-flex justify-content-between align-items-end mb-2"
							>
								<InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
									Company Name
								</InputLabel>
								<Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
									{company_name}
								</Typography>
							</Grid>
							<Grid
								item
								md={4}
								className="d-flex justify-content-between align-items-start mb-2"
							>
								<InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
									About Company
								</InputLabel>
								<Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
									{/* {about_company} */}
									<span style={{ width: '175px', display: 'inline-block' }}>
										{truncateText(about_company)}{' '}
									</span>
									{about_company?.length > 50 ? (
										<Button
											aria-describedby={idd}
											className="ps-2 justify-content-start btn-tooltip"
											onClick={(e) => handleClickPopper(e, about_company)}
										>
											<InfoOutlinedIcon
												sx={{
													width: '19px',
													height: '19px',
													color: '#864FFD',
												}}
											/>
										</Button>
									) : (
										<></>
									)}
								</Typography>
							</Grid>
							<Grid
								item
								md={4}
								className="d-flex justify-content-between align-items-end mb-2"
							>
								<InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
									Product Category
								</InputLabel>
								<Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
									{categoriList}
								</Typography>
							</Grid>
						</Grid>

						<Divider className="main-divider my-3"></Divider>
						<Grid container columnSpacing={4} rowSpacing={4}>
							{businessDetails &&
								businessDetails.map((r) => (
									<Grid
										item
										md={4}
										className="d-flex justify-content-between align-items-end mb-2"
									>
										<InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
											{r.document_name}
										</InputLabel>
										<Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
											{r.document_number}
										</Typography>
									</Grid>
								))}
							{/* <Grid item md={4} className="d-flex justify-content-between">
                                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">PAN</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">M52200002</Typography>
                            </Grid> */}
						</Grid>
					</Box>
					<Grid
						container
						rowSpacing={1.5}
						columnSpacing={{ xs: 1, sm: 2, md: 2 }}
						className="align-items-start justify-content-between mb-4"
					>
						<Grid item xs={10} md={5} className="" rowSpacing={4}></Grid>

						<Grid item xs={10} md={5} className="" rowSpacing={4}></Grid>
					</Grid>

					<Typography className="fs-20 fw-bold text-black font-skmodernist">
						{productType} Details
					</Typography>
					<Divider className="main-divider my-3"></Divider>

					<Paper sx={{ width: '100%' }}>
						{products && products?.length > 0 && (
							<TableContainer sx={{ maxHeight: '450px' }}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											<TableCell>{productType} Name</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{products.map((row) => (
											<TableRow>
												<TableCell>
													<div className="d-flex justify-content-start align-items-center">
														<div className="w-50 d-flex">
															<div className="me-3">
																<img
																	src={`${PRODUCT_IMAGE_BASE_URL}${row.default_image}`}
																	alt="product-image"
																	width="80"
																	height="80"
																	style={{
																		objectFit: 'cover',
																		objectPosition: 'center',
																	}}
																	className="product-image"
																/>
															</div>
															<div className="">
																<Typography
																	variant="h5"
																	className="fs-16 text-black mb-2 fw-normal font-skmodernist"
																	component="div"
																>
																	{row.title}
																</Typography>
																<Typography
																	variant="body2"
																	className="fs-14 text-grey mb-2 font-gotham"
																>
																	{/* {row.description}. */}
																	<span
																		style={{
																			width: '175px',
																			display: 'inline-block',
																		}}
																	>
																		{truncateText(row.description)}{' '}
																	</span>
																	{row?.description?.length > 50 ? (
																		<Button
																			aria-describedby={idd}
																			className="ps-2 justify-content-start btn-tooltip"
																			onClick={(e) =>
																				handleClickPopper(e, row.description)
																			}
																		>
																			<InfoOutlinedIcon
																				sx={{
																					width: '19px',
																					height: '19px',
																					color: '#864FFD',
																				}}
																			/>
																		</Button>
																	) : (
																		<></>
																	)}
																</Typography>
															</div>
														</div>

														<div className="w-50">
															<Typography
																variant="h5"
																className="fs-13 text-darkgrey mb-0 font-skmodernist"
															>
																Starting at
															</Typography>

															<Typography
																variant="h5"
																className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
															>
																₹ {row.budget}
																{row.unit ? '/' : ''}
																<span className="fs-13 text-grey fw-light">
																	{row.unit}
																</span>
															</Typography>
														</div>
													</div>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
						{/* <CustomPagination page={page} count={data.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} /> */}
					</Paper>
				</Box>
			</Box>
			<Popover
				id={idd}
				open={openPopper}
				anchorEl={popper}
				onClose={handleClosePopper}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Box sx={{ p: 2, maxWidth: '300px' }}>
					<Typography
						className="fs-12 fw-normal font-gotham text-black"
						sx={{ wordBreak: 'break-all' }}
					>
						{popperText}
					</Typography>
				</Box>
			</Popover>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleInfIconClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Typography sx={{ p: 2 }}>
					{rejected_reason ? rejected_reason : 'No reason given'}.
				</Typography>
			</Popover>
		</SideBarContent>
	);
}
const mapStateToProps = ({ b2bUser, signin }) => {
	console.log('====mapStateToProps b2bUser 567', b2bUser.b2bUser);
	// const userId = b2bUser.b2bUser.user.id
	// const regId = signin.userData.registration.id

	return {
		b2bUser: b2bUser.b2bUser,
		b2bloading: b2bUser.loading,
		regId: b2bUser.b2bUser.id,
		status: b2bUser.b2bUser.status,
		rejected_reason: b2bUser.b2bUser.rejected_reason,
	};
};

export default connect(mapStateToProps, {
	updateVendorStatus,
	pushMessage,
	getVendorDetails,
})(B2BProfileView);

