import { GET_HOMEPAGE_WIDGET } from '../types';

const initial_state = {
  all_widget: [],
};

const homepageWidgetReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_HOMEPAGE_WIDGET:
      return {
        ...state,
        all_widget: action.payload,
      };
    default:
      return state;
  }
};

export default homepageWidgetReducer;
