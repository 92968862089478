import { UserMasterAPI } from "../../../../apis/UserMasterAPI";
import { SET_LOADING } from "../../../../store/types";
import { SAVE_SIGNIN_DATA } from "../../../Otp/redux/types";
import { SET_PROFILE_ADMIN } from "../types";

export const editProfileUserMaster = (id, data, header, olduserData, successCallback = () => { }, errorCallback = () => { }) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADING, payload: true })
        try {
            const response = await UserMasterAPI.updateUserMaster(id, data, header);
            if (response.status === 200) {
                let newUsrData = { ...olduserData };
                newUsrData.user = response.data?.user;
                await dispatch({ type: SAVE_SIGNIN_DATA, payload: newUsrData });
                successCallback(response.data)
                dispatch({ type: SET_LOADING, payload: false })
            } else {

                errorCallback(response.data)
                dispatch({ type: SET_LOADING, payload: false })
            }
        } catch (error) {
            dispatch({ type: SET_LOADING, payload: false });
            if (error.response.status === 401) {
                errorCallback(error?.response?.data?.message);
            }
            else {
                errorCallback(error?.response?.data?.error);
            }
            // errorCallback(error?.response?.data.error);

        }
    }
}


// export const fetchUser = (id) => {
//     return async(dispatch) => { 
//         dispatch({type: SET_LOADING,payload:true})
//         try {
//             const response = await UserMasterAPI.getUserMasterById(id);
//             if (response.status === 200){
//                 // const sortedArray = response?.data?.data?.sort((a,b)=> moment(b.updatedAt) - moment(a.updatedAt))
//                dispatch({type: GET_USER_DATA, payload: sortedArray})
//                dispatch({type: SET_LOADING, payload: false});
//             }

//         } catch (error) {
//             console.log("error", error);
//         }  
//     }

// }


export const setProfileMasterData = (insertData) => {
    return async (dispatch) => {
        dispatch({ type: SET_PROFILE_ADMIN, payload: insertData })
    }
}