//For get social media master
export const GET_SOCIAL_MEDIA_REQUEST = 'GET_SOCIAL_MEDIA_REQUEST';
export const GET_SOCIAL_MEDIA_SUCCESS = 'GET_SOCIAL_MEDIA_SUCCESS';
export const GET_SOCIAL_MEDIA_FAILURE = 'GET_SOCIAL_MEDIA_FAILURE';

//For post social media master
export const POST_SOCIAL_MEDIA_REQUEST = 'POST_SOCIAL_MEDIA_REQUEST';
export const POST_SOCIAL_MEDIA_SUCCESS = 'POST_SOCIAL_MEDIA_SUCCESS';
export const POST_SOCIAL_MEDIA_FAILURE = 'POST_SOCIAL_MEDIA_FAILURE';


//For update social media master
export const PUT_SOCIAL_MEDIA_REQUEST = 'PUT_SOCIAL_MEDIA_REQUEST';
export const PUT_SOCIAL_MEDIA_SUCCESS = 'PUT_SOCIAL_MEDIA_SUCCESS';
export const PUT_SOCIAL_MEDIA_FAILURE = 'PUT_SOCIAL_MEDIA_FAILURE';

//For delete social media master
export const DEL_SOCIAL_MEDIA_REQUEST = 'PUT_SOCIAL_MEDIA_REQUEST';
export const DEL_SOCIAL_MEDIA_SUCCESS = 'PUT_SOCIAL_MEDIA_SUCCESS';
export const DEL_SOCIAL_MEDIA_FAILURE = 'PUT_SOCIAL_MEDIA_FAILURE';


export const SET_SOCIAL_ACTION_TYPE = "SET_SOCIAL_ACTION_TYPE";
export const SET_SOCIAL_MEDIA_OBJECT = 'SET_SOCIAL_MEDIA_OBJECT';

export const CLEAR_SOCIAL_MEDIA_OBJ = 'CLEAR_SOCIAL_MEDIA_OBJ';


export const SET_SOCIAL_LINKS_FILTER = 'SET_SOCIAL_LINKS_FILTER';

export const RESET_SOCIAL_LINK = 'RESET_SOCIAL_LINK';