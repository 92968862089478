import React, { useEffect, useState } from "react";
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import AddIcon from "../../assets/add-icon-white.svg";
import { connect } from "react-redux";
import {
  addToList,
  addInput,
  updateCheckbox,
  setValuesArray,
  createMiscdata,
  setMastersName,
  setMastersValue,
  resetMiscData,
  postMiscData,
  putMiscdata,
  getMiscData,
  toggleStatus
} from "./redux/actions/masters-action";
import { MISC_MODULE_TITLE } from "./redux/types";
import { useHistory } from "react-router-dom";
import { pushMessage } from "../../component/MessageBox/redux/actions/message-box-action";
import useValidation from "../../hooks/useValidation";
import { getModuleDataByAdminId } from "./redux/actions/masters-action";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

// import { createMiscdata } from "./redux/actions/masters-action";

function CreateMasters({
    addToList,
    addInput,
    updateCheckbox,
    setValuesArray,
    createMiscdata,
    setMastersName,
    setMastersValue,
    resetMiscData,
    getModuleDataByAdminId,
    postMiscData,
    putMiscdata,
    valuesArray,
    userInput,
    create_misc_data,
    misc_data,
    getMiscData,
    toggleStatus,
    pushMessage
}) {
  let { state } = useLocation();
  const { validateInput, errors } = useValidation();
  const history = useHistory();
  const [checkedItems, setCheckedItems] = useState({});
  const [error, setError] = useState(false);
  const [moduleValuesError, setModuleValuesError] = useState(false);
  useEffect(() => {
    if (state?.rid) {
      getModuleDataByAdminId(state?.rid);
    }
  }, [state]);

  const handleChange = (e) => {
    addInput(e.target.value);
  };

  useEffect(() => {
    const foundObject = create_misc_data.module.find(
      (obj) => obj.value === userInput
    );
    if (foundObject) {
      setModuleValuesError(true);
    } else {
      setModuleValuesError(false);
    }
  }, [userInput]);

  const handleAddToMaster = () => {
    if (!userInput) {
      return;
    }
    if (errors.master_name) {
      return;
    }
    if (moduleValuesError) {
      return;
    }
    let moduleArray = [...create_misc_data.module]
    let obj = {
      checkid: create_misc_data.module.length + 1,
      value: userInput,
      status: true,
    };
    if (state?.rid) {
      obj.admin_module_id = state?.rid;
    }
    moduleArray.push(obj)
    let newMiscData = { ...create_misc_data };
    newMiscData.module = moduleArray;
    console.log("newMiscData is",newMiscData)
    postMiscData(newMiscData);
    validateInput("values_array", newMiscData?.module);
    addInput("");
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };


  const [selectAll, setSelectAll] = useState(false);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    console.log("values_array is", errors)
  },[errors])

  useEffect(() => {
    setIsActive(create_misc_data?.admin?.is_active)
    console.log("toggle is_active",isActive)
  }, [])

  const handleClick = () => { 
      validateInput("master_name", create_misc_data?.admin?.name);
      validateInput("values_array", create_misc_data?.module);

    if (create_misc_data?.admin?.name === "") {
      return;
    }
    if (create_misc_data?.module?.length === 0) {  
      return;
    }
    if (state?.rid) {
      putMiscdata(create_misc_data, () => {
        resetMiscData();
        getMiscData();
        pushMessage("Updated Successfully")
        history.push("/masters");
      });
    } else {
      console.log("inside else")
      createMiscdata(create_misc_data,() => {
        resetMiscData();
        getMiscData();
        pushMessage("Created Successfully")
        history.push("/masters");
      });
    }
    
  };

  const handleClickNew = () => {
    resetMiscData();
    addInput("");
    history.push("/masters");
  };

  useEffect(() => {
    return () => {
      resetMiscData();
      addInput("");
    }
  },[])


  const handleChangeName = (e) => {
    const master_name = e.target.value;
    validateInput("master_name", master_name);
    setMastersName(e.target.value);
  };

  useEffect(() => {
    if (create_misc_data?.admin?.name) {
      validateInput("master_name", create_misc_data?.admin?.name);
      const foundObject = misc_data.find(
        (obj) =>
          obj.name?.toLowerCase() === create_misc_data?.admin?.name?.trim()?.toLowerCase()
      );
      if (foundObject) {
        if(create_misc_data?.admin?.id !== foundObject?.id) setError(true);
      } else {
        setError(false);
      }
    }
  }, [create_misc_data?.admin]);

  const handleCheckboxNewChange = (row) => {
    console.log("row is", row);
    let newValuesArray = [...create_misc_data.module];
    const itm_idx = create_misc_data.module.findIndex(
      (itm) => itm.checkid === row.checkid
    );
    if (itm_idx !== -1) {
      newValuesArray[itm_idx].status = !row.status;
    }
    let newMiscData = { ...create_misc_data };
    newMiscData.module = newValuesArray;
    postMiscData(newMiscData);
  };

  const handleBack = () => {
    resetMiscData();
    addInput("");
    history.push("/masters");
  };

  const handleMasterCheckbox = (e) => {
    toggleStatus(e.target.checked)
    setIsActive(e.target.checked)
  }

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid container spacing={2}>
            <Grid item xs={10} md={5}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputPlanName"
              >
                Masters Name <span className="text-mandatory">*</span>
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="inputPlanName"
                  type="text"
                  placeholder=""
                  required
                  autoFocus
                  fullWidth
                  margin="none"
                  onChange={handleChangeName}
                  // onBlur={handleChangeName}
                  inputProps={{
                    maxLength: 50,
                  }}
                  value={create_misc_data?.admin?.name}
                  disabled={state?.rid}
                />
              </FormControl>
              {error && <p style={{ color: "red" }}>Name already exists...</p>}
              <FormHelperText sx={{ color: "red" }} id="master-helper-text">
                {errors.master_name}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid
            item
            xs={10}
            md={6}
            className="col-auto custom-control custom-checkbox"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  onChange={handleMasterCheckbox}
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
        </Box>

        <Box className="mb-4">
          <Grid container spacing={2} className="align-items-end">
            <Grid item xs={10} md={5}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputValues"
              >
                Values <span className="text-mandatory">*</span>
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined" className="valid-align-field">
                <OutlinedInput
                  id="inputValues"
                  type="text"
                  placeholder="Input"
                  required
                  fullWidth
                  margin="none"
                  value={userInput}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <FormHelperText sx={{ color: "red" }} id="module-helper-text" className="valid-align-text">
                {errors.values_array}
                {moduleValuesError && (
                  <p className="mb-0" >Module Name already exists...</p>
                )}
              </FormHelperText>
              </FormControl>
              
            </Grid>
            <Grid item xs={2} md={2}>
              <Button
                // disabled={error}
                type="button"
                className="add-btn"
                sx={{ my: 1 }}
                onClick={() => handleAddToMaster()}
                helperText=" "
              >
                <img src={AddIcon} alt="add-btn-icon" className="user-logo" />
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "450px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="start">Values</TableCell>
                  <TableCell sx={{ width: "80px" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {create_misc_data?.module
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ color: "#393939 !important" }}>
                        {row.value}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={row.status}
                          // checked={checkedItems[index] || false}
                          onChange={() => handleCheckboxNewChange(row)}
                          color="primary"
                          sx={{
                            color: "#D4D4D4",
                            "&.Mui-checked": {
                              color: "#416AD4",
                            },
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={page}
            count={create_misc_data.module.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>

        <Box className="d-flex justify-content-between mt-4">
          <Button className="btn-save" onClick={handleClickNew}>
            Back
          </Button>
          <div className="d-flex">
            <Button className="btn-cancel me-3" onClick={handleBack}>
              Cancel
            </Button>
            <Button
              className="btn-save"
              onClick={handleClick}
              disabled={error || errors.master_name}
            >
              Save
            </Button>
          </div>
        </Box>
      </Box>
    </SideBarContent>
  );
}

const mapStatetoProps = (state) => {
  return {
    valuesArray: state.master.valuesArray,
    userInput: state.master.userInput,
    create_misc_data: state.master.create_misc_data,
    misc_data: state.master.misc_data,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     addToList: (input) => dispatch(addToList(input)),
//     addInput: (input) => dispatch(addInput(input)),
//     updateCheckbox: (input) => dispatch(updateCheckbox(input)),
//     setValuesArray: (arr) => dispatch(setValuesArray(arr)),
//     createMiscdata: (
//       data,
//       header,
//       successCallback = () => {},
//       errorCallback = () => {}
//     ) =>
//       dispatch(
//         createMiscdata(
//           data,
//           header,
//           (successCallback = () => {}),
//           (errorCallback = () => {})
//         )
//       ),
//     setMastersName: (input) => dispatch(setMastersName(input)),
//     setMastersValue: (input) => dispatch(setMastersValue(input)),
//     resetMiscData: () => dispatch(resetMiscData()),
//     getModuleDataByAdminId: (id) => dispatch(getModuleDataByAdminId(id)),
//     postMiscData: (id) => dispatch(postMiscData(id)),
//     putMiscdata: (data, sC, eC) => dispatch(putMiscdata(data, sC, eC)),
//     //     clearValuesArray: () => dispatch(clearValuesArray()),
//     //     clearCreateMiscData: () => dispatch(clearCreateMiscData())
//   };
// };

export default connect(mapStatetoProps, {
    addToList,
    addInput,
    updateCheckbox,
    setValuesArray,
    createMiscdata,
    setMastersName,
    setMastersValue,
    resetMiscData,
    getModuleDataByAdminId,
    postMiscData,
    putMiscdata,
    getMiscData,
    toggleStatus,
    pushMessage
})(CreateMasters);
