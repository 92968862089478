import api from './configs/axiosConfig';

const SubscriptionMaster = '/subscription';
const ParentSubscriptionMaster = '/subscription-parent';
const DeleteSubscriptionChild = '/subscription-child';

export const SubscriptionAPI = {
  getSubscription: async function () {
    const response = await api.get(SubscriptionMaster);
    return response;
  },
  getSingleSubscription: async function (id) {
    const response = await api.get(SubscriptionMaster + '/' + id);
    return response;
  },
  postSubscription: async function (data, header) {
    const response = await api.post(SubscriptionMaster, data, header);
    return response;
  },
  updateSubscription: async function (id, data, header) {
    const response = await api.put(SubscriptionMaster + '/' + id, data, header);
    return response;
  },
  updateParentSubscription: async function (id, data, header) {
    const response = await api.put(
      ParentSubscriptionMaster + '/' + id,
      data,
      header
    );
    return response;
  },
  deleteSubscription: async function (id) {
    const response = await api.delete(DeleteSubscriptionChild + '/' + id);
    return response;
  },
};
