import { SubscriptionAPI } from '../../../../apis/subscriptionAPI';
import { SET_LOADING } from '../../../../store/types';
import {
  ADD_SINGLE_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  POST_SUBSCRIPTION,
  PARENT_SUBSCRIPTION,
  GET_SINGLE_SUBSCRIPTION,
  GET_PARENT_SUBSCRIPTION,
  CLEAR_SINGLE_SUBSCRIPTION_AFTER_EDIT,
  CLEAR_PARENT_SUBSCRIPTION,
  CLEAR_SINGLE_SUBSCRIPTION,
} from '../types';

export const getSubscriptions = (
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch({ type: SET_LOADING, payload: true });

      const response = await SubscriptionAPI.getSubscription();
      console.log('getSubscriptions:', response);
      if (response.status === 200) {
        successCallback(response.data);
        dispatch({ type: GET_SUBSCRIPTION, payload: response.data.data });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        errorCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error.response);
      console.log('getSubscriptions:', error);
    }
  };
export const getSingleSubscriptions = (
  id,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      console.log('getSingleSubscriptions');
      dispatch({ type: SET_LOADING, payload: true });

      const response = await SubscriptionAPI.getSingleSubscription(id);
      console.log('getSingleSubscriptions:', response.data?.data[0]);
      if (response.status === 200) {
        successCallback(response.data?.data[0]?.subsDetails);
        dispatch({
          type: GET_PARENT_SUBSCRIPTION,
          payload: response.data?.data[0],
        });
        // dispatch({
        //   type: GET_PARENT_SUBSCRIPTION,
        //   payload: response.data?.data[0].subs,
        // });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        errorCallback(response.data);
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error.response);
      console.log('getSingleSubscriptions:', error);
    }
  };
export const putSingleSubscriptions = (
  data,
  id,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await SubscriptionAPI.updateSubscription(id, data);
      console.log('putSingleSubscriptions:', response);
      if (response.status === 200) {
        successCallback(response.data.message);
        dispatch({ type: POST_SUBSCRIPTION, payload: response.data.data });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error);
      console.log('putSingleSubscriptions:', error);
    }
  };
export const putParentSubscriptions = (
  data,
  id,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      if(!id) {
        errorCallback('Please fill the required fields and add a Plan.');
        return;
      }
      dispatch({ type: SET_LOADING, payload: true });
      const response = await SubscriptionAPI.updateParentSubscription(id, data);
      console.log('putParentSubscriptions:', response);
      if (response.status === 200) {
        successCallback(response.data.message);
        // dispatch({ type: POST_SUBSCRIPTION, payload: response.data.data });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error);
      console.log('putParentSubscriptions:', error);
    }
  };
export const postSubscriptions = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await SubscriptionAPI.postSubscription(data);
      console.log('postSubscriptions:', response);
      if (response.status === 200 || response.status === 201) {
        successCallback(
          response.data.data.subscriptionDetails.id,
          response.data.message
        );
        dispatch({ type: POST_SUBSCRIPTION, payload: response.data.data });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error);
      console.log('postSubscriptions:', error);
    }
  };
export const updateSubscriptions = (
  data,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch({ type: SET_LOADING, payload: true });

      const response = await SubscriptionAPI.updateSubscription(data);
      console.log('updateSubscriptions:', response);
      if (response.status === 200) {
        successCallback(response.data);
        dispatch({ type: GET_SUBSCRIPTION, payload: response.data.data });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error.response);
      console.log('updateSubscriptions:', error);
    }
  };
export const deleteSubscriptions = (
  id,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      dispatch({ type: SET_LOADING, payload: true });

      const response = await SubscriptionAPI.deleteSubscription(id);
      console.log('deleteSubscriptions:', response);
      if (response.status === 200) {
        successCallback(response.data.message);
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      errorCallback(error.response);
      console.log('deleteSubscriptions:', error);
    }
  };
export const addSingleSubscription = (data) =>
  async function (dispatch) {
    try {
      dispatch({ type: ADD_SINGLE_SUBSCRIPTION, payload: data });
    } catch (error) {
      console.log('updateSubscriptions:', error);
    }
  };
export const addParentSubscription = (data) =>
  async function (dispatch) {
    try {
      dispatch({ type: PARENT_SUBSCRIPTION, payload: data });
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('updateSubscriptions:', error);
    }
  };
export const clearSingleSubscriptionAfterEdit = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SINGLE_SUBSCRIPTION_AFTER_EDIT });
  };
};
export const clearParentSubscription = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PARENT_SUBSCRIPTION });
  };
};
// PARENT_SINGLE_SUBSCRIPTION

// export const clearSingleSubscription = () => function (dispatch) {
//   dispatch({type: CLEAR_SINGLE_SUBSCRIPTION})
// }