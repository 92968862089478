import React from "react";
import { Pagination, Stack, Typography } from "@mui/material";



const CustomPagination = ({ page, count, rowsPerPage, onPageChange,  }) => {
    const numPages = Math.ceil(count / rowsPerPage);
    // console.log("page",page);
    // console.log("count",count);
    // console.log("rowsPerPage",rowsPerPage);
    // console.log("onPageChange",onPageChange);

    const handlePageChange = (event, value) => {
        onPageChange(value-1);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderTop: '1px solid #E4E4E4' }}>
            <Typography variant="body1" className="fs-15 font-skmodernist" sx={{color: '#5F6165'}}>
                Showing {Math.min((page + 1) * rowsPerPage, count)} of {count} Results
            </Typography>
            <div>
            <Stack spacing={2} direction="row">
                <Pagination
                    count={numPages}
                    page={page + 1} // Convert to 1-based index for MUI Pagination
                    onChange={handlePageChange}
                    shape="rounded"
                    variant="outlined"
                />
            </Stack>
                {/* {[...Array(numPages)].map((_, index) => (
                    <button key={index} onClick={() => onPageChange(index)} className="fs-15 font-skmodernist" style={{ margin: '0 5px', padding: '4px 12px 5px 12px', border: '1px solid #E4E4E4', borderRadius: '5px', backgroundColor: page === index ? '#864FFD' : '#FAFAFA',color: page === index ? '#FFFFFF' : '#864FFD' }}>
                        {index + 1}
                    </button>
                ))} */}
            </div>
        </div>
    );
};


export default CustomPagination;