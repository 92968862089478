import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import { RichTextEditor, LinkBubbleMenu, TableBubbleMenu } from 'mui-tiptap';
import EditorMenuControls from './EditorMenuControls';
import useExtensions from './useExtensions';
import useValidation from '../../hooks/useValidation';

const RichTextEditorComponent = forwardRef(function RichTextEditorComponent(
	{
		content,
		setCharCount,
		setcontentHTML,
		setcontentText,
		onChange = () => {},
	},
	ref
) {
	const extensions = useExtensions({
		placeholder: 'Add your own content here...',
	});

	const { validateInput, errors } = useValidation();

	useEffect(() => {
		const contentLength = ref.current?.editor?.getText() ?? '';

		setCharCount(5000 - contentLength.length);
	}, []);

	return (
		<div>
			<RichTextEditor
				ref={ref}
				extensions={extensions}
				content={content}
				onUpdate={({ editor }) => {
					const contentTxt = editor?.getText() ?? '';
					const contentHTML = editor?.getHTML() ?? '';
					setcontentHTML(contentHTML);
					setcontentText(contentTxt);
					console.log('contentLength', contentTxt);
					// validateInput("pageContent",contentText)
					onChange(contentHTML);
					setCharCount(5000 - contentTxt.length);
				}}
				renderControls={() => <EditorMenuControls />}
			>
				{() => (
					<>
						<LinkBubbleMenu />
						<TableBubbleMenu />
					</>
				)}
			</RichTextEditor>
		</div>
	);
});

export default RichTextEditorComponent;
