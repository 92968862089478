import React, { useEffect, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import { useHistory } from 'react-router-dom';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import IOSSwitch from '../../component/Switch/Switch';
import { connect } from 'react-redux';
import {
  getSubscriptions,
  getSingleSubscriptions,
  clearSingleSubscriptionAfterEdit,
  addSingleSubscription,
  clearParentSubscription,
  putSingleSubscriptions,
  putParentSubscriptions,
} from './redux/actions/subscription-actions';
import moment from 'moment/moment';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';

function SubscriptionMaster({
  getSubscriptions,
  subscriptions,
  getSingleSubscriptions,
  addSingleSubscription,
  clearParentSubscription,
  clearSingleSubscriptionAfterEdit,
  pushMessage,
  putSingleSubscriptions,
  putParentSubscriptions,
}) {
  console.log('===subscriptions101', subscriptions);
  const [filteredSubscription, setFilteredSubscription] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    const filtered = (Array.isArray(subscriptions) ? subscriptions : []).filter(
      (subscription) => {
        const nameMatch = subscription.name
          ?.toLowerCase()
          .includes(searchText?.toLowerCase().trim());
        const descriptionMatch = subscription.description
          ?.toLowerCase()
          .includes(searchText?.toLowerCase().trim());
        const createdAtMatch = subscription.createdAt
          ?.toLowerCase()
          .includes(searchText?.toLowerCase().trim());
        return !searchText || nameMatch || descriptionMatch || createdAtMatch;
      }
    );
    setFilteredSubscription(filtered);
  }, [searchText, subscriptions]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const history = useHistory();

  const handleCreateButtonClick = () => {
    clearParentSubscription();
    history.push('/create-subscription');
  };

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const onEditSubscription = async (id) => {
    await getSingleSubscriptions(id, (res) => {
      addSingleSubscription({ id: res.id });
    });
    clearSingleSubscriptionAfterEdit();
    history.push('/create-subscription', { isEditMode: true });
  };

  useEffect(() => {
    setFilteredSubscription(Array.isArray(subscriptions) ? subscriptions : []);
  }, [subscriptions]);
  const onSucess = (message) => {
    console.log('===onSucess', message);
    pushMessage(message);
  };
  const onError = (message) => {
    console.log('===onSucess', message);
    pushMessage(message);
  };
  const updateStatus = (data) => {
    console.log('updateStatus==', data);
    putParentSubscriptions(
      data,
      data?.id,
      (r) => {
        getSubscriptions();
        onSucess(r);
      },
      onError
    );
  };
  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="align-items-center just-content-between"
          >
            <Grid item xs={10} md={6}>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <Search
                      sx={{
                        width: '1em',
                        height: '1em',
                        marginRight: '8px',
                        color: '#864FFD',
                      }}
                      color="action"
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={'auto'} className="ms-auto">
              <Button className="btn-create" onClick={handleCreateButtonClick}>
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Subscription Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Created On</TableCell>
                  <TableCell>Active/Inactive</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(filteredSubscription) &&
                  filteredSubscription
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          {moment(row.createdAt).format('DD-MM-YY,HH:mm')}
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            checked={row?.is_active}
                            onChange={(r) => {
                              console.log('==r row', row, r.target.checked);
                              updateStatus({
                                ...row,
                                is_active: r.target.checked,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Button onClick={() => onEditSubscription(row?.id)}>
                            <EditIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={page}
            count={
              Array.isArray(filteredSubscription)
                ? filteredSubscription.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = ({ subscription }) => ({
  subscriptions: subscription.subscriptions,
});

export default connect(mapStateToProps, {
  getSubscriptions,
  getSingleSubscriptions,
  clearSingleSubscriptionAfterEdit,
  addSingleSubscription,
  clearParentSubscription,
  putSingleSubscriptions,
  pushMessage,
  putParentSubscriptions,
})(SubscriptionMaster);
