import { SET_PROFILE_ADMIN } from "../types"

const initial_state = {
    profileObj: {},
    // profile_data: ""
}


const profileAdminData = (state= initial_state, action) => {
    switch(action.type){
        case SET_PROFILE_ADMIN:
            return{
                ...state,
                profileObj: action.payload
            }
        // case GET_PROFILE_ADMIN:
        //     return {
        //         ...state,

        //     }
        default:
            return state
    }
}



export default profileAdminData;