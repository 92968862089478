import moment from "moment";
import { SocialMediaAPI } from "../../../../apis/SocialMediaAPI";
import { SET_LOADING } from "../../../../store/types";
import { GET_SOCIAL_MEDIA_REQUEST, GET_SOCIAL_MEDIA_SUCCESS, GET_SOCIAL_MEDIA_FAILURE, SET_SOCIAL_ACTION_TYPE, SET_SOCIAL_MEDIA_OBJECT, CLEAR_SOCIAL_MEDIA_OBJ, SET_SOCIAL_LINKS_FILTER } from "../types";
import { POST_SOCIAL_MEDIA_REQUEST, POST_SOCIAL_MEDIA_SUCCESS, POST_SOCIAL_MEDIA_FAILURE } from "../types";
import { PUT_SOCIAL_MEDIA_REQUEST, PUT_SOCIAL_MEDIA_SUCCESS, PUT_SOCIAL_MEDIA_FAILURE } from "../types";



export const getSocialMediaRequest = (load) => {
    return {
        type: GET_SOCIAL_MEDIA_REQUEST,
        payload: load
    }
}


export const getSocialMediaSuccess = (media) => {
    return {
        type: GET_SOCIAL_MEDIA_SUCCESS,
        payload: media
    }
}

export const getSocialMediaFailure = (error) => {
    return {
        type: GET_SOCIAL_MEDIA_FAILURE,
        payload: error
    }
}





export const fetchSocialMedia = () => {
    return async (dispatch) => {
        dispatch({
            type: SET_LOADING,payload: true
        })
        try {
            const response = await SocialMediaAPI.getSocialMedia();
            console.log("response", response)

            if (response.status === 200) {
                const sortedArray = response?.data?.data?.sort((a,b)=> moment(b.updatedAt) - moment(a.updatedAt))
                // console.log("SortedArray&&&& :", sortedArray)
                dispatch({ type: GET_SOCIAL_MEDIA_SUCCESS, payload: sortedArray });
                dispatch({type: SET_LOADING,payload: false})
            }
            else{
                dispatch({type: SET_LOADING,payload: false})
            }
        } catch (error) {
            dispatch({
                type: GET_SOCIAL_MEDIA_FAILURE,
                payload: error
            });
            dispatch({type: SET_LOADING,payload: false})
            console.log("Error getting social media")
            throw error;

        }
    }
}


///Post social media action

export const postSocialMediaRequest = () => {
    return {
        type: POST_SOCIAL_MEDIA_REQUEST,
    }
}


export const postSocialMediaSuccess = (media_data) => {
    return {
        type: POST_SOCIAL_MEDIA_SUCCESS,
        payload: media_data
    }
}


export const postSocialMediaFailure = (error) => {
    return {
        type: POST_SOCIAL_MEDIA_FAILURE,
        payload: error
    }
}


export const createSocialMedia = (data,successCallback=()=>{}, errorCallback=()=>{}) => async function (dispatch) {
    try {
       

        dispatch({type: SET_LOADING,payload: true})

        const response = await SocialMediaAPI.createSocialMedia(data)
        console.log("create_response:", response)
        if(response.status === 201){
            successCallback(response.data)
            dispatch({type: SET_LOADING,payload: false});

        }
        else{
            errorCallback(response.data)
            dispatch({type: SET_LOADING,payload: false});
        }

    } catch (error) {
        errorCallback(error.response.data)
        dispatch({
            type: POST_SOCIAL_MEDIA_FAILURE,
            payload: error
        })
        dispatch({type: SET_LOADING,payload: false});
        console.log("create_error:", error)
    }
}



// Update social media actions

export const updateSocialMediaRequest = () => {
    return {
        type: PUT_SOCIAL_MEDIA_REQUEST
    }
}

export const updateSocialMediaSuccess = (updated_data) => {
    return {
        type: PUT_SOCIAL_MEDIA_SUCCESS,
        payload: updated_data
    }
}

export const updateSocialMediaFailure = (error) => {
    return {
        type: PUT_SOCIAL_MEDIA_FAILURE,
        payload: error
    }
}

export const setSocialActionType = (type) => {
    return (dispatch) => {
        dispatch({type:SET_SOCIAL_ACTION_TYPE, payload:type})
    }
}

export const setsocialMediaObj = (socialObj) => function(dispatch) {
    
    dispatch({type: SET_SOCIAL_MEDIA_OBJECT, payload: socialObj});
}


export const updateSocialMediaReq = (id, updated_data, successCallback=() => {}, errorCallback = ()=> {}) => {
    return async (dispatch) => {
        dispatch({type: SET_LOADING,payload: true})
        try {
            const response = await SocialMediaAPI.updateSocialMedia(id, updated_data);
            console.log("response", response)

            if (response.status === 200) {
                successCallback(response.data)
                dispatch({type: SET_LOADING,payload: false});
            }
            else{
                dispatch({type: SET_LOADING,payload: false});
                errorCallback(response.data)
            }
        } catch (error) {
            dispatch({type: SET_LOADING,payload: false});
            if(error.response.status===401){
                errorCallback(error?.response?.data?.message);  
            }
            else{
                errorCallback(error?.response?.data?.error); 
            }
            // errorCallback(error?.response?.data.error);
            console.log("Error getting social media", error);
            throw error;

        }

    }
}

export const clearSocialMediaObj = () => {
    return (dispatch) => {
         dispatch({type:CLEAR_SOCIAL_MEDIA_OBJ})
    }
}


export const setSocialMediaFilter = (filter) => {
    return (disptach) => {
        disptach({type:SET_SOCIAL_LINKS_FILTER,payload: filter})
    }
}