import React, { useEffect, useRef, useState } from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, Card, CardContent, CardMedia, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import { ReactComponent as ExcelIcon } from "../../assets/excel-icon.svg";
import { ReactComponent as WhatsappThumb } from "../../assets/whatsapp-business-icon.svg";
import { ReactComponent as GoogleThumb } from "../../assets/google-business-icon.svg";
import { ReactComponent as StartupThumb } from "../../assets/startup-service-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download-icon.svg";
import DownloadTempIcon from "../../assets/download-icon-purple.svg";
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import { connect } from "react-redux";
import { addUploadFileToServer, clearFileObject, editAdminToolsData, fetchUploadFile, setStatusData, setUploadFileData } from "./redux/actions/tools-services-action";
import { pushMessage } from "../../component/MessageBox/redux/actions/message-box-action";
import IOSSwitch from "../../component/Switch/Switch";

export const UPLOAD_BASE_URL = process.env.REACT_APP_UPLOAD_BASE_URL

function ToolsServicesMaster({ setUploadFileData, upload_file, addUploadFileToServer, pushMessage, clearFileObject, fetchUploadFile, get_file_rowData, setStatusData, editAdminToolsData }) {
    //const navigate=useNavigate();
    const [searchText, setSearchText] = useState("");
    const resumeUploadRef = useRef(null);
    // const [fileNameData, setFileNameData] = useState(false);
    // const [rows, setRows] = useState(data);


    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };


    const handleResumeUploadFile = async (e) => {
        const fileName = e.target.files;
        console.log("fileName uplaod: ", fileName);
        setUploadFileData(fileName);

    };
    const handleResumeUploadClick = () => {
        resumeUploadRef.current.click();
    };


    // const data = [
    //     { id: 1, image: <WhatsappThumb />, toolsServiceName: 'WhatsApp Business', serviceType: 'Tool' },
    //     { id: 2, image: <GoogleThumb />, toolsServiceName: 'Google Business Profile', serviceType: 'Service' },
    //     { id: 3, image: <StartupThumb />, toolsServiceName: 'Job Posting', serviceType: 'Tool' },
    //     { id: 4, image: <WhatsappThumb />, toolsServiceName: 'Advisory Service', serviceType: 'Service' },
    //     { id: 5, image: <GoogleThumb />, toolsServiceName: 'Concierge Service', serviceType: 'Tool' },
    //     { id: 6, image: <StartupThumb />, toolsServiceName: 'Digital Catalog', serviceType: 'Service' },
    // ];


    useEffect(() => {
        fetchUploadFile();
    }, [])


    const handleStatusChange = async (checked, item) => {
        updateAllWidgetState(item, 'is_active', checked)
        // setIsChanged(true);
        console.log("get_file_rowData :#####", get_file_rowData)


    }

    const updateAllWidgetState = async (item, key, value) => {
        let newUploadFile = [...get_file_rowData];
        const itmIdx = get_file_rowData.findIndex(itm => itm.id === item.id);
        if (itmIdx !== -1) {
            newUploadFile[itmIdx][key] = value;

            const jsonData = JSON.stringify({
                is_active: get_file_rowData[itmIdx]?.is_active,
            });
            const customConfig = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            await editAdminToolsData(get_file_rowData[itmIdx]?.id,
                jsonData,
                customConfig,
                () => {
                    if(get_file_rowData[itmIdx]?.is_active == true){
                        pushMessage('Activated Successfully');
                    }else{
                        pushMessage('InActive Successfully');
                    }
                    
                },
                (error) => {
                    pushMessage(error);
                }
            );
        }
        setStatusData(newUploadFile);

    }


    const handleUploadFile = async () => {
        let formData = new FormData();
        console.log("uploaded fileType data :", upload_file[0])
        formData.append('file', upload_file[0]);

        await addUploadFileToServer(formData, () => {
            pushMessage("File Uploaded SuccessFully");
            // setFileNameData(true);
            fetchUploadFile();
            clearFileObject();
        })
    }



    return (
        <SideBarContent>
            <Box className="custom-card-bg">

                <Box className="d-flex justify-content-between align-items-center mb-5">
                    <div className="col-md-4 position-relative">
                        <input
                            type="file"
                            style={{ display: "none" }}
                            ref={resumeUploadRef}
                            onChange={handleResumeUploadFile}
                            id="file"
                            accept=".doc,.docx,.pdf,.xlsx,.xls"
                        />
                        <InputLabel
                            htmlFor="inputCIN"
                            className="text-start fs-14 text-black font-skmodernist">
                            Upload Tool / Service Doc
                        </InputLabel>
                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                            onClick={handleResumeUploadClick}
                            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}>
                            <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
                        </Button>
                        <div style={{ position: 'absolute', bottom: '-25px' }}>
                            {upload_file && Array.from(upload_file).map((row) => <div key={row?.size} className="">{row?.name}</div>)}
                        </div>
                    </div>
                    <div>
                        <Button
                            download={'template.xlsx'}
							href={`${UPLOAD_BASE_URL}/uploads/admin/tools/Tools_and_services.xlsx`}
                            type="submit"
                            fullWidth
                            variant="outlined"
                            className="text-secondary fs-14 font-gotham fw-medium"
                            sx={{ textTransform: 'capitalize', border: 'none !important' }}>
                            <img src={DownloadTempIcon} className="user-logo me-2" />
                            Download Template
                        </Button>
                    </div>
                </Box>
                { upload_file && Array.from(upload_file)?.length > 0 && <Box className="my-3">
                    <Button className="btn-save" onClick={handleUploadFile}>UPLOAD FILE</Button>
                </Box>}


                <Paper sx={{ width: "100%" }} className="my-3">

                    <TableContainer sx={{ maxHeight: "450px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Service / Tools  name</TableCell>
                                    <TableCell>Service Type</TableCell>
                                    <TableCell>Active / Inactive</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {get_file_rowData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.id}>

                                        <TableCell>
                                            <img src={row.cover_image} alt={row.cover_image} style={{ width: "67px", height: "60px", borderRadius: "4px" }} />
                                            {/* {row.tools_cover_image} */}
                                        </TableCell>
                                        <TableCell>
                                            {row.title}
                                        </TableCell>
                                        <TableCell>
                                            {row.service_type}
                                        </TableCell>
                                        <TableCell>
                                            <FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} />}
                                                checked={row.is_active}
                                                onChange={(e, checked) => handleStatusChange(checked, row)}
                                            />

                                        </TableCell>



                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <CustomPagination page={page} count={get_file_rowData?.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
                </Paper>
            </Box>
        </SideBarContent >
    );
}

const mapStateToProps = (state) => {
    const { upload_file, get_file_rowData } = state.tools;
    return {
        upload_file,
        get_file_rowData
    }
}

export default connect(mapStateToProps, { setUploadFileData, addUploadFileToServer, pushMessage, clearFileObject, fetchUploadFile, setStatusData, editAdminToolsData })(ToolsServicesMaster);