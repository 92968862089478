import * as React from 'react';
import { Box, styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import zIndex from '@mui/material/styles/zIndex';

export default function ServerModal({ saveImg, openFile, fileRef, close, dialogtype, yesCallBack }) {
  const rootRef = React.useRef(null);

  const handleYes = () => {
    console.log("updated image 2")
    if(dialogtype==="remove"){
      yesCallBack();
      // if(fileRef){
      //   fileRef?.click();
      // }
    }
    else{
      if(fileRef){
        fileRef?.click();
      }
    }

    // openFile();
    // saveImg();

  }

  const handleNo = () => {
    close(false);
  }

  return (
    <Box
      sx={{
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        // Isolate the modal to the demo
        zIndex: 800,
        position:"absolute",

      }}
      ref={rootRef}
    >
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        container={() => rootRef.current}
      >
        <ModalContent sx={{ width: 400 }}>
          <h2 id="server-modal-title" className="modal-title">
            Update Image
          </h2>
          <span id="server-modal-description" className="modal-description">
            Are you sure you want to {dialogtype} the Existing image?
          </span>
          <DialogActions>
          <Button onClick={handleYes} className="btn-save">
          YES
        </Button>
        <Button onClick={handleNo} className="btn-save">
          NO
        </Button>
        </DialogActions>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0 0 0 / 0.4);

  &.base-Modal-hidden {
    visibility: hidden;
  }
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);


