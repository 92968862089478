import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Search, Troubleshoot } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as OptionsIcon } from '../../assets/options-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import EnquiryUpdateStatusModal from './EnquiryUpdateStatusModal';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  getEnquiryDetails,
  setEnquiryData,
  setEnquiryFilter,
} from './redux/actions/enquiry-action';
import moment from 'moment';

function EnquiryList({
  all_enquiry,
  getEnquiryDetails,
  setEnquiryData,
  setEnquiryFilter,
  all_enquiry_filter,
  enquiryObj,
}) {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [type, setType] = useState('view');
  const [popperText, setPopperText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [oldStatus, setOldStatus] = useState('');
  
  useEffect(() => {
    if (searchText === '') {
      setEnquiryFilter(all_enquiry);
      setPage(currentPage);
    } else {
      setPage(0);
      const filteredItems = all_enquiry?.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          item?.email?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          item?.phone_number?.includes(searchText) ||
          item?.status?.toLowerCase()?.includes(searchText.toLowerCase())
      );
      setEnquiryFilter(filteredItems);
    }
  }, [all_enquiry, searchText, currentPage]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
    setSearchText(searchTerm, 'searchValue');
  };

  useEffect(() => {
    if (searchText === '') {
      setEnquiryFilter(all_enquiry);
      setPage(currentPage);
    } else {
      setPage(0);
      const filteredItems = all_enquiry?.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(searchText.toLowerCase().trim()) ||
          item?.email?.toLowerCase()?.includes(searchText.toLowerCase().trim()) ||
          item?.status?.includes(searchText.trim()) ||
          item?.phone_number?.includes(searchText)
      );
      setEnquiryFilter(filteredItems);
    }
  }, [all_enquiry, searchText]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setCurrentPage((prevPage) => {
      if (prevPage === 0) {
        return newPage;
      } else {
        return prevPage;
      }
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    console.log('row_data@@@', row);
    setEnquiryData(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = ['Update Status', 'View'];

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getEnquiryDetails();
  }, []);

  const [popper, setPopper] = React.useState(null);

  const handleClickPopper = (event, comments) => {
    setPopper(event.currentTarget);
    setPopperText(comments);
  };

  const handleClosePopper = () => {
    setPopper(null);
    setPopperText('');
  };
  const openPopper = Boolean(popper);
  const idd = openPopper ? 'simple-popover' : undefined;

  function truncateText(text, length = 16) {
    if (text.length <= length) {
      return text;
    }

    return text.substr(0, length) + '\u2026';
  }

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="align-items-center just-content-between"
          >
            <Grid item xs={10} md={6}>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <Search
                      sx={{
                        width: '1em',
                        height: '1em',
                        marginRight: '8px',
                        color: '#864FFD',
                      }}
                      color="action"
                    />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                <TableCell>Enq. ID</TableCell>
                  <TableCell sx={{ minWidth: '100px !important' }}>
                    Enquiry Date
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>User Comments</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {all_enquiry_filter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.code}</TableCell>
                      <TableCell sx={{ minWidth: '100px !important' }}>
                        {moment(row.createdAt).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.phone_number}</TableCell>
                      <TableCell>{row.type == "generic" ? "General" : row.type == "service" ? "Service" : row.type == "subscription" ? "Subscription" : "General" }</TableCell>
                      <TableCell>
                        <Typography className='font-gotham fs-13'>
                          <span
                            style={{ width: '175px', display: 'inline-block' }}
                          >
                            {truncateText(row.comments)}{' '}
                          </span>
                          {row?.comments?.length > 16 ? (
                            <Button
                              aria-describedby={idd}
                              className="ps-2 justify-content-start btn-tooltip"
                              onClick={(e) =>
                                handleClickPopper(e, row.comments)
                              }
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  width: '19px',
                                  height: '19px',
                                  color: '#864FFD',
                                }}
                              />
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {/* <Typography className={row.status === 'pending' ? 'text-status-pending' : 'text-status-contacted'}>{row.status}</Typography> */}
                        <Typography
                          className={
                            row.status.toLowerCase() === 'pending'
                              ? 'text-status-inactive'
                              : row.status.toLowerCase() === 'closed'
                              ? 'text-status-contacted'
                              : 'text-status-pending'
                          }
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={(e) => handleClick(e, row)}>
                          <OptionsIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Popover
            id={idd}
            open={openPopper}
            anchorEl={popper}
            onClose={handleClosePopper}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ p: 2, maxWidth: '300px' }}>
              <Typography className="fs-16 fw-normal font-gotham text-black" sx={{wordBreak: 'break-all'}}>
                {popperText}
              </Typography>
            </Box>
          </Popover>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option, optionIdx) => {
              let renderOption = false;
              if (optionIdx === 0 && enquiryObj?.status === 'pending') {
                renderOption = true; // Hide "Update Status" when status is not "pending"
              }
              if (optionIdx === 0 && enquiryObj?.status === 'contacted') {
                renderOption = true; // Hide "View" when status is not "contacted"
              }
              if (optionIdx === 1 && enquiryObj?.status === 'closed') {
                renderOption = true; // Hide "Update Status" when status is not "closed"
              }

              if (renderOption) {
                return (
                  <MenuItem
                    anchororigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformorigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    sx={{
                      boxShadow:
                        '0px 0px 0px 1px rgba(0,0,0,-1.8), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 4px -1px rgba(0,0,0,0.10) !important',
                    }}
                    key={option}
                    onClick={() => {
                      if (optionIdx === 0) {
                        setOldStatus(enquiryObj?.status)
                        setType('edit');
                        handleClose();
                        handleOpenModal();
                      }
                      if (optionIdx === 1) {
                        setType('view');
                        handleClose();
                        handleOpenModal();
                      }
                    }}
                  >
                    {option}
                  </MenuItem>
                );
              }
            })}
          </Menu>
          <EnquiryUpdateStatusModal
            open={openModal}
            onClose={handleCloseModal}
            userSelect={type}
            oldStatus={oldStatus}
          />
          <CustomPagination
            page={page}
            count={all_enquiry_filter.length || 0}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = (state) => {
  const { all_enquiry, all_enquiry_filter, enquiryObj } = state.enquiry;
  return {
    all_enquiry,
    all_enquiry_filter,
    enquiryObj,
  };
};

export default connect(mapStateToProps, {
  getEnquiryDetails,
  setEnquiryData,
  setEnquiryFilter,
})(EnquiryList);
