import { RoleMasterAPI } from "../../../../apis/RoleMasterAPI";
import { SET_LOADING } from "../../../../store/types";
import { ALL_ROLE_FILTER, CLEAR_STATE, CREATE_NAME, CREATE_PERMISSION, GET_ADMIN_MODULE, GET_ROLE_MASTER, SET_ACTION_TYPE, SET_ROLE_PERMISSION_UPDATE, SET_ROW_ID } from "../types";


export const fetchRole = () => {
    return async (dispatch) => {
        dispatch({
            type: SET_LOADING, payload: true
        })
        try {
            const response = await RoleMasterAPI.getRole();
            console.log("Response_data :", response)
            if (response.status === 200) {
                dispatch({ type: GET_ROLE_MASTER, payload: response.data.data });
                dispatch({ type: SET_LOADING, payload: false });
            } else{
                dispatch({ type: SET_LOADING, payload: false });
            }

        } catch (error) {
            dispatch({ type: SET_LOADING, payload: false });
            console.log("error:", error);
        }
    }
}


export const createRoleMaster = (data, header, successCallback=() => {}, errorCallback = ()=> {}) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADING, payload: true })
        try {
            const response = await RoleMasterAPI.createRole(data, header);
            console.log("Response_post_role:", response)            
            if (response.status === 201) {
                successCallback(response.data)
                dispatch({ type: SET_LOADING, payload: false })
            }else{
                dispatch({ type: SET_LOADING, payload: false });
            }
            
        } catch (error) {
            dispatch({ type: SET_LOADING, payload: false });
            console.log("error", error)
        }
    }

}



export const getAssignModule = (existing_module = []) => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADING, payload: true });
        try {
            const response = await RoleMasterAPI.assignModule();
            console.log("Response_data:", response)
            if (response.status === 200) {
                let newModuleArr = [];
                if (existing_module && existing_module?.length > 0) {
                    newModuleArr = response.data.data.map(itm => {
                        let newItm = { ...itm }
                        const exIdx = existing_module.find(itmm => itmm.id === itm.id);
                        console.log('exIdx', exIdx);
                        if (exIdx) {
                            newItm.is_active = true;
                        }
                        else {
                            newItm.is_active = false;
                        }

                        return newItm
                    })
                } else {
                    newModuleArr = response.data.data.map(itm => {
                        let newItm = {...itm}
                        newItm.is_active = false;
                        return newItm
                    })
                }
                dispatch({ type: GET_ADMIN_MODULE, payload: newModuleArr })
                dispatch({ type: SET_LOADING, payload: false })
            } else{
                dispatch({ type: SET_LOADING, payload: false });
            }
        } catch (error) {
            dispatch({ type: SET_LOADING, payload: false });
            console.log("ERROR : ", error);
        }
    }
}

export const setEditModuleData = (newAllModule) => function (dispatch) {
    dispatch({ type: GET_ADMIN_MODULE, payload: newAllModule })
}


export const getName = (data) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_NAME, payload: data })
    }
}


export const getPermission = (data) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_PERMISSION, payload: data })
    }
}

export const clearRoleState = () => {
    return async (dispatch) => {
        dispatch({ type: CLEAR_STATE })
    }
}


export const setActionType = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_ACTION_TYPE, payload: data })
    }
}


export const setAllRoleFilter = (filter) => {
    return async(dispatch) => {
        dispatch({ type: ALL_ROLE_FILTER, payload: filter})
    }
}

export const updateRoleMaster = (id, data, header, successCallback=() => {}, errorCallback = ()=> {}) => {
    return async(dispatch) => {
        dispatch({ type: SET_LOADING, payload: true });
        try {
            const response = await RoleMasterAPI.updateRole(id, data, header);
            
            if (response.status === 200){
                successCallback(response.data);
                dispatch({ type: SET_LOADING, payload: false });
            } else{
                dispatch({ type: SET_LOADING, payload: false });
            }
        } catch (error) {
            dispatch({ type: SET_LOADING, payload: false });
            console.log("update_role error:", error)
        }  
    }
}

export const setRowId = (data) => {
    return async(dispatch) => {
        dispatch({ type: SET_ROW_ID, payload: data})
    }
}
