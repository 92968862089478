import { ALL_USER_FILTER, CLEAR_USER_DATA, GET_USER_DATA, POST_USER_DATA, SET_USER_ACTION_TYPE, UPDATE_ROLE_DATA, USER_MOBILE_NO, USER_COUNTRY_CODE, USER_COUNTRY_CODE_NAME } from "../types";

const INITIAL_STATE = {
    all_user: [],
    userMasterObj: {email: '', name: '', city: '', country_code: '+91', username: '', is_active: true, roles: [], mobile_number: ''},
    action_Type: 1, // 1 is for create and 0 is for edit,
    all_user_filter: [],
    country_code_name: 'IN',
    user_mobile_number: '',
    user_country_code: '+91',
}


const userMasterReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case GET_USER_DATA:
            return {
                ...state,
                all_user: action.payload
            }
        case POST_USER_DATA:
            return {
                ...state,
                userMasterObj:action.payload
            }
        case SET_USER_ACTION_TYPE:
            return {
                ...state,
                action_Type: action.payload
            }
        case ALL_USER_FILTER:
            return {
                ...state,
                all_user_filter: action.payload
            }
        case CLEAR_USER_DATA:
            return {
                ...state,
                userMasterObj: INITIAL_STATE.userMasterObj,
                country_code_name: INITIAL_STATE.country_code_name,
                user_mobile_number: INITIAL_STATE.user_mobile_number,
                user_country_code: INITIAL_STATE.user_country_code
            }
        case UPDATE_ROLE_DATA:
            return {
                ...state,
                userMasterObj:{
                    ...state.userMasterObj,
                    roles: action.payload
                }
            }
        case USER_MOBILE_NO:
            return {
                ...state,
                user_mobile_number: action.payload
            }
        case USER_COUNTRY_CODE:
            return {
                ...state,
                user_country_code: action.payload
            }
        case USER_COUNTRY_CODE_NAME:
            return {
                ...state,
                country_code_name: action.payload
            }
        default:
            return state
    }
}

export default userMasterReducer;