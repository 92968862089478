import { GET_PAGE_DATA, POST_PAGE_DATA, SET_TEMPLATE_ACTION_TYPE, SET_TEMPLATE_CLEAR_DATA, SET_TEMPLATE_EDIT_DATA, SET_TEMPLATE_FILTER, SET_TEMPLATE_TYPE } from "../types"

const initial_state = {
    all_template: [],
    templateMasterObj: {},
    action_Type: 1, // 1 is for create and 0 is for edit,
    all_template_filter: [],
    set_template_type: {}
}

const templateReducer = (state=initial_state, action) => {
    switch(action.type){
        case GET_PAGE_DATA:
            return {
                ...state,
                all_template: action.payload
            }
        case POST_PAGE_DATA:
            return {
                ...state,
                templateMasterObj: action.payload
            }
        case SET_TEMPLATE_ACTION_TYPE:
            return {
                ...state,
                action_Type: action.payload
            }
        case SET_TEMPLATE_EDIT_DATA:
            return {
                ...state,
                templateMasterObj: action.payload
            }
        case SET_TEMPLATE_CLEAR_DATA:
            return {
                ...state, 
                templateMasterObj: initial_state.templateMasterObj
            }
        case SET_TEMPLATE_FILTER:
            return {
                ...state, 
                all_template_filter: action.payload
            }
        case SET_TEMPLATE_TYPE:
            return{
                ...state,
                set_template_type: action.payload
            }
        default:
            return state
    }
}


export default templateReducer;