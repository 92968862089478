export const ALL_PRODUCT_CATEGORY_DATA = "ALL_PRODUCT_CATEGORY_DATA";
export const RESET_PRODUCT_CATEGORY_PARENT_DATA = "RESET_PRODUCT_CATEGORY_PARENT_DATA";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const IS_PARENT = "IS_PARENT";
export const SET_IS_PARENT = "SET_IS_PARENT";
export const SET_IS_EDITED = "SET_IS_EDITED";
export const SET_IS_RANK = "SET_IS_RANK";
export const SET_IS_ACTIVE = "SET_IS_ACTIVE";
export const SET_IS_PARENTCATEGORY = "SET_IS_PARENTCATEGORY";
export const SET_IS_PRODUCTCATEGORY = "SET_IS_PRODUCTCATEGORY";
export const SET_IS_PLANNAME = "SET_IS_PLANNAME";
export const SET_SELECTED_ROW_DATA = "SET_SELECTED_ROW_DATA";
export const UPDATE_PRODUCT_CATEGORY_DATA = "UPDATE_PRODUCT_CATEGORY_DATA";
export const RESET_PRODUCT_CATEGORY_DATA = "RESET_PRODUCT_CATEGORY_DATA";
export const PRODUCT_CATEGORY_TYPE_DATA = "PRODUCT_CATEGORY_TYPE_DATA";
export const FILTERED_CATEGORY_DATA = "FILTERED_CATEGORY_DATA";
export const SET_RANK = "SET_RANK";
export const RANK_ARRAY = "RANK_ARRAY";
export const FREELANCER_ARRAY = "FREELANCER_ARRAY";