import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Snackbar } from '@mui/material';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { setMessageList } from './redux/actions/message-box-action';

const handleClose = (event, reason, msg, messageList, setMessageList) => {
  if (reason === 'clickaway') {
    return;
  }

  let newArr = [...messageList];
  const currIdx = messageList.findIndex((mssg) => mssg?.id === msg?.id);
  if (currIdx !== -1) {
    newArr.splice(currIdx, 1);
    setMessageList(newArr);
  }
};

function MessageBox({
  messageList = [],
  setMessageList = () => {},
  showAction = false,
  action = (msg = {}, messageList = [], setMessageList = () => {}) => {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(event, reason) =>
            handleClose(event, reason, msg, messageList, setMessageList)
          }
        >
          <CloseIcon fontSize="small" color="#ffffff" />
        </IconButton>
      </>
    );
  },
}) {
  return (
    <div
      style={{
        // position: 'absolute',
        // top: 25,
        // right: 25,
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
      }}
    >
      {messageList.map((msg) => {
        return (
          <Snackbar
            key={msg.id}
            open={msg.open}
            autoHideDuration={6000}
            anchorOrigin={{vertical: 'top',horizontal:'right'}}
            onClose={() => {
              let newArr = [...messageList];
              const currIdx = messageList.findIndex(
                (mssg) => mssg.id === msg.id
              );
              if (currIdx !== -1) {
                newArr.splice(currIdx, 1);
                setMessageList(newArr);
              }
            }}
            message={msg.message}
            action={
              showAction ? action(msg, messageList, setMessageList) : <></>
            }
          />
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { messageList } = state.messageBox;

  return { messageList };
};

export default connect(mapStateToProps, { setMessageList })(MessageBox);

MessageBox.propTypes = {
  messageList: PropTypes.array,
  action: PropTypes.element,
  showAction: PropTypes.bool,
  setMessageList: PropTypes.func,
};
