import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import AddIcon from '../../assets/add-icon-white.svg';
import { ReactComponent as WidgetImg } from '../../assets/widget-img.svg';
import { ReactComponent as ProductImage1 } from '../../assets/product1.svg';
import { ReactComponent as TileImg1 } from '../../assets/tile-img1.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as WidgetCheckIcon } from '../../assets/widget-check-icon.svg';
import AddTilesModal from './AddTilesModal';

function CreateTiles() {
  //const navigate=useNavigate();
  const [searchText, setSearchText] = useState('');
  // const [rows, setRows] = useState(data);

  // useEffect(() => {
  //     console.log("Filtered items:", rows);
  // }, [rows]);

  // const handleSearch = (e) => {
  //     const searchTerm = e.target.value.toLowerCase();
  //     setSearchText(searchTerm, "searchValue");
  //     if (searchTerm === "") {
  //         // If search term is empty, display the original list
  //         setRows(data);
  //     } else {
  //         // Filter the list based on the search term
  //         const filteredItems = rows.filter((item) =>
  //             item.name.toLowerCase().includes(searchTerm)
  //         );
  //         setRows(filteredItems);
  //     }
  // };
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  // };

  const data = [
    {
      id: 1,
      tileName: 'Games',
      redirectTo: 'Product List Page ',
      tileSeqNo: '01',
      status: 'Active',
      editIcon: <EditIcon />,
      deleteIcon: <DeleteIcon />,
    },
    {
      id: 2,
      tileName: 'Industrial Plants',
      redirectTo: 'Product List Page ',
      tileSeqNo: '03',
      status: 'Inactive',
      editIcon: <EditIcon />,
      deleteIcon: <DeleteIcon />,
    },
    {
      id: 3,
      tileName: 'Electronics',
      redirectTo: 'Product List Page ',
      tileSeqNo: '02',
      status: 'Active',
      editIcon: <EditIcon />,
      deleteIcon: <DeleteIcon />,
    },
  ];

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const handleCardClick1 = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCardClick2 = () => {
    setIsChecked2(!isChecked2);
  };

  const handleCardClick3 = () => {
    setIsChecked3(!isChecked3);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 10 }}
            className="align-items-center justify-content-between"
          >
            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputPlanName"
              >
                Widget Name
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="inputPlanName"
                  type="text"
                  placeholder="Language"
                  required
                  autoFocus
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>

            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputProductCategory"
              >
                Banner Type
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }}>
                <Select inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="Select 1">Card</MenuItem>
                  <MenuItem value="Select 2">Tiles </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* <Box>
          <Typography className="fs-18 fw-bold font-skmodernist">
            Select Tiles
          </Typography>
          <Divider className="personal-divider mt-2 mb-3"></Divider>
          {
            <Grid
              container
              rowSpacing={2}
              columnSpacing={4}
              className="align-items-center mb-4"
            >
              <Grid item>
                <Card className="card-tiles" onClick={handleCardClick1}>
                  <CardMedia className="card-tiles-media">
                    <TileImg1 className="card-tiles-img" />
                  </CardMedia>
                  {isChecked1 && (
                    <Box className="widget-check-icon">
                      <WidgetCheckIcon />
                    </Box>
                  )}
                </Card>
                <Box className="text-center mt-1">
                  <Typography className="fs-14 fw-normal font-gotham text-darkgrey">
                    Name Here
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <Card
                  className="card-tiles"
                  sx={{ borderColor: '#864FFD' }}
                  onClick={handleCardClick2}
                >
                  <CardMedia className="card-tiles-media">
                    <TileImg1 className="card-tiles-img" />
                  </CardMedia>
                  {isChecked2 && (
                    <Box className="widget-check-icon">
                      <WidgetCheckIcon />
                    </Box>
                  )}
                </Card>
                <Box className="text-center mt-1">
                  <Typography className="fs-14 fw-normal font-gotham text-darkgrey">
                    Name Here
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <Card
                  className="card-tiles"
                  sx={{ borderColor: '#61A3F2' }}
                  onClick={handleCardClick3}
                >
                  <CardMedia className="card-tiles-media">
                    <TileImg1 className="card-tiles-img" />
                  </CardMedia>
                  {isChecked3 && (
                    <Box className="widget-check-icon">
                      <WidgetCheckIcon />
                    </Box>
                  )}
                </Card>
                <Box className="text-center mt-1">
                  <Typography className="fs-14 fw-normal font-gotham text-darkgrey">
                    Name Here
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          }

        </Box> */}

        <Box className="d-flex align-items-center mb-3">
          <Typography className="fs-18 fw-bold font-skmodernist me-3">
            Add Tile Details
          </Typography>
          <Button
            type="button"
            className="widget-add-btn"
            onClick={handleOpenModal}
            helperText=" "
          >
            <img src={AddIcon} alt="add-btn-icon" className="add-btn-icon" />
          </Button>

          <AddTilesModal open={openModal} onClose={handleCloseModal} />
        </Box>

        <Box className="d-flex justify-content-end mb-3">


        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Tile Name</TableCell>
                  <TableCell>Redirect To</TableCell>
                  <TableCell>Tile Seq Number</TableCell>
                  <TableCell sx={{ width: '80px' }}>Status</TableCell>
                  <TableCell className="text-center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <div className=" d-flex align-items-center">
                          <div className="tile-field me-3">
                            <TileImg1 className="tile-media" />
                          </div>
                          <div className="" component="div">
                            {row.tileName}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{row.redirectTo}</TableCell>
                      <TableCell>{row.tileSeqNo}</TableCell>
                      <TableCell align="center">
                        <Typography
                          className={
                            row.status === 'Active'
                              ? 'text-status-active'
                              : 'text-status-inactive'
                          }
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-evenly">
                          {row.editIcon} {row.deleteIcon}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={page}
            count={data.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

export default CreateTiles;
