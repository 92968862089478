import React from 'react'
import './product.css';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
function Product() {
  return (
    <SideBarContent> 
      <div>
      Page Under Construction
      </div>
    </SideBarContent>
  )
}

export default Product
