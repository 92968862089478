import React, { useEffect, useState } from 'react';
import { Button, Container, FormHelperText, Grid, Link, Typography, } from '@mui/material';
import "../../index.css";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetUserData, sendSigninData } from './redux/actions/otp-action';
import { OTP_MIN, OTP_SEC } from '../../utils/constants';
import { setLoading } from '../../store/actions/rootActions';
import useValidation from '../../hooks/useValidation';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL

function Otp({ setLoading, mobile_number, country_code, sendSigninData, resetUserData, pushMessage }) {
    const [otp, setOtp] = useState("");

    const [minutes, setMinutes] = useState(OTP_MIN);
    const [seconds, setSeconds] = useState(OTP_SEC);
    const { validateInput, errors } = useValidation();


    let history = useHistory();

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);


    const resendOTP = () => {
        setMinutes(OTP_MIN);
        setSeconds(OTP_SEC);
    };

    const handleChange = (newValue) => {
        validateInput('otp', newValue);
        setOtp(newValue);

    }

    const handelSubmit = (e) => {
        e.preventDefault();
        submitOTPData();
    };

    const submitOTPData = async () => {
        if (otp.length < 6) {
            alert('Please Enter Correct OTP.');
            return;

        }
        if (otp === "") {
            validateInput('otp', otp);
        }
        setLoading(true);
        let data = {
            mobile_number: mobile_number,
            country_code: country_code,
            otp: otp,
        };
        await sendSigninData(data, (data) => {
            if (data?.user?.is_active === false) {
                history.replace('login');
                resetUserData();
                pushMessage("Account is inactive. Please contact the admin.")
            }
            else {
                history.replace("/")
            }
        });
        setLoading(false);
    };

    const sendToLogin = () => {
        history.replace("/login");
    }




    return (
        <Container maxWidth="xl" className="p-0">
            <Grid container className="root" style={{ height: '100vh' }}> {/* Apply class name from CSS */}
                {/* The image half */}
                <Grid item md={7} lg={7} className="bgImage"> {/* Apply class name from CSS */}

                </Grid>

                {/* The content half */}
                <Grid item md={5} lg={5} xs={12} className="bgMain" sx={{ margin: 'auto' }}> {/* Apply class name from CSS */}
                    <div className="login">
                        {/* Demo content */}
                        <Container>
                            <Grid justifyContent="center" display={'flex'} alignContent={'center'}>

                                <Grid item xs={12} md={8} lg={8} xl={8}>
                                    <div className="mb-2">
                                        <Typography
                                            variant="h4"
                                            align="left"
                                            alignItems="center"
                                            className="fs-34 mb-0 me-2 fw-bold text-black font-skmodernist"
                                            gutterBottom>
                                            Enter code sent to your number
                                        </Typography>
                                    </div>

                                    <Typography
                                        variant="body1"
                                        align="left"
                                        className="fs-15 text-darkgrey fw-normal font-gotham"
                                        gutterBottom>
                                        We sent it to the number{' '}
                                        <span style={{ color: '#864FFD' }}>{mobile_number}</span>
                                    </Typography>

                                    <Typography className="fs-15 text-decoration-underline fw-normal font-gotham">
                                        <Link underline="hover" href="#" onClick={sendToLogin} className="text-primary">Change Mobile Number</Link>
                                    </Typography>


                                    <form style={{ marginTop: '50px' }}
                                        onSubmit={handelSubmit}>
                                        <div>
                                            <MuiOtpInput value={otp} length={6} gap={2} validateChar={(char) => !isNaN(char)} onChange={handleChange} />
                                            <FormHelperText sx={{ color: 'red' }} id="otp-helper-text">
                                                {errors.otp}
                                            </FormHelperText>
                                        </div>

                                        <div className="d-flex justify-content-between mt-4">
                                            {seconds > 0 ? <Typography
                                                variant="body1"
                                                align="left"
                                                className="fs-14 text-darkgrey fw-normal font-gotham"
                                                gutterBottom
                                            >
                                                Waiting for OTP:{' '}
                                                <span style={{ color: '#416AD4' }}>{minutes < 10 ? `0${minutes}` : minutes}:
                                                    {seconds < 10 ? `0${seconds}` : seconds}</span>
                                            </Typography> : (
                                                <Link
                                                    component="button"
                                                    variant="body1"
                                                    align="right"
                                                    underline="none"
                                                    onClick={resendOTP}
                                                    className="fs-14 fw-normal font-gotham"
                                                    gutterBottom
                                                    style={{
                                                        color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#416AD4',
                                                    }}
                                                    disabled={seconds > 0 || minutes > 0}
                                                >
                                                    Resend OTP
                                                </Link>
                                            )}


                                        </div>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                                            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}>
                                            Submit
                                        </Button>
                                    </form>

                                </Grid>

                            </Grid>

                        </Container>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}


const mapStateToProps = state => {
    const { otp,
        error,
        userData,
        postLoginRouteName } = state.signin;

    const { country_code, mobile_number } = state.mobile;
    return {
        otp,
        error,
        userData,
        postLoginRouteName,
        country_code,
        mobile_number
    }
}

export default connect(mapStateToProps, { setLoading, sendSigninData, resetUserData, pushMessage })(Otp);
