import { ALL_ROLE_FILTER, CLEAR_STATE, CREATE_NAME, CREATE_PERMISSION, CREATE_ROLE_MASTER, EDIT_ROLE_MASTER, GET_ADMIN_MODULE, GET_ROLE_MASTER, SET_ACTION_TYPE, SET_ROLE_UPDATE, SET_ROW_ID } from "../types"

const initial_state = {
    all_role : [],
    error : '',
    roleMasterObj: { name: '', permissions: []},
    all_module: [],
    action_Type: 1, // 1 is for create and 0 is for edit,
    all_role_filter: [],
    rowId: 0,
}


const roleMasterReducer = (state = initial_state, action) => {
    switch(action.type){
        case GET_ROLE_MASTER:
            return {
                ...state,
                all_role: action.payload,
                error: ''
            }
        case CREATE_ROLE_MASTER:
            return {
                ...state,
                roleMasterObj: action.payload
            }
        case CREATE_NAME:
            return {
                ...state,
                roleMasterObj: {
                    ...state.roleMasterObj,
                    name: action.payload
                }
            }
        case CREATE_PERMISSION:
            return {
                ...state,
                roleMasterObj:{
                    ...state.roleMasterObj,
                    permissions: action.payload
                }
            }
        case EDIT_ROLE_MASTER:
            return {
                ...state,
                roleMasterObj: action.payload
            }
        case GET_ADMIN_MODULE:
            return {
                ...state,
                all_module: action.payload
            }
        case CLEAR_STATE:
            return initial_state

        case SET_ACTION_TYPE:
            return {
                ...state,
                action_Type: action.payload
            }
        case ALL_ROLE_FILTER:
            return {
                ...state,
                all_role_filter: action.payload
            }
        case SET_ROW_ID:
            return {
                ...state,
                rowId : action.payload
            }
        default: 
            return state
    }

}

export default roleMasterReducer;
