import React from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, Divider, Grid, InputLabel, Typography } from "@mui/material";
// import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import CustomPagination from "../../component/CustomPagination/CustomPagination";
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import { ReactComponent as ProductImage1 } from "../../assets/product1.svg";
import { ReactComponent as InfoBtn } from "../../assets/info-btn.svg";
import Popover from '@mui/material/Popover';
// import B2BRejectModal from "./B2BRejectModal";




function B2BProfileViewReason() {
    //const navigate=useNavigate();
    // const [searchText, setSearchText] = useState("");
    // const [rows, setRows] = useState(data);





    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;





    return (
        <SideBarContent>
            <Box className="custom-card-bg">
                <Box className="mb-4">
                    <Box className="d-flex justify-content-between mb-4 align-items-center">
                        <div className="ribbon">
                            {/* <a className="rejected" href="#">Rejected</a> */}
                            <a className="approved" href="#">Approved</a>
                            {/* <a className="pending" href="#">Pending</a> */}
                        </div>
                        <Button className="btn-info" aria-describedby={id} variant="contained" onClick={handleClick}>
                            <InfoBtn />
                        </Button>

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            
                        >
                            <Box sx={{ p: 2 }}>
                            <Typography className="fs-16 fw-normal font-gotham text-black">Rejected Reason</Typography>
                            <Typography className="fs-16 fw-normal font-gotham text-grey">Inappropriate content</Typography>
                            </Box>
                        </Popover>

                    </Box>
                    <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 2 }} className="align-items-center justify-content-between mb-4">
                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">Name</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">Varsha Mali</Typography>
                        </Grid>
                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">Product Category</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">FMCG, Electronics</Typography>
                        </Grid>

                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">Mobile No.</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">9542568793</Typography>
                        </Grid>
                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">GSTIN</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">GS00005400</Typography>
                        </Grid>

                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">Email</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">mukta@abc.com</Typography>
                        </Grid>

                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">PAN</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">M52200002</Typography>
                        </Grid>
                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">Company Name</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">e.Soft</Typography>
                        </Grid>

                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">CIN</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">CI0054000</Typography>
                        </Grid>
                        <Grid item xs={10} md={6} className="d-flex justify-content-between">
                            <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">About Company</InputLabel>
                            <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">Services Provider</Typography>
                        </Grid>


                    </Grid>

                    <Typography className="fs-20 fw-bold text-black font-skmodernist">Catalog Details</Typography>
                    <Divider className="main-divider my-3"></Divider>

                    <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: "450px" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Service Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => ( */}
                                    <TableRow>
                                        <TableCell >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="w-50 d-flex">
                                                    <div className="me-3">
                                                        <ProductImage1 className="media" />
                                                    </div>
                                                    <div className="">
                                                        <Typography variant="h5"
                                                            className="fs-16 text-black mb-2 fw-normal font-skmodernist"
                                                            component="div">Light Fixtures</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            className="fs-14 text-grey mb-2 font-gotham"
                                                        >
                                                            Best in class, stylish light fixtures.
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="w-50">
                                                    <Typography
                                                        variant="h5"
                                                        className="fs-13 text-darkgrey mb-0 font-skmodernist"
                                                    >
                                                        Starting at
                                                    </Typography>

                                                    <Typography
                                                        variant="h5"
                                                        className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                                                    >
                                                        ₹ 135/
                                                        <span className="fs-13 text-grey fw-light">
                                                            Piece
                                                        </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </TableCell>

                                    </TableRow>
                                    {/* ))} */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <CustomPagination page={page} count={data.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} /> */}
                    </Paper>


                </Box>



            </Box>
        </SideBarContent>
    );
}

export default B2BProfileViewReason;
