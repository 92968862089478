import api from './configs/axiosConfig';

const miscMaster = '/admin/modules';

const modDetailsByAdminId = '/moduleDetails/miscellaneous/';

const createMisc = '/moduleDetails/miscellaneous';

const headerConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const MiscellaneousAPI = {
  getMisc: async function () {
    const response = await api.get(miscMaster);
    return response;
  },
  getSingleMisc: async function (id) {
    const response = await api.get(modDetailsByAdminId + id);
    return response;
  },
  createMisc: async function (data) {
    const response = await api.post(createMisc, data, headerConfig);
    return response;
  },
  editMisc: async function (id) {
    const response = await api.get(modDetailsByAdminId + id);
    return response;
  },
  putMisc: async function (data) {
    const response = await api.put(createMisc, data, headerConfig);
    return response;
  },
};
