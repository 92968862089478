export const GET_ROLE_MASTER = 'GET_ROLE_MASTER';
export const CREATE_ROLE_MASTER = 'CREATE_ROLE_MASTER';
export const EDIT_ROLE_MASTER = 'EDIT_ROLE_MASTER';
export const CREATE_NAME = 'CREATE_NAME';
export const CREATE_PERMISSION = 'CREATE_PERMISSION';
export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_ACTION_TYPE = 'SET_ACTION_TYPE';
export const SET_ROLE_PERMISSION_UPDATE = 'SET_ROLE_PERMISSION_UPDATE';
export const ALL_ROLE_FILTER = 'ALL_ROLE_FILTER';
export const SET_ROW_ID = 'SET_ROW_ID'

//For admin module
export const GET_ADMIN_MODULE = 'GET_ADMIN_MODULE';