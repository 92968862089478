import { TEMPLATE_TYPE } from "../utils/constants";
import api from "./configs/axiosConfig";

const TemplateMaster = '/page/';

const templateTypeStatus = '/moduleDetails/miscellaneous/searchByName/' + TEMPLATE_TYPE;


export const TemplateAPI = {
    getTemplateData: async function(){
        const response = await api.get(TemplateMaster);
        return response;
    },
    postTemplateData: async function(data, header){
        const response = await api.post(TemplateMaster, data, header);
        return response;
    },
    updateTemplateData: async function(id, data, header){
        const response = await api.put(TemplateMaster + id, data, header);
        return response;
    },
    getTemplateTypeData: async function () {
        const response = await api.get(templateTypeStatus);
        return response;
      },
}