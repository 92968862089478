import api from "./configs/axiosConfig";

const RoleMaster = '/roles/';

// const AdminModule = '/admin/modules';
const AdminModule = '/systemmodules/';


export const RoleMasterAPI = {
    getRole: async function(){
        const response = await api.get(RoleMaster);
        return response
    },

    createRole: async function(data,header){
        const response = await api.post(RoleMaster,data,header);
        return response
    },

    assignModule: async function(){
        const response = await api.get(AdminModule);
        return response;
    },

    updateRole: async function(id, data, header){
        const response = await api.put(RoleMaster + id, data, header);
        return response;
    }
}