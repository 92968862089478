import axios from "axios";
import { resetUserData } from "../../pages/Otp/redux/actions/otp-action";

// export const BASE_URL = 'http://172.20.2.38:3000';
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const UPLOAD_BASE_URL = process.env.REACT_APP_UPLOAD_BASE_URL
export const SOCIAL_MEDIA_URL = UPLOAD_BASE_URL + '/uploads/social/social_images/'
export const PRODUCT_CATEGORY_URL = UPLOAD_BASE_URL + '/uploads/category/category_logo/'
export const RESUME_BASE_URL = `${UPLOAD_BASE_URL}/uploads/freelancer/resumes/`;
export const BANNER_BASE_URL = `${UPLOAD_BASE_URL}/uploads/banner/banner_images/`;

const api = axios.create({
    baseURL: BASE_URL,
});

let store;

export const injectStore = (_store) => {
    store = _store;
}

api.interceptors.request.use(
    (config) => {
        // const token= localStorage.getItem('token');
        const token = store?.getState()?.signin?.userData?.accessToken;
        if (token) {
            config.headers['x-access-token'] = token
            // config.headers['x-access-token']="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTYsImlhdCI6MTcwODk1MDA2MSwiZXhwIjoxNzA5MDM2NDYxfQ.V6p1LWJoQkFgvpA1F0fC2KMqt4ydiFKNkatqFd2gKU8";
        }
        return config;
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response?.status === 401) {
            // Token expired or invalid
            // Perform logout action here
            store?.dispatch(resetUserData()); // Dispatch action to logout user
        }
        return Promise.reject(error)
    }
)

export default api;