import moment from "moment";
import { UserMasterAPI } from "../../../../apis/UserMasterAPI";
import { SET_LOADING } from "../../../../store/types";
import { ALL_USER_FILTER, CLEAR_USER_DATA, GET_USER_DATA, POST_USER_DATA, SET_USER_ACTION_TYPE, UPDATE_ROLE_DATA, USER_COUNTRY_CODE, USER_COUNTRY_CODE_NAME, USER_MOBILE_NO } from "../types";


export const fetchUser = () => {
    return async(dispatch) => { 
        dispatch({type: SET_LOADING,payload:true})
        try {
            const response = await UserMasterAPI.getUserMaster();
            if (response.status === 200){
                const sortedArray = response?.data?.data?.sort((a,b)=> moment(b.updatedAt) - moment(a.updatedAt))
               dispatch({type: GET_USER_DATA, payload: sortedArray})
               dispatch({type: SET_LOADING, payload: false});
            }else{
                dispatch({type: SET_LOADING, payload: false});
            }
    
        } catch (error) {
            dispatch({type: SET_LOADING, payload: false});
            console.log("error", error);
        }  
    }

}

export const addUserMaster = (data, header,successCallback=() => {}, errorCallback=() => {}) => {
    return async(dispatch) => {
        dispatch({type: SET_LOADING, payload: true})
        try {
            const response = await UserMasterAPI.postUserMaster(data, header);
            if (response.status === 201){
                successCallback(response.data)
                dispatch({ type: SET_LOADING, payload: false });
            } else{
                dispatch({type: SET_LOADING,payload: false});
                errorCallback(response.data)
                
            }
        } catch (error) {
            dispatch({type: SET_LOADING,payload: false});
            if(error.response.status===401){
                errorCallback(error?.response?.data?.message);  
            }
            else{
                errorCallback(error?.response?.data?.error); 
            };

        }
    }
}


export const editUserMaster = (id, data, header,successCallback=() => {}, errorCallback=() => {}) => {
    return async(dispatch) => {
        dispatch({type: SET_LOADING, payload: true})
        try {
            const response = await UserMasterAPI.updateUserMaster(id, data, header);
            if (response.status === 200){
                successCallback(response.data)
                dispatch({ type: SET_LOADING, payload: false })
            } else{
                dispatch({type: SET_LOADING,payload: false});
                errorCallback(response.data)
            }
        } catch (error) {
            dispatch({type: SET_LOADING,payload: false});
            if(error.response.status===401){
                errorCallback(error?.response?.data?.message);  
            }
            else{
                errorCallback(error?.response?.data?.error); 
            }
            // errorCallback(error?.response?.data.error);
        }
    }
}


export const setUserMasterData = (insertData) => {
    return async(dispatch) => {
        dispatch({type: POST_USER_DATA, payload:insertData})
    }
}


export const setUserActionType = (data) => {
    return async(dispatch) => {
        dispatch({type: SET_USER_ACTION_TYPE, payload: data})
    }
}


export const setAllUserFilter = (filter) => {
    return async(dispatch) => {
        dispatch({type: ALL_USER_FILTER, payload: filter})
    }
}

export const setClearUserData = () => {
    return async(dispatch) => {
        dispatch({type: CLEAR_USER_DATA})
    }
}

export const setUpdateRolesData = (updated_roles) => {
    return async(dispatch) => {
        dispatch({type: UPDATE_ROLE_DATA, payload: updated_roles})
    }
}

export const saveUserMobileNo = (mobile_no) => {
    return async (dispatch) => {
      try {
        await dispatch({
          type: USER_MOBILE_NO,
          payload: mobile_no,
        });
      } catch (error) {
        console.log('Error saving mobile_no', error);
      }
    };
  };

  export const saveUserCountryCode = (code) => {
    return async (dispatch) => {
      try {
        await dispatch({
          type: USER_COUNTRY_CODE,
          payload: code,
        });
      } catch (error) {
        console.log('Error saving country_code', error);
      }
    };
  };

  export const saveUserCountryCodeName = (code) => {
    return async (dispatch) => {
      try {
        await dispatch({
          type: USER_COUNTRY_CODE_NAME,
          payload: code,
        });
      } catch (error) {
        console.log('Error saving country_code_name', error);
      }
    };
  };