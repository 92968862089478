import { FormHelperText } from '@mui/material';
import React from 'react';

export default function ErrorFormHelperText({ children }) {
  return (
    <FormHelperText
      sx={{ color: 'red', height: 10, marginBottom: 3 }}
      id="master-helper-text"
    >
      {children}
    </FormHelperText>
  );
}
