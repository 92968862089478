import api from './configs/axiosConfig';
import {
  GET_VENDORS,
  GET_VENDORS_DETAILS,
  UPDATE_VENDORS_DETAILS,
  DELETE_USER,
} from './endpoints';
export const Vendors = {
  GetVendors: async function (type) {
    const response = await api.get(`${GET_VENDORS}/${type}`);
    return response;
  },
  GetVendorDetail: async function (reg_id) {
    const response = await api.get(`${GET_VENDORS_DETAILS}/${reg_id}`);
    return response;
  },
  updateVendorStatus: async function (reg_id, data) {
    const response = await api.put(`${UPDATE_VENDORS_DETAILS}/${reg_id}`, data);
    return response;
  },
  deleteVendor: async function (user_id) {
    const response = await api.delete(`${DELETE_USER}/${user_id}`);
    return response;
  },
};
