import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextareaAutosize } from '@mui/material';
import AddBtnIcon from '../../assets/add-btn-icon.svg';



const CustomModal = ({ open, onClose, handleSave, reason, setReason }) => {


  const handleCancel = () => {
    onClose(true); // Close the modal
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="custom-border-header">Reject Reason</DialogTitle>
      <DialogContent>

        <div className="col-md-12 mb-2">
          <InputLabel className="text-start fs-14 text-black font-skmodernist" htmlFor="inputPlanName">Reason</InputLabel>
          <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
            <TextareaAutosize
              id="inputAdditionalInfo"
              placeholder="Enter Reason"
              onChange={r => setReason(r.target.value)}
              minRows={3} // You can adjust the number of rows as needed
              maxRows={2} // You can adjust the number of rows as needed
            // value={reason}
            />
          </FormControl>
        </div>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} className="btn-cancel me-2">
          Cancel
        </Button>
        <Button onClick={handleSave} className="btn-save">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
