import React, { useEffect, useState } from "react";
import CommonTable from "../../component/CommonTable";
import { Box, Button, Grid, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import CreatePlanModal from "./CreatePlanModal";
import SideBarContent from "../../component/SideBarContent/SideBarContent";
// import CommonButton from "../../component/CommonButton";


const columns = [
  { id: "name", label: "Name", minWidth: 170, class: "plans" },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size\u00a0(km\u00b2)",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Density",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const data = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

function Plans() {
  //const navigate=useNavigate();
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(data);
  const handelButtonClick = () => {
    console.log("Plan call");
  };

  useEffect(() => {
    console.log("Filtered items:", rows);
  }, [rows]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchText(searchTerm, "searchValue");
    if (searchTerm === "") {
      // If search term is empty, display the original list
      setRows(data);
    } else {
      // Filter the list based on the search term
      const filteredItems = rows.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      );
      setRows(filteredItems);
    }
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <SideBarContent>
        <Box className="custom-card-bg">
          <Box className="mb-3">
            <Grid container spacing={2} className="align-items-center just-content-between">
              <Grid item xs={10} md={6} >
                <TextField
                  sx={{ height: "50px", width: '400px',borderRadius: '6px' }}
                  variant="outlined"
                  placeholder="Search here..."
                  value={searchText}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <Search
                        sx={{ width: "1em", height: "1em", marginRight: "8px", color: '#864FFD' }}
                        color="action"
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={"auto"} className="ms-auto">
                <Button className="btn-create"  onClick={handleOpenModal}>
                  Create
                </Button>
                <CreatePlanModal open={openModal} onClose={handleCloseModal} />
              </Grid>
            </Grid>
          </Box>

          <CommonTable rows={rows} columns={columns} />
        </Box>
    </SideBarContent>
  );
}

export default Plans;
