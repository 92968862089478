import React, { useEffect, useState } from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, Grid, Pagination, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import SocialMediaMasterModal from "./SocialMediaMasterModal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
import { connect } from 'react-redux';
import { SocialMediaAPI } from "../../apis/SocialMediaAPI";
import { fetchSocialMedia, setSocialActionType, setSocialMediaFilter, setsocialMediaObj } from "./redux/actions/social-media-action";
import { SOCIAL_MEDIA_URL } from "../../apis/configs/axiosConfig";
import Progress from "../../component/CommonLoading";
import SideBarContent from "../../component/SideBarContent/SideBarContent";






function SocialMediaMaster({ all_links, fetchSocialMedia, setSocialActionType, setsocialMediaObj, all_link_filter, setSocialMediaFilter, loading }) {
    const [searchText, setSearchText] = useState("");


    useEffect(() => {
        fetchSocialMedia()
    }, []);

    useEffect(()=> {
        if (searchText === "") {
            // If search term is empty, display the original list
            setSocialMediaFilter(all_links);
        } else {
            // Filter the list based on the search term
            const filteredItems = all_links.filter((item) =>
                item.media_name.toLowerCase().includes(searchText.toLowerCase().trim())
            );
            setSocialMediaFilter(filteredItems);
        }

    },[searchText, all_links])

    const handleSearch = (e) => {
        const searchTerm = e.target.value.replace(/\s\s+/g, ' ')
        setSearchText(searchTerm, "searchValue");
        
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
    

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };


    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
        setSocialActionType(1)
    };

    const handleEditModal = () => {
        setOpenModal(true);
        setSocialActionType(0)
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <SideBarContent>
            <Box className="custom-card-bg">
            
                <Box className="mb-4">
                    <Grid container spacing={2} className="align-items-center just-content-between">
                        <Grid item xs={10} md={6} >
                            <TextField
                                sx={{ height: "50px", width: '400px', borderRadius: '6px' }}
                                variant="outlined"
                                placeholder="Search here..."
                                value={searchText}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <Search
                                            sx={{ width: "1em", height: "1em", marginRight: "8px", color: '#864FFD' }}
                                            color="action"
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={"auto"} className="ms-auto">
                            <Button className="btn-create" onClick={handleOpenModal}>
                                Create
                            </Button>
                            <SocialMediaMasterModal open={openModal} onClose={handleCloseModal} />
                        </Grid>
                    </Grid>
                </Box>

                <Paper sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: "450px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Social Media Links</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                 {!loading && all_link_filter?.length > 0 && all_link_filter?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <img src={SOCIAL_MEDIA_URL + row.media_image_path} height={'30px'} width={'30px'} /> {row.media_name}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography className={row.is_active ? 'text-status-active' : 'text-status-inactive'}>{row.is_active ? 'Active' : 'Inactive'}</Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button onClick={() => {
                                                console.log(' Social Media Item' , row);
                                                setsocialMediaObj(row)
                                                handleEditModal()
                                            }}> <EditIcon /></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <CustomPagination page={page} count={all_link_filter?.length || 0} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
                </Paper>
            </Box>
        </SideBarContent>
    );
}

const mapStateToProps = (state) => {
    const { loading, all_links, error, all_link_filter } = state.socialmedia;
    return {
        loading,
        all_links,
        error,
        all_link_filter
    }
}


export default connect(mapStateToProps, { fetchSocialMedia, setSocialActionType, setsocialMediaObj, setSocialMediaFilter })(SocialMediaMaster);
