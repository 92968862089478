import { ADD_CITY_FAILURE, ADD_CITY_REQUEST, ADD_CITY_SUCCESS, RESET_CITY_DATA } from "../types"

const initialState={
    loading:false,
    name:'',
    error:''
}

const cityReducer=(state=initialState,action)=>{
    switch(action.type){
        case ADD_CITY_REQUEST :
            return{
                ...state,
                loading: true,
                error:''
            }
        case ADD_CITY_SUCCESS:
            return{
                ...state,
                loading:false,
                name:action.payload,
                error:''
            }
        case ADD_CITY_FAILURE :
            return{
                ...state,
                loading:false,
                name:'',
                error:action.payload
            }
        case RESET_CITY_DATA:
            return initialState
        default:
            return state

    }
}

export default cityReducer;