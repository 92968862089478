import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import { useHistory } from 'react-router-dom';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import {
  getAllWidget,
  getWidgetDetails,
  resetWidgetDetails,
  saveSelectedWidgetDetails,
  saveWidget,
} from './redux/actions/widget-action';
import moment from 'moment/moment';

function WidgetMaster({
  allWidget,
  getWidgetDetails,
  getAllWidget,
  saveWidget,
  saveSelectedWidgetDetails,
  resetWidgetDetails,
}) {
  const [widgets, setWidgets] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [filteredWidgets, setFilteredWidgets] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getAllWidget();
  }, [getAllWidget]);

  useEffect(() => {
    setWidgets(allWidget);
  }, [allWidget]);

  useEffect(() => {
    const filtered = widgets.filter(
      (widget) =>
        !searchText ||
        widget.widget_name
          .toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        !searchText ||
        widget.widget_type
          .toLowerCase()
          .includes(searchText.toLowerCase().trim())
    );
    setFilteredWidgets(filtered);
  }, [searchText, widgets]);

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

  const saveWidgetToStore = async (widget) => {
    saveWidget(widget);
    await getWidgetDetails(widget.id);
    history.push('/create-widget', { isFromEditPage: true });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleCreateButtonClick = () => {
    resetWidgetDetails();
    saveSelectedWidgetDetails(null);
    history.push('/create-widget');
  };

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="align-items-center just-content-between"
          >
            <Grid item xs={10} md={6}>
              <TextField
                sx={{
                  height: '50px',
                  width: '400px',
                  borderRadius: '6px',
                  marginLeft: '10px',
                }}
                variant="outlined"
                placeholder="Search by Widget Name Or Type"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <Search
                      sx={{
                        width: '1em',
                        height: '1em',
                        marginRight: '8px',
                        color: '#864FFD',
                      }}
                      color="action"
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={'auto'} className="ms-auto">
              <Button className="btn-create" onClick={handleCreateButtonClick}>
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Created At</TableCell>
                  <TableCell>Widget Name</TableCell>
                  <TableCell>Widget Type</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredWidgets
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        {moment(row.createdAt).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>{row.widget_name}</TableCell>
                      <TableCell>{row.widget_type}</TableCell>
                      <TableCell align="center">
                        <Typography
                          className={
                            row.is_active
                              ? 'text-status-active'
                              : 'text-status-inactive'
                          }
                        >
                          {!!row.is_active ? 'Active' : 'Inactive'}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => saveWidgetToStore(row)}>
                          <EditIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={page}
            count={filteredWidgets.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = ({ createWidget }) => ({
  allWidget: createWidget?.allWidget || [],
  single_widget: createWidget?.single_widget || [],
});

export default connect(mapStateToProps, {
  getAllWidget,
  saveWidget,
  getWidgetDetails,
  saveSelectedWidgetDetails,
  resetWidgetDetails,
})(WidgetMaster);
