import { BASE_URL, UPLOAD_BASE_URL } from './configs/axiosConfig';
export const GET_VENDORS = '/admin/vendors';
export const DELETE_USER = '/users';
export const GET_VENDORS_DETAILS = '/admin/vendorDetails';
export const UPDATE_VENDORS_DETAILS = '/registration';
export const PRODUCT_IMAGE_BASE_URL = `${UPLOAD_BASE_URL}/uploads/products/products_images/`;
export const POST_WIDGET = `${BASE_URL}/widgets/single`;
export const PUT_WIDGET = `${BASE_URL}/widgetDetails/`;
export const PUT_WIDGET_DETAILS = `${BASE_URL}/widgetDetails/`;
export const POST_WIDGET_DETAILS = `${BASE_URL}/widgetDetails`;
export const ALL_WIDGET = `${BASE_URL}/widgets`;
export const GET_WIDGET_DETAILS = `${BASE_URL}/widgetDetails/search/widgetId/`;
export const IMAGE_BASE_URL = `${UPLOAD_BASE_URL}/uploads/widget/widget_images/`;
// /api/admin/vendorDetails/
export const GET_ALL_WEB_COMPONENT = '/webcomponents';
export const PUT_SINGLE_WIDGET = '/widgets/';
