import { EDIT_ENQUIRY_DATA, GET_ENQUIRY_DATA, SET_CLEAR_DATA, SET_ENQUIRY_FILTER, STATUS_DATA } from "../types";

const initial_state = {
    all_enquiry: [],
    enquiryObj: {},
    all_enquiry_filter: [],
    status_data: {}
}


const enquiryListData = (state=initial_state, action) => {
    switch(action.type){
        case GET_ENQUIRY_DATA:
            return{
                ...state,
                all_enquiry: action.payload
            }
        case EDIT_ENQUIRY_DATA:
            return{
                ...state,
                enquiryObj: action.payload
            }
        case SET_CLEAR_DATA:
            return{
                ...state,
                enquiryObj: initial_state.enquiryObj
            }
        case SET_ENQUIRY_FILTER:
            return{
                ...state,
                all_enquiry_filter: action.payload
            }
        case STATUS_DATA:
            return {
                ...state,
                status_data: action.payload
            }
        default: 
            return state
    }
}




export default enquiryListData;