import api from './configs/axiosConfig';
import {
  POST_WIDGET,
  POST_WIDGET_DETAILS,
  ALL_WIDGET,
  GET_WIDGET_DETAILS,
  PUT_WIDGET,
  GET_ALL_WEB_COMPONENT,
  PUT_SINGLE_WIDGET,
} from './endpoints';
export const Widget = {
  postWidget: async function (widget) {
    const response = await api.post(`${POST_WIDGET}`, widget);
    return response;
  },
  editWidget: async function (id, widgetData) {
    const response = await api.put(`${PUT_WIDGET}${id}`, widgetData);
    return response;
  },
  deleteWidget: async function (id) {
    const response = await api.delete(`${PUT_WIDGET}${id}`);
    return response;
  },
  postWidgetDetails: async function (widget_details) {
    const response = await api.post(`${POST_WIDGET_DETAILS}`, widget_details);
    return response;
  },
  editWidgetDetails: async function (widget_details, id) {
    const response = await api.put(`${PUT_SINGLE_WIDGET}${id}`, widget_details);
    return response;
  },
  getWebComponent: async function () {
    const response = await api.get(GET_ALL_WEB_COMPONENT);
    return response;
  },
  getAllWidget: async function () {
    const response = await api.get(`${ALL_WIDGET}`);
    return response;
  },
  getWidgetDetails: async function (id) {
    const response = await api.get(`${GET_WIDGET_DETAILS}${id}`);
    return response;
  },
};
