import {
  POST_CREATED_WIDGET,
  ADD_SINGLE_WIDGET,
  GET_ALL_WIDGET,
  ADD_WIDGET,
  GET_WIDGET_DETAILS,
  SET_SELECTED_WIDGET,
  CLEAR_SINGLE_WIDGET,
  ADD_SINGLE_MODULE_WIDGET,
  ADD_WEBCOMPONENT,
} from '../types';

const initial_state = {
  widget: [],
  allWidget: null,
  single_widget: {},
  single_module_widget: {},
  single_widget_details: {},
  selected_widget_details: {},
  webComponent: [],
};

const widgetReducer = (state = initial_state, action) => {
  switch (action.type) {
    case POST_CREATED_WIDGET:
      return {
        ...state,
        widget: action.payload,
      };
    case SET_SELECTED_WIDGET: {
      // console.log('===SET_SELECTED_WIDGET action.payload', action.payload);
      return {
        ...state,
        selected_widget_details: action.payload,
      };
    }
    case ADD_SINGLE_WIDGET: {
      const ret = {
        ...state,
        single_widget: { ...state.single_widget, ...action.payload },
      };
      // console.log('ADD_SINGLE_WIDGET', ret);
      return ret;
    }
    case ADD_WEBCOMPONENT: {
      const ret = {
        ...state,
        webComponent: action.payload,
      };
      // console.log('ADD_SINGLE_WIDGET', ret);
      return ret;
    }
    case ADD_SINGLE_MODULE_WIDGET: {
      const ret = {
        ...state,
        single_module_widget: {
          ...state.single_module_widget,
          ...action.payload,
        },
      };
      // console.log('ADD_SINGLE_WIDGET', ret);
      return ret;
    }
    case CLEAR_SINGLE_WIDGET: {
      const ret = {
        ...state,
        single_module_widget: null,
      };
      console.log('====CLEAR_SINGLE_WIDGET', ret);
      return ret;
    }

    case ADD_WIDGET: {
      const r = {
        ...state,
        single_widget: { ...action.payload },
      };
      return r;
    }
    case GET_WIDGET_DETAILS: {
      const r = {
        ...state,
        single_widget_details: action.payload,
      };
      return r;
    }
    case GET_ALL_WIDGET:
      return {
        ...state,
        allWidget: [...action.payload],
      };
    default:
      return state;
  }
};

export default widgetReducer;
