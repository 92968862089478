import api from "./configs/axiosConfig";

const login = '/auth/signin';

const check_access = '/auth/checkUser'


export const Login = {
    userLogin: async function(data){
        const response = await api.post(login,data);
        return response;
    },
    userCheckAccess: async function(data){
        const response = await api.post(check_access,data);
        return response;
    }
}

