import api from "./configs/axiosConfig";

const product_category = "/categories"

const put_product_category = "/categories/"

const headerConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}


export const ProductCategoryAPI = {
    getCategory: async function() {
        const response = await api.get(product_category)
        return response
    },
    postCategory: async function(data){
        const response = await api.post(product_category, data, headerConfig);
        return response;
    },
    editCategory: async function(id, data){
        const response = await api.put(put_product_category + id, data, headerConfig);
        return response;
    }
}

