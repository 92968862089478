import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Pagination,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import {
  clearRoleState,
  createRoleMaster,
  getAssignModule,
  getName,
  getPermission,
  setEditModuleData,
  updateRoleMaster,
} from './redux/actions/role-action';
import { useHistory } from 'react-router';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import useValidation from '../../hooks/useValidation';

function CreateRole({
  roleMasterObj,
  all_module,
  setEditModuleData,
  getName,
  getPermission,
  createRoleMaster,
  clearRoleState,
  action_Type,
  setRoleUpdateState,
  all_role,
  updateRoleMaster,
  rowId,
  pushMessage,
}) {
  //const navigate=useNavigate();
  const [searchText, setSearchText] = useState('');
  const [alreadyExist, setAlreadyExist] = useState(false);
  const { validateInput, errors, setErrors } = useValidation();
  // const [rows, setRows] = useState(data);

  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const [data, setData] = useState(all_module);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (data.every((row) => row.is_active === true)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [data]);

  useEffect(() => {
    console.log('all_modules_data :', all_module);
  }, []);

  const handleMasterCheckboxChange = () => {
    let newData = [];
    if (selectAll) {
      newData = data.map((itm) => {
        return {
          ...itm,
          is_active: false,
        };
      });
      setSelectAll(false);
      getPermission([]);
    } else {
      newData = data.map((itm) => {
        return {
          ...itm,
          is_active: true,
        };
      });
      setSelectAll(true);
      getPermission(newData);
    }

    validateInput('check', newData?.length);

    setData(newData);
    setEditModuleData(newData);
  };

  const handleCheckboxChange = (row) => {
    // console.log('change id', row.id);
    let newData = [...data];
    let selectedPermissionsArr = [...roleMasterObj.permissions];
    if (!row.is_active) {
      selectedPermissionsArr.push(row);
    } else {
      const rIdx = selectedPermissionsArr.findIndex((itm) => itm.id === row.id);
      if (rIdx !== -1) selectedPermissionsArr.splice(rIdx, 1);
    }
    getPermission(selectedPermissionsArr);
    validateInput('check', selectedPermissionsArr?.length);
    const idx = data.findIndex((itm) => itm.id === row.id);
    if (idx !== -1) {
      // console.log('idx', idx);
      newData[idx].is_active = !row.is_active;
    }
    console.log('newData', newData);
    setData(newData);
    setEditModuleData(newData);
  };

  const handleInputRole = (e) => {
    const role_name = e.target.value;
    console.log('role anme', role_name);
    if (role_name?.toLowerCase() === 'business') {
      setErrors(prevErrors => ({
        ...prevErrors,
        role: 'Cannot create with this role name',
      }));


    } else if (role_name?.toLowerCase() === 'freelancer') {
      setErrors(prevErrors => ({
        ...prevErrors,
        role: 'Cannot create with this role name',
      }));
    }
    else if (role_name?.toLowerCase() === 'b2c') {
      setErrors(prevErrors => ({
        ...prevErrors,
        role: 'Cannot create with this role name',
      }));
    }
    else {
      validateInput("role", role_name)
    }
    // validateInput("role", role_name)
    getName(role_name);
  };

  useEffect(() => {
    if (action_Type === 1) {
      for (let i = 0; i < all_role.length; i++) {
        if (
          all_role[i]?.name?.toLowerCase() ===
          roleMasterObj?.name?.toLowerCase()
        ) {
          setAlreadyExist(true);
          return;
        } else {
          setAlreadyExist(false);
        }
      }
    }
  }, [all_role, roleMasterObj?.name]);


  const handleSave = async () => {
    if (action_Type === 1) {
      const permission_data = roleMasterObj?.permissions;
      let permission_str = "";
      for (const permission of permission_data) {
        permission_str += "," + permission.id
      }
      if (roleMasterObj?.name?.toLowerCase() === 'business') {
        setErrors(prevErrors => ({
          ...prevErrors,
          role: 'Cannot create with this role name',
        }));
        return;


      } else if (roleMasterObj?.name?.toLowerCase() === 'freelancer') {
        setErrors(prevErrors => ({
          ...prevErrors,
          role: 'Cannot create with this role name',
        }));
        return;
      }
      else if (roleMasterObj?.name?.toLowerCase() === 'b2c') {
        setErrors(prevErrors => ({
          ...prevErrors,
          role: 'Cannot create with this role name',
        }));
        return;
      }
      else {
        validateInput("role", roleMasterObj?.name)
      }
      if (roleMasterObj?.name === '') {
        validateInput("role", roleMasterObj?.name)
        // alert("please enter role");
        return
      }

      if (permission_str === "") {
        // alert("Please add role checkbox");
        validateInput("check", permission_str)
        return
      }

      if (alreadyExist){
        return;
      }

      const jsonData = JSON.stringify({
        name: roleMasterObj?.name,
        permissions: permission_str?.substring(1),
      });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      await createRoleMaster(jsonData, customConfig, () => {
        pushMessage("Created Successfully")
        history.replace('/role-master');
      });
    }
    else {
      const permission_data = all_module;
      console.log("permission_data for update:", permission_data);
      let permission_str = "";
      for (const permission of permission_data) {
        if (permission.is_active === true) {
          permission_str += "," + permission.id
        }

      }
      if (roleMasterObj?.name === '') {
        validateInput("role", roleMasterObj?.name)
        return
      }
      if (permission_str === "") {
        validateInput("check", permission_str)
        return
      }

      const jsonData = JSON.stringify({
        name: roleMasterObj?.name,
        permissions: permission_str?.substring(1),
        // permissions: all_module,
      });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      await updateRoleMaster(rowId, jsonData, customConfig, () => {
        pushMessage("Updated Successfully");
        history.replace('/role-master');
      })

    }
  };

  const handleClearState = () => {
    clearRoleState();
    history.replace('/role-master');
  };

  // useEffect(() => {
  //     return () => {
  //         clearRoleState();
  //     }
  // },[])


  const handleBackButton = () => {
    clearRoleState();
    history.replace('/role-master')
  }

  // useEffect(() => {
  //     return () => {
  //         clearRoleState();
  //     }
  // },[])
  function getCheckedPermission(row) {
    return row?.is_active ? true : false;
  }


  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="align-items-center just-content-between"
          >
            <Grid item xs={10} md={5}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputPlanName"
              >
                Role Name
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="inputPlanName"
                  type="text"
                  disabled={action_Type === 0}
                  value={roleMasterObj?.name}
                  placeholder=""
                  required
                  autoFocus
                  fullWidth
                  margin="none"
                  onChange={handleInputRole}
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="role-helper-text">
                {errors.role}
              </FormHelperText>
              {alreadyExist && (
                <div style={{ color: 'red' }}>{'Role Already Exist'}</div>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box className="mb-3">
          <Typography className="fs-20 fw-bold font-skmodernist text-dark">
            Assign Modules
          </Typography>
        </Box>
        <FormHelperText sx={{ color: 'red' }} id="checkbox-helper-text">
          {errors.check}
        </FormHelperText>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '80px' }}>
                    <Checkbox
                      checked={selectAll}
                      onChange={handleMasterCheckboxChange}
                      color="primary"
                      sx={{
                        color: '#D4D4D4',
                        '&.Mui-checked': {
                          color: '#416AD4',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="start">Module Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row?.id}>
                        <TableCell>
                          <Checkbox
                            checked={getCheckedPermission(row)}
                            onChange={() => handleCheckboxChange(row)}
                            color="primary"
                            sx={{
                              color: '#D4D4D4',
                              '&.Mui-checked': {
                                color: '#416AD4',
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ color: '#393939 !important' }}>
                          {row?.module_name}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={page}
            count={data.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>

        <Box className="d-flex justify-content-between mt-4">
          <Button className="btn-save" onClick={handleBackButton}>Back</Button>
          <div className="d-flex">
            <Button className="btn-cancel me-3" onClick={handleClearState}>Cancel</Button>
            <Button className="btn-save" onClick={handleSave} >Save</Button>
          </div>
        </Box>


      </Box>

    </SideBarContent>
  );

}

const mapStateToProps = (state) => {
  const { roleMasterObj, all_module, action_Type, all_role, rowId } =
    state.role;
  return {
    roleMasterObj,
    all_module,
    action_Type,
    all_role,
    rowId,
  };
};

export default connect(mapStateToProps, {
  getAssignModule,
  getName,
  getPermission,
  createRoleMaster,
  clearRoleState,
  setEditModuleData,
  updateRoleMaster,
  pushMessage,
})(CreateRole);
