// import React from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  TextField,
} from '@mui/material';
// import CustomizedTreeView from "../../component/CustomizedTreeview/CustomizedTreeView";
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import React, { useState, useEffect, useMemo } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { Search } from '@mui/icons-material';
import ProductCategoryMasterModal from './ProductCategoryMasterModal';
import {
  getProductCategoryData,
  resetCategory,
  setSelectedRowData,
  saveEditCategory,
  setRankArray,
  setFreelancerArray,
} from './redux/actions/productcategory-action';
import { connect } from 'react-redux';

export function list_to_tree(list) {
  let map = {},
    node,
    roots = [],
    i;
  //   main_array = []
  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
    list[i].data = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== null) {
      // if you have dangling branches check that map[node.parent_id] exists
      node.data = {
        name: node.title,
        count: node.children_count,
        rank: node.rank,
        status: node.status,
        action: 'Edit',
      };
      list[map[node.parent_id]].children.push(node);

      // main_array.push({data: {name: node.title, count: node.children_count, rank: node.rank, status: node.status, action: "Edit"}});
    } else {
      node.data = {
        name: node.title,
        count: node.children_count,
        rank: node.rank,
        status: node.status,
        action: 'Edit',
      };
      roots.push(node);
      // main_array.push({data: {name: node.title, count: node.children_count, rank: node.rank, status: node.status, action: "Edit", parent_id: node.parent_id}});
    }
  }
  return roots;
}

export function addKeysToNodes(data) {
  let currentKey = 0;

  function assignKeys(node) {
    node.key = currentKey.toString();
    currentKey++;

    if (node.children) {
      node.children.forEach((child) => assignKeys(child));
    }
  }

  data.forEach((root) => assignKeys(root));
  return data;
}

// Function to recursively search for items in the node tree
const searchNode = (node, value) => {
  // Check if the node matches the search criteria
  if (
    node?.title?.toLowerCase().includes(value) ||
    `${node?.rank}`?.toLowerCase().includes(value) ||
    node?.status_text?.toLowerCase().includes(value)
  ) {
    return true;
  }

  // If the node has children, recursively search them
  if (node.children) {
    for (let child of node.children) {
      if (searchNode(child, value)) {
        return true;
      }
    }
  }

  // If no match is found, return false
  return false;
};

function ProductCategoryMaster({
  productCategory,
  getProductCategoryData,
  resetCategory,
  setSelectedRowData,
  selected_row_data,
  saveEditCategory,
  setRankArray,
  setFreelancerArray,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
  const [treeData, setTreeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({});

  useEffect(() => {
    productCategory.sort((a, b) => {
      if (a.rank === 0 && b.rank !== 0) {
        return 1; // Move items with rank 0 to the end
      } else if (a.rank !== 0 && b.rank === 0) {
        return -1; // Move items with rank 0 to the end
      } else if (a.rank === 0 && b.rank === 0) {
        // If both items have rank 0, sort alphabetically by name
        return a.title.localeCompare(b.title);
      } else if (a.rank === b.rank) {
        // If ranks are equal, sort alphabetically by name
        return a.title.localeCompare(b.title);
      } else {
        // Sort by rank
        return a.rank - b.rank;
      }
    });
  }, [productCategory]);

  useEffect(() => {
    const fetchData = async () => {
      await getProductCategoryData();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (productCategory.length > 0) {
      const newProdCategory = productCategory.map((itm) => ({
        ...itm,
        status_text: itm.status ? 'Active' : 'InActive',
      }));
      const categoryTree = list_to_tree(newProdCategory);
      const categoryWithKey = addKeysToNodes(categoryTree);
      setTreeData(categoryWithKey);
    }
  }, [productCategory]);

  useEffect(() => {
    if (searchValue) {
      setPage(0);
      if (searchValue === '') {
        setFilteredData(treeData);
        setExpandedKeys({});
        return;
      }

      // Filter nodes based on the search criteria
      const filteredItems = treeData.filter((node) =>
        searchNode(node, searchValue || '')
      );
      setFilteredData(filteredItems);
    } else {
      setFilteredData(treeData);
      setExpandedKeys({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, treeData]);

  const treeList = useMemo(() => {
    return filteredData?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [filteredData, page, rowsPerPage]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setExpandedKeys({});
  };

  useEffect(() => {
    // const rankArray = productCategory.map((item) => item.rank);
    const rankArrayBusiness = productCategory.map((item) => {
      if (item.category_type === 'Business') {
        return item.rank;
      }
    });
    const rankArrayFreelancer = productCategory.map((item) => {
      if (item.category_type === 'Freelancer') {
        return item.rank;
      }
    });
    const uniqueArray = [...new Set(rankArrayBusiness)];
    const uniqueFreelancerArray = [...new Set(rankArrayFreelancer)];
    setRankArray(uniqueArray);
    setFreelancerArray(uniqueFreelancerArray);
  }, [productCategory]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value?.toLowerCase());
  };

  useEffect(() => {
    const expand = setTimeout(() => {
      let _expandedKeys = { ...expandedKeys };
      if (filteredData?.length > 0 && searchValue) {
        function addKey(arr) {
          arr.forEach((itm) => {
            if (itm?.children?.length > 0) {
              _expandedKeys[itm.key] = true;
              addKey(itm?.children);
            }
          });
        }
        addKey(filteredData);
      }
      setExpandedKeys(_expandedKeys);
    }, 500);

    return () => clearTimeout(expand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, searchValue]);

  const statusBodyTemplate = (rowData) => {
    const statusColor = rowData.status ? '#00B257' : '#F04D23';
    return (
      <span style={{ color: statusColor }}>
        {rowData.status ? 'Active' : 'InActive'}
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <div>
          <EditIcon
            style={{ marginRight: '0.5rem', cursor: 'pointer' }}
            onClick={() => handleEdit(rowData)}
          />
          {/* <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(rowData)}
          /> */}
        </div>
      </div>
    );
  };

  const handleEdit = (rowData) => {
    setSelectedRowData(rowData);
    saveEditCategory(rowData);
    setOpenModal(true);
  };

  const handleDelete = (rowData) => {
    console.log('Delete clicked for row:', rowData);
  };

  const columns = [
    { field: 'name', header: 'Category Name', expander: true },
    {
      field: 'category_type',
      header: 'Category Type',
      body: (rowData) => rowData.category_type,
    },
    { field: 'count', header: 'Category Count' },
    { field: 'rank', header: 'Rank' },
    { field: 'status', header: 'Status', body: statusBodyTemplate },
    { field: 'action', header: 'Actions', body: actionBodyTemplate },
  ];

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    getProductCategoryData();
    setSearchValue('');
    resetCategory();
    setSelectedRowData(null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    getProductCategoryData();
    setOpenModal(false);
  };

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            spacing={2}
            className="align-items-center just-content-between"
          >
            <Grid item xs={10} md={6}>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchValue}
                onChange={(e) => handleSearchChange(e)}
                InputProps={{
                  startAdornment: (
                    <Search
                      sx={{
                        width: '1em',
                        height: '1em',
                        marginRight: '8px',
                        color: '#864FFD',
                      }}
                      color="action"
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={'auto'} className="ms-auto">
              <Button className="btn-create" onClick={handleOpenModal}>
                Create
              </Button>
              <ProductCategoryMasterModal
                open={openModal}
                onClose={handleCloseModal}
                data={treeData}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="mb-4">
          <div className="treedata-card">
            <TreeTable
              value={treeList}
              showGridlines={true}
              onToggle={(e) => setExpandedKeys(e.value)}
              expandedKeys={expandedKeys}
              tableStyle={{ width: '100%' }}
            >
              {columns.map((col, i) => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  expander={col.expander}
                  body={col.body}
                />
              ))}
            </TreeTable>
            <CustomPagination
              page={page}
              count={filteredData?.length || 0}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          </div>
        </Box>
      </Box>
    </SideBarContent>
  );
}

const mapStatetoProps = (state) => {
  return {
    productCategory: state.productcategory.productCategory,
    selected_row_data: state.productcategory.selected_row_data,
  };
};

export default connect(mapStatetoProps, {
  getProductCategoryData,
  resetCategory,
  setSelectedRowData,
  saveEditCategory,
  setRankArray,
  setFreelancerArray,
})(ProductCategoryMaster);
