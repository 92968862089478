import { PUSH_MESSAGE_BOX_MESSAGE, SET_MESSAGE_BOX_LIST } from '../types';

const INITIAL_STATE = {
  messageList: [] /*
    { id: 'testmessage', message: 'MESSAGE CONTENT', open: true },
    {
      id: 'testmessage2',
      message:
        'MESSAGE CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT CONTENT 2',
      open: true,
    },
    */,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUSH_MESSAGE_BOX_MESSAGE:
      return { ...state, messageList: [action.payload, ...state.messageList] };
    case SET_MESSAGE_BOX_LIST:
      return { ...state, messageList: action.payload };
    default:
      return state;
  }
};
