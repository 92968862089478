import { useEffect, useState } from "react";
import reactLogo from "./assets/react.svg";
import "./App.css";
import DashBoard from "./pages/Dashboard/DashBoard";
import Login from "./pages/Login/Login";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore, { history } from "./store/store";
import { ConnectedRouter } from "connected-react-router";
import MessageBox from './component/MessageBox/MessageBox';
import CommonLoading from "./component/CommonLoading";
import Routers from "./navigation/Routers";

// import ServerModal from "./component/Modal";

import Otp from "./pages/Otp/Otp";
import { injectStore } from "./apis/configs/axiosConfig";
import Masters from "./pages/Masters/Masters";
import CreateMasters from "./pages/Masters/CreateMasters";


function App() {
  const { store, persistor } = configureStore();

  useEffect(()=>{
    if (store) injectStore && injectStore(store)
  },[store]);

  return (
   
    <Provider store={store}> 
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <div className="App">
            <Routers />
            <MessageBox />
            {/* <ServerModal /> */}
            {/* <DashBoard /> */}
            {/* <Login /> */}
            <CommonLoading />
            {/* <DashBoard /> */}
            {/* <Otp /> */}
            {/* <Masters /> */}
            {/* <CreateMasters /> */}
          </div>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
