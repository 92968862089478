import Resizer from 'react-image-file-resizer';

/* eslint-disable no-useless-escape */
export const OTP_MIN = 0;
export const OTP_SEC = 30;
export const DEFAULT_COUNTRY_CODE = 'IN';
// export const DATA_STATUS = "Enquiry Status"

export const DATA_STATUS = 'EnquiryStatus';

export const TEMPLATE_TYPE = 'TemplateType';

export function ValidateEmail(inputText) {
  var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export const compressImages = (files) => {
    return Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            500, // New width
            500, // New height
            'JPEG', // Format
            75, // Quality
            0, // Rotation
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      })
    );
  };


export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const REQUIREMENT_DATA_STATUS = 'Requirement';
