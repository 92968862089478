import moment from "moment";
import { TemplateAPI } from "../../../../apis/TemplateAPI";
import { SET_LOADING } from "../../../../store/types"
import { GET_PAGE_DATA, SET_TEMPLATE_ACTION_TYPE, SET_TEMPLATE_CLEAR_DATA, SET_TEMPLATE_EDIT_DATA, SET_TEMPLATE_FILTER, SET_TEMPLATE_TYPE } from "../types";

export const getTemplate = () => {
    return async(dispatch) => {
        dispatch({type: SET_LOADING, payload: true});
        try {
            const response = await TemplateAPI.getTemplateData();
            console.log("template_data :", response);
            if(response?.status === 200) {
                const sortedArray = response?.data?.data?.sort((a,b)=> moment(b.updatedAt) - moment(a.updatedAt))
                dispatch({type: GET_PAGE_DATA, payload: sortedArray});
                dispatch({type: SET_LOADING, payload: false});
            }else{
                dispatch({type: SET_LOADING, payload: false});
            }
        } catch (error) {
          console.log("error :", error);
          dispatch({type: SET_LOADING, payload: false});
        }
    }
}


export const addPageTemplate = (data, header,successCallback=() => {}, errorCallback=() => {}) => {
    return async(dispatch) => {
        dispatch({type: SET_LOADING, payload: true});
        try {
            const response = await TemplateAPI.postTemplateData(data, header);
            if (response.status === 201){
                successCallback(response.data)
                dispatch({ type: SET_LOADING, payload: false });
            } else{
                dispatch({type: SET_LOADING,payload: false});
                errorCallback(response.data);
            }
        } catch (error) {
            dispatch({type: SET_LOADING,payload: false});
            if(error.response.status===401){
                errorCallback(error?.response?.data?.message);  
            }
            else{
                errorCallback(error?.response?.data?.error); 
            };
        }
    }
}

export const editTemplateMaster = (id, data, header,successCallback=() => {}, errorCallback=() => {}) => {
    return async(dispatch) => {
        dispatch({type: SET_LOADING, payload: true})
        try {
            const response = await TemplateAPI.updateTemplateData(id, data, header);
            if (response.status === 200){
                successCallback(response.data)
                dispatch({ type: SET_LOADING, payload: false })
            } else{
                dispatch({type: SET_LOADING,payload: false});
                errorCallback(response.data)
            }
        } catch (error) {
            dispatch({type: SET_LOADING,payload: false});
            if(error.response.status===401){
                errorCallback(error?.response?.data?.message);  
            }
            else{
                errorCallback(error?.response?.data?.error); 
            }
            // errorCallback(error?.response?.data.error);
        }
    }
}


export const setTemplateActionType = (data) => {
    return async(dispatch) => {
        dispatch({type: SET_TEMPLATE_ACTION_TYPE, payload: data})
    }
}

export const setTemplateData = (insert) => {
    return async(dispatch) => {
        dispatch({type: SET_TEMPLATE_EDIT_DATA, payload: insert})
    }
}

export const setClearTemplate = () => {
    return async(dispatch) => {
        dispatch({type: SET_TEMPLATE_CLEAR_DATA})
    }
}

export const setTemplateFilter = (filter) => {
    return async(dispatch) => {
        dispatch({type: SET_TEMPLATE_FILTER, payload: filter})
    }
}

export const getTemplateTypeDetails = () => {
    return async(dispatch) => {
        dispatch({type: SET_LOADING, payload: true});
        try {
            const response = await TemplateAPI.getTemplateTypeData();
            console.log("tresonsedata : ",response?.data);
            if (response.status === 200){
                dispatch({type:SET_TEMPLATE_TYPE, payload: response?.data?.module});
                dispatch({type: SET_LOADING, payload: false});
            }else{
                dispatch({type:SET_LOADING, payload: false});
            }
        } catch (error) {
            dispatch({type:SET_LOADING, payload: false});
           console.log("error", error) 
        }
    }
}