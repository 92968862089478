export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const POST_SUBSCRIPTION = 'POST_SUBSCRIPTION';
export const PUT_SUBSCRIPTION = 'PUT_SUBSCRIPTION';
export const ADD_SINGLE_SUBSCRIPTION = 'ADD_SINGLE_SUBSCRIPTION';
export const PARENT_SUBSCRIPTION = 'PARENT_SUBSCRIPTION';
export const GET_SINGLE_SUBSCRIPTION = 'GET_SINGLE_SUBSCRIPTION';
export const GET_PARENT_SUBSCRIPTION = 'GET_PARENT_SUBSCRIPTION';
export const CLEAR_SINGLE_SUBSCRIPTION_AFTER_EDIT =
  'CLEAR_SINGLE_SUBSCRIPTION_AFTER_EDIT';
export const CLEAR_PARENT_SUBSCRIPTION = 'CLEAR_PARENT_SUBSCRIPTION';
export const CLEAR_SINGLE_SUBSCRIPTION = 'CLEAR_SINGLE_SUBSCRIPTION'