
import {
  SAVE_OTP,
  SAVE_SIGNIN_DATA,
  SET_POST_LOGIN_ROUTE_NAME,
  RESET_OTP_DATA,
} from '../types';


const INITIAL_STATE = {
  otp: '',
  error: '',
  userData: null,
  postLoginRouteName: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_OTP:
      return { ...state, otp: action.payload };

    case SAVE_SIGNIN_DATA:
      return { ...state, userData: action.payload };
    case SET_POST_LOGIN_ROUTE_NAME:
      return { ...state, postLoginRouteName: action.payload };
    case RESET_OTP_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
