import { SET_LOADING, SET_HEADER_TITLE, SET_OPEN_DRAWER, RESET_LOADING_DATA } from "../types"

const INITIAL_STATE = {
    loading: false,
    open: false,
    headerTitle: ''
}


export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SET_OPEN_DRAWER:
            return {...state, open: action.payload}
        case SET_HEADER_TITLE:
            return {...state, headerTitle: action.payload}
        case RESET_LOADING_DATA:
            return state
        default:
            return state
        
    }

}