import api from './configs/axiosConfig';

const AdminTool = '/admin/tools/';
const subscriptionServices = '/subscription-items/search/is_active/true';

export const AdminToolsAPI = {
  getAdminTools: async function () {
    const response = await api.get(AdminTool);
    return response;
  },
  getSubscriptionServices: async function () {
    const response = await api.get(subscriptionServices);
    return response;
  },
  postAdminTools: async function (data) {
    const response = await api.post(AdminTool, data);
    return response;
  },
  updateAdminTools: async function (id, data, header) {
    const response = await api.put(AdminTool + id, data, header);
    return response;
  },
};
