import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';
import { fetchRole } from '../RoleMaster/redux/actions/role-action';
import {
  addUserMaster,
  editUserMaster,
  saveUserCountryCode,
  saveUserCountryCodeName,
  saveUserMobileNo,
  setClearUserData,
  setUpdateRolesData,
  setUserActionType,
  setUserMasterData,
} from './redux/actions/user-action';
import CustomDropdown from '../../component/CustomDropdown';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import { useHistory } from 'react-router';
import useValidation from '../../hooks/useValidation';
import { MuiTelInput } from 'mui-tel-input';
import { maxMobileLengthObj } from '../../utils/countryCode';

function CreateUsers({
  all_role,
  all_user,
  fetchRole,
  setUserMasterData,
  userMasterObj,
  action_Type,
  setUserActionType,
  addUserMaster,
  pushMessage,
  setClearUserData,
  editUserMaster,
  setUpdateRolesData,
  userData,
  country_code_name,
  saveUserMobileNo,
  saveUserCountryCode,
  saveUserCountryCodeName,
  user_mobile_number,
  user_country_code
}) {
  const [error, setError] = useState([]);
  const { validateInput, errors } = useValidation();
  const [mobileNumberLength, setMobileNumberLength] = useState(10);
  const history = useHistory();

  const handleChange = (newValue, info) => {
    saveUserCountryCode(`+${info.countryCallingCode}`);
    if (info.countryCode) {
      saveUserCountryCodeName(info.countryCode);
    }
    if (info?.nationalNumber?.length > mobileNumberLength) {
      pushMessage(`Only ${mobileNumberLength} digits are allowed.`);
      return;
    }
    validateInput('mobileNumber', info?.nationalNumber);
    saveUserMobileNo(info.nationalNumber);
   
  };

  const handleName = (e) => {
    let obj = { ...userMasterObj };
    obj.name = e.target.value;
    validateInput('name', obj?.name.trim());
    setUserMasterData(obj);
  };

  const handleEmail = (e) => {
    let obj = { ...userMasterObj };
    obj.email = e.target.value;
    validateInput('email', obj?.email);
    setUserMasterData(obj);
  };

  const handlePhone = (e) => {
    let obj = { ...userMasterObj };
    obj.mobile_number = e.target.value;
    validateInput('mobileNumber', obj?.mobile_number);
    setUserMasterData(obj);
  };

  const handleDropDown = (e, value) => {
    let selected_roles = [value];
    validateInput('assignArr', selected_roles);
    setUpdateRolesData(selected_roles);
  };

  useEffect(() => {
    fetchRole();
  }, []);

  useEffect(() => {
    const numberLen =
      maxMobileLengthObj(userMasterObj?.country_code || '')
        ?.mobileNumberLength || 0;
    setMobileNumberLength(numberLen || 10);
  }, [userMasterObj?.country_code]);

  const handleSave = async () => {
    console.log('handlesave on user');
    console.log('userMasterObj?.roles :', userMasterObj?.roles);
    if (action_Type === 1) {
      console.log('under action type');
      validateInput('name', userMasterObj?.name?.trim());
      validateInput('email', userMasterObj?.email);
      validateInput('mobileNumber', user_mobile_number);
      validateInput('assignArr', userMasterObj?.roles);
      if (userMasterObj?.name?.trim() === '') {
        return;
      }
      if (userMasterObj?.email === '') {
        return;
      }
      if (user_mobile_number === '') {
        return;
      }
      if (!userMasterObj?.roles || userMasterObj?.roles.length === 0) {
        return;
      }

      const jsonData = JSON.stringify({
        name: userMasterObj?.name,
        email: userMasterObj?.email,
        mobile_number: user_mobile_number,
        roles: [userMasterObj?.roles[0]?.name],
        is_active: userMasterObj?.is_active,
        created_by: userData?.user?.id,
        updated_by: userData?.user?.id,
        country_code: user_country_code,
      });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await addUserMaster(
        jsonData,
        customConfig,
        () => {
          pushMessage('Created Successfully');
          setClearUserData();
          history.push('/users-master');
        },
        (error) => {
          pushMessage(error);
        }
      );
    } else {
      validateInput('name', userMasterObj?.name?.trim());
      validateInput('email', userMasterObj?.email);
      validateInput('mobileNumber', user_mobile_number);
      validateInput('assignArr', userMasterObj?.roles);
      if (userMasterObj?.name.trim() === '') {
        return;
      }
      if (userMasterObj?.email === '') {
        return;
      }
      if (user_mobile_number === '') {
        return;
      }
      if (!userMasterObj?.roles || userMasterObj?.roles.length === 0) {
        return;
      }

      const jsonData = JSON.stringify({
        name: userMasterObj?.name,
        email: userMasterObj?.email,
        mobile_number: user_mobile_number,
        roles: [userMasterObj?.roles[0]?.name],
        is_active: userMasterObj?.is_active,
        updated_by: userData?.user?.id,
        country_code: user_country_code,
      });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      console.log('jsonData:', jsonData);

      await editUserMaster(
        userMasterObj?.id,
        jsonData,
        customConfig,
        () => {
          pushMessage('Successfully updated');
          setClearUserData();
          history.push('/users-master');
        },
        (error) => {
          pushMessage(error);
        }
      );
    }
  };

  const handleCancel = () => {
    setClearUserData();
    history.push('/users-master');
  };

  const handleBack = () => {
    setClearUserData();
    history.push('/users-master');
  };

  // useEffect(() => {
  //     return () => {
  //         setClearUserData();
  //     }
  // },[])

  useEffect(() => {
    console.log('error_dropdown', errors.assign);
  }, [errors.assign]);

  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 10 }}
            className="align-items-center justify-content-between"
          >
            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputName"
              >
                Name
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="inputName"
                  type="text"
                  value={userMasterObj?.name}
                  placeholder="Name"
                  required
                  autoFocus
                  fullWidth
                  disabled={action_Type === 0}
                  margin="none"
                  onChange={handleName}
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="name-helper-text">
                {errors.name}
              </FormHelperText>
              {error.includes('name') && (
                <div style={{ color: 'red' }}>{'User Already Exist'}</div>
              )}
            </Grid>

            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputName"
              >
                Email
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <OutlinedInput
                  id="inputName"
                  type="text"
                  placeholder="Email"
                  value={userMasterObj?.email}
                  required
                  autoFocus
                  fullWidth
                  margin="none"
                  onChange={handleEmail}
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                {errors.email}
              </FormHelperText>
              {error.includes('email') && (
                <div style={{ color: 'red' }}>{'Email Already Exist'}</div>
              )}
            </Grid>
            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputName"
              >
                Phone
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                <MuiTelInput
                  forceCallingCode
                  focusOnSelectCountry
                  onlyCountries={['IN', 'AU']}
                  defaultCountry={country_code_name}
                  fullWidth
                  sx={{ my: 1 }}
                  value={user_mobile_number}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div>
                          {user_mobile_number?.length}/
                          {mobileNumberLength}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormHelperText sx={{ color: 'red' }} id="phoneno-helper-text">
                {errors.mobileNumber}
              </FormHelperText>
              {error.includes('mobile') && (
                <div style={{ color: 'red' }}>{'Phone no Already Exist'}</div>
              )}
            </Grid>
            <Grid item xs={10} md={6}>
              <InputLabel
                className="text-start fs-14 text-black font-skmodernist"
                htmlFor="inputProductCategory"
              >
                Assign Role
              </InputLabel>
              <FormControl fullWidth sx={{ my: 1 }}>
                <CustomDropdown
                  id="roleInput"
                  value={userMasterObj?.roles[0]}
                  options={all_role}
                  inputPlaceholder="Select Roles"
                  onChange={handleDropDown}
                  labelKey="name"
                  optionKey="id"
                  autoComplete="address-level2"
                />
              </FormControl>
              <FormHelperText
                sx={{ color: 'red' }}
                id="select-role-helper-text"
              >
                {errors.assignArr}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 10 }}
            className="align-items-center justify-content-between mt-1"
          >
            <Grid
              item
              xs={10}
              md={6}
              className="col-4 custom-control custom-checkbox"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userMasterObj?.is_active}
                    onChange={(e) => {
                      let obj = { ...userMasterObj };
                      obj.is_active = e.target.checked;
                      setUserMasterData(obj);
                    }}
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>

          <Box className="d-flex justify-content-between mt-3">
            <Button className="btn-save" onClick={handleBack}>
              Back
            </Button>
            <div className="d-flex">
              <Button className="btn-cancel me-3" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="btn-save" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </SideBarContent>
  );
}

const mapStateToProps = (state) => {
  const { all_role } = state.role;
  const { userMasterObj, action_Type, all_user, country_code_name, user_mobile_number, user_country_code } =
    state.user;
  const { userData } = state.signin;
  return {
    all_role,
    userMasterObj,
    action_Type,
    all_user,
    userData,
    country_code_name,
    user_mobile_number,
    user_country_code
  };
};

export default connect(mapStateToProps, {
  fetchRole,
  setUserMasterData,
  setUserActionType,
  addUserMaster,
  pushMessage,
  setClearUserData,
  editUserMaster,
  setUpdateRolesData,
  saveUserMobileNo,
  saveUserCountryCode,
  saveUserCountryCodeName,
})(CreateUsers);
