import React, { useEffect, useState } from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, Grid, Link, Pagination, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
import { useHistory } from 'react-router-dom';
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import { connect } from "react-redux";
import { getTemplate, setClearTemplate, setTemplateActionType, setTemplateData, setTemplateFilter } from "./redux/actions/template-action";


function TemplateList({ getTemplate, all_template, setTemplateActionType, setTemplateData, setTemplateFilter, all_template_filter, setClearTemplate }) {
    //const navigate=useNavigate();
    const [searchText, setSearchText] = useState("");
    // const [rows, setRows] = useState(data);


    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
    const [currentPage, setCurrentPage] = useState(0);

    const handleChangePage = (newPage) => {
        setPage(newPage);
        setCurrentPage((prevPage) => {
            if(prevPage === 0) {
                return newPage
            } else {
                return prevPage
            }
        });
    };


    useEffect(() => {

        if (searchText === "") {
            setTemplateFilter(all_template);
            setPage(currentPage);
        } else {
            setPage(0);
            const filteredItems = all_template?.filter((item) =>
                item?.page_title?.toLowerCase().includes(searchText.toLowerCase()));
            setTemplateFilter(filteredItems);
        }
    }, [all_template, searchText])

    const handleSearch = (e) => {
        const searchTerm = e.target.value.replace(/\s\s+/g, ' ');
        setSearchText(searchTerm, "searchValue");
    };

    const history = useHistory();

    const handleCreateButtonClick = () => {
        setTemplateActionType(1);
        history.push('/create-template'); // Navigate to Createrole page
    };
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };


    useEffect(() => {
        getTemplate();
    }, [])

    useEffect(() => {
        setClearTemplate();
    }, []);


    const handleEditTemplate = (row) => {
        setTemplateData(row);
        setTemplateActionType(0)
        history.push('/create-template'); // Navigate to Createrole page
    }




    return (
        <SideBarContent>
            <Box className="custom-card-bg">
                <Box className="mb-4">
                    <Grid container spacing={2} className="align-items-center just-content-between">
                        <Grid item xs={10} md={6} >
                            <TextField
                                sx={{ height: "50px", width: '400px', borderRadius: '6px' }}
                                variant="outlined"
                                placeholder="Search here..."
                                value={searchText}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <Search
                                            sx={{ width: "1em", height: "1em", marginRight: "8px", color: '#864FFD' }}
                                            color="action"
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={"auto"} className="ms-auto">
                            <Button className="btn-create" onClick={handleCreateButtonClick}>
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Paper sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: "450px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Page Title / Template Name</TableCell>
                                    <TableCell>Page Slug / Subject</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {all_template_filter?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            {row.page_title}
                                        </TableCell>
                                        <TableCell>
                                            {row.page_slug}
                                        </TableCell>
                                        <TableCell style={{textTransform: "capitalize"}}>
                                            {row.type}
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={row?.status ? 'text-status-active' : 'text-status-inactive'}>{row?.status ? "Active" : "Inactive"}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                onClick={() => {
                                                    handleEditTemplate(row)
                                                }}
                                            ><EditIcon /></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <CustomPagination page={page} count={all_template_filter?.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
                </Paper>
            </Box>
        </SideBarContent>
    );
}

const mapStateToProps = (state) => {
    const { all_template, all_template_filter } = state.template;
    return {
        all_template,
        all_template_filter
    }
}

export default connect(mapStateToProps, { getTemplate, setTemplateActionType, setTemplateData, setTemplateFilter, setClearTemplate })(TemplateList);