import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

export default function CommonLoading() {
  const {loading} = useSelector(state => state.root);


  return loading?(
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', position: 'absolute', top: 0, right: 0, left: 0, bottom: -300, background: 'rgb(0,0,0,0.5)' ,zIndex: 9999}}>
      <CircularProgress />
    </Box>
  ):
  null;
}
 