import { PUSH_MESSAGE_BOX_MESSAGE, SET_MESSAGE_BOX_LIST } from '../types';
import { v4 as uuidv4 } from 'uuid';

export const pushMessage = (message) =>
  function (dispatch) {
    console.log("message_push:",message)
    const messageId = uuidv4().substring(0, 13);
    const messageObj = { id: messageId, message, open: true };
    dispatch({ type: PUSH_MESSAGE_BOX_MESSAGE, payload: messageObj });
    return messageId;
  };

export const setMessageList = (messageList) =>
  function (dispatch) {
    dispatch({ type: SET_MESSAGE_BOX_LIST, payload: messageList });
  };
