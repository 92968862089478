export const POST_CREATED_WIDGET = 'POST_CREATED_WIDGET';
export const ADD_SINGLE_WIDGET = 'ADD_SINGLE_WIDGET';
export const GET_ALL_WIDGET = 'GET_ALL_WIDGET';
export const ADD_WIDGET = 'ADD_WIDGET';
export const ADD_WIDGET_DETAILS = 'ADD_WIDGET_DETAILS';
export const GET_WIDGET_DETAILS = 'GET_WIDGET_DETAILS';
export const SET_SELECTED_WIDGET = 'SET_SELECTED_WIDGET';
export const CLEAR_SINGLE_WIDGET = 'CLEAR_SINGLE_WIDGET';
export const ADD_SINGLE_MODULE_WIDGET = 'ADD_SINGLE_MODULE_WIDGET';
export const ADD_WEBCOMPONENT = 'ADD_WEBCOMPONENT';
