import {
  GET_SUBSCRIPTION,
  POST_SUBSCRIPTION,
  ADD_SINGLE_SUBSCRIPTION,
  PARENT_SUBSCRIPTION,
  GET_SINGLE_SUBSCRIPTION,
  GET_PARENT_SUBSCRIPTION,
  CLEAR_SINGLE_SUBSCRIPTION_AFTER_EDIT,
  CLEAR_PARENT_SUBSCRIPTION,
  CLEAR_SINGLE_SUBSCRIPTION,
  //   PUT_SUBSCRIPTION,
} from '../types';

const initial_state = {
  loading: false,
  subscriptions: [],
  singleSubscription: {},
  parentSubscription: {},
};

const socialmediamasterReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case POST_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: { ...state.subscriptions, ...action.payload },
      };
    case ADD_SINGLE_SUBSCRIPTION:
      return {
        ...state,
        singleSubscription: { ...state.singleSubscription, ...action.payload },
      };
    case CLEAR_SINGLE_SUBSCRIPTION_AFTER_EDIT: {
      const ret = {
        ...state,
        singleSubscription: { id: state.singleSubscription.id },
      };
      console.log('====CLEAR_SINGLE_SUBSCRIPTION_AFTER_EDIT ret', ret);
      return ret;
    }
    case GET_SINGLE_SUBSCRIPTION:
      return {
        ...state,
        singleSubscription: action.payload,
      };
    case PARENT_SUBSCRIPTION:
      return {
        ...state,
        parentSubscription: {
          ...state.parentSubscription,
          ...action.payload,
        },
      };
    case CLEAR_PARENT_SUBSCRIPTION:
      return {
        ...state,
        parentSubscription: null,
        singleSubscription: null,
      };
      // case CLEAR_SINGLE_SUBSCRIPTION:
      //   return {...state, singleSubscription: null}
    case GET_PARENT_SUBSCRIPTION:
      return {
        ...state,
        parentSubscription: action.payload,
      };
    // case PUT_SUBSCRIPTION:
    //   return {
    //     ...state,
    //     all_links: [],
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

// Post social all media api reducer

export default socialmediamasterReducer;
