import { Login } from '../../../../apis/LoginAPI';
import { SET_LOADING } from '../../../../store/types';
import {
    SAVE_MOBILE_NO,
    LOADING,
    SAVE_COUNTRY_CODE,
    SAVE_COUNTRY_CODE_NAME,
  } from '../types';
  
  export const verifyMobileRequest = (isLoading) => ({
    type: LOADING,
    payload: isLoading,
  });
  
  export const saveMobileNo = (mobile_no) => {
    return async (dispatch) => {
      try {
        verifyMobileRequest(true);
        await dispatch({
          type: SAVE_MOBILE_NO,
          payload: mobile_no,
        });
        verifyMobileRequest(false);
      } catch (error) {
        console.log('Error saving mobile_no', error);
      }
    };
  };
  
  export const saveCountryCode = (code) => {
    return async (dispatch) => {
      try {
        await dispatch({
          type: SAVE_COUNTRY_CODE,
          payload: code,
        });
      } catch (error) {
        console.log('Error saving country_code', error);
      }
    };
  };
  
  export const saveCountryCodeName = (code) => {
    return async (dispatch) => {
      try {
        await dispatch({
          type: SAVE_COUNTRY_CODE_NAME,
          payload: code,
        });
      } catch (error) {
        console.log('Error saving country_code_name', error);
      }
    };
  };


  export const loginAdminAccess = (data,successCallback=() => {}, errorCallback=() => {}) => {
    console.log("data===========");
    return async(dispatch) => {
      // console.log("data===========");
        dispatch({type: SET_LOADING, payload: true})
        try {
            const response = await Login.userCheckAccess(data);
            if (response.status === 200){
                successCallback(response.data?.admin_access)
                dispatch({ type: SET_LOADING, payload: false })
            } else{

                errorCallback(response.data)
                dispatch({type: SET_LOADING,payload: false})
            }
        } catch (error) {
            dispatch({type: SET_LOADING,payload: false})
            if(error.response.status===401){
                errorCallback(error?.response?.data?.message);  
            }
            else{
                errorCallback(error?.response?.data?.error); 
            };

        }
    }
}