import { HomePageWidgetApi } from "../../../../apis/SettingHomewidgetAPI";
import { SET_LOADING } from "../../../../store/types";
import { GET_HOMEPAGE_WIDGET } from "../types";

export const getHomepageWidgetDetails = () => {
    return async(dispatch) => {
        dispatch({type: SET_LOADING, payload: true});
        try {
            const response = await HomePageWidgetApi.getHomepageWidget();
            if (response.status === 200){
                dispatch({type: GET_HOMEPAGE_WIDGET, payload: response?.data?.data});
                dispatch({type: SET_LOADING, payload: false});
            }
            else {
                dispatch({type: SET_LOADING, payload: false});
            }
        } catch (error) {
            console.log("error on homepageWidget", error);
            dispatch({type: SET_LOADING, payload: false});
        }
    }
}


export const setWidgetData = (widget) => {
    return (dispatch) => {
        dispatch({type : GET_HOMEPAGE_WIDGET, payload: widget});
    }
}



export const editHomepageWidget = (data,successCallback=() => {}, errorCallback=() => {}) => {
    return async(dispatch) => {
        dispatch({type: SET_LOADING, payload: true})
        try {
            const response = await HomePageWidgetApi.postHomepageWidget(data);
            if (response.status === 201){
                successCallback(response.data)
                dispatch({ type: SET_LOADING, payload: false });
            } else{

                errorCallback(response.data)
                dispatch({type: SET_LOADING,payload: false});
            }
        } catch (error) {
            dispatch({type: SET_LOADING,payload: false});
            if(error.response.status===401){
                errorCallback(error?.response?.data?.message);  
            }
            else{
                errorCallback(error?.response?.data?.error); 
            }
            // errorCallback(error?.response?.data.error);
        }
    }
}