import React from 'react'
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { Box, Typography } from "@mui/material";

function DashBoard() {
  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <div>
          <Typography className="text-center fs-20">Page is Under Construction</Typography>
        </div>
      </Box>
    
    </SideBarContent>
  )
}

export default DashBoard;