import React, { useEffect, useState } from "react";
// import CommonTable from "../../component/CommonTable";
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CustomPagination from "../../component/CustomPagination/CustomPagination";
import SideBarContent from "../../component/SideBarContent/SideBarContent";
import { ReactComponent as ProductImage1 } from "../../assets/product1.svg";
import FreelancerRejectModal from "./FreelancerRejectModal";




function FreelancerProfileView() {
    //const navigate=useNavigate();
    const [searchText, setSearchText] = useState("");
    // const [rows, setRows] = useState(data);


    // useEffect(() => {
    //     console.log("Filtered items:", rows);
    // }, [rows]);

    // const handleSearch = (e) => {
    //     const searchTerm = e.target.value.toLowerCase();
    //     setSearchText(searchTerm, "searchValue");
    //     if (searchTerm === "") {
    //         // If search term is empty, display the original list
    //         setRows(data);
    //     } else {
    //         // Filter the list based on the search term
    //         const filteredItems = rows.filter((item) =>
    //             item.name.toLowerCase().includes(searchTerm)
    //         );
    //         setRows(filteredItems);
    //     }
    // };
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };

    // const data = [
    //     { id: 1, name: 'Facebook', status: 'Active', logo: <FacebookLogo />, editIcon: <EditIcon /> },
    //     { id: 2, name: 'Instagram', status: 'Inactive', logo: <InstagramLogo />, editIcon: <EditIcon /> },
    //     { id: 3, name: 'WhatsApp', status: 'Active', logo: <WhatsappLogo />, editIcon: <EditIcon /> },
    //     { id: 4, name: 'Telegram', status: 'Inactive', logo: <TelegramLogo />, editIcon: <EditIcon /> },
    //     { id: 5, name: 'Instagram', status: 'Inactive', logo: <InstagramLogo />, editIcon: <EditIcon /> },
    //     { id: 6, name: 'Facebook', status: 'Active', logo: <FacebookLogo />, editIcon: <EditIcon /> },

    // ];


    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    return (
        <SideBarContent className="side-test">
            <Box className="custom-card-bg">
                <Box className="mb-4">
                    <Box className="d-flex justify-content-end mb-4">
                        <Button className="btn-approve me-3">Approve</Button>
                        {/* <Button className="btn-hold me-3">Hold</Button> */}
                        <Button className="btn-reject" onClick={handleOpenModal}>Reject</Button>

                        <FreelancerRejectModal open={openModal} onClose={handleCloseModal} />
                    </Box>
                    <Box className="mb-3">
                        <Typography className="fs-20 fw-bold text-black font-skmodernist">Personal Details</Typography>
                        <Divider className="personal-divider my-3"></Divider>

                        <Grid container columnSpacing={4} rowSpacing={4}>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Name</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">Varsha Mali</Typography>
                            </Grid>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Mobile No.</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">9542568793</Typography>
                            </Grid>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Email</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">mukta@abc.com</Typography>
                            </Grid>
                        </Grid>
                        <Divider className="main-divider my-3"></Divider>
                        <Grid container columnSpacing={4} rowSpacing={4}>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Freelancer Category</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">---</Typography>
                            </Grid>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Date of Birth</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">20 - 05 - 1994</Typography>
                            </Grid>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Education</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">MCA</Typography>
                            </Grid>
                        </Grid>
                        <Divider className="main-divider my-3"></Divider>
                        <Grid container columnSpacing={4} rowSpacing={4}>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Language</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">Hindi, English</Typography>
                            </Grid>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Certification</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-secondary font-skmodernist">Google Certification</Typography>
                            </Grid>
                        </Grid>
                        <Divider className="main-divider my-3"></Divider>
                    </Box>
                    <Box className="mb-3">
                        <Typography className="fs-20 fw-bold text-black font-skmodernist">Professional Details</Typography>
                        <Divider className="personal-divider my-3"></Divider>
                        <Grid container columnSpacing={4} rowSpacing={4}>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Resume</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-secondary font-skmodernist">Varsha Resume.pdf</Typography>
                            </Grid>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Your Professional Role</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">Manager</Typography>
                            </Grid>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Bio</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">---</Typography>
                            </Grid>
                        </Grid>
                        <Divider className="main-divider my-3"></Divider>
                        <Grid container columnSpacing={4} rowSpacing={4} alignItems={"start"}>
                            <Grid item md={4} className="d-flex justify-content-between align-items-start mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Projects</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">Mobile Development <br></br>UI Design</Typography>
                            </Grid>
                            <Grid item md={4} className="d-flex justify-content-between align-items-end mb-2 mb-2">
                                <InputLabel className="text-start w-50 fs-15 text-darkgrey font-skmodernist">Hourly Base Rate</InputLabel>
                                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">1080</Typography>
                            </Grid>
                        </Grid>
                        <Divider className="main-divider my-3"></Divider>

                    </Box>


                    <Typography className="fs-20 fw-bold text-black font-skmodernist">Service Details</Typography>
                    <Divider className="personal-divider my-3"></Divider>

                    <Paper sx={{ width: "100%" }}>
                        <TableContainer sx={{ maxHeight: "450px" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Service Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => ( */}
                                    <TableRow>
                                        <TableCell >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="w-50 d-flex">
                                                    <div className="me-3">
                                                        <ProductImage1 className="media" />
                                                    </div>
                                                    <div className="">
                                                        <Typography variant="h5"
                                                            className="fs-16 text-black mb-2 fw-normal font-skmodernist"
                                                            component="div">Web Development</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            className="fs-14 text-grey mb-2 font-gotham"
                                                        >
                                                            Quick delivery code to build
                                                            your app within days.
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="w-50">
                                                    <Typography
                                                        variant="h5"
                                                        className="fs-13 text-darkgrey mb-0 font-skmodernist"
                                                    >
                                                        Starting at
                                                    </Typography>

                                                    <Typography
                                                        variant="h5"
                                                        className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                                                    >
                                                        ₹ 1000/
                                                        <span className="fs-13 text-grey fw-light">
                                                            Hour
                                                        </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </TableCell>

                                    </TableRow>

                                    <TableRow>
                                        <TableCell >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="w-50 d-flex">
                                                    <div className="me-3">
                                                        <ProductImage1 className="media" />
                                                    </div>
                                                    <div className="">
                                                        <Typography variant="h5"
                                                            className="fs-16 text-black mb-2 fw-normal font-skmodernist"
                                                            component="div">Light Fixtures</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            className="fs-14 text-grey mb-2 font-gotham"
                                                        >
                                                            Best in class, stylish light fixtures.
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="w-50">
                                                    <Typography
                                                        variant="h5"
                                                        className="fs-13 text-darkgrey mb-0 font-skmodernist"
                                                    >
                                                        Starting at
                                                    </Typography>

                                                    <Typography
                                                        variant="h5"
                                                        className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                                                    >
                                                        ₹ 135/
                                                        <span className="fs-13 text-grey fw-light">
                                                            Piece
                                                        </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </TableCell>

                                    </TableRow>

                                    <TableRow>
                                        <TableCell >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="w-50 d-flex">
                                                    <div className="me-3">
                                                        <ProductImage1 className="media" />
                                                    </div>
                                                    <div className="">
                                                        <Typography variant="h5"
                                                            className="fs-16 text-black mb-2 fw-normal font-skmodernist"
                                                            component="div">Light Fixtures</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            className="fs-14 text-grey mb-2 font-gotham"
                                                        >
                                                            Best in class, stylish light fixtures.
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="w-50">
                                                    <Typography
                                                        variant="h5"
                                                        className="fs-13 text-darkgrey mb-0 font-skmodernist"
                                                    >
                                                        Starting at
                                                    </Typography>

                                                    <Typography
                                                        variant="h5"
                                                        className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                                                    >
                                                        ₹ 135/
                                                        <span className="fs-13 text-grey fw-light">
                                                            Piece
                                                        </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </TableCell>

                                    </TableRow>

                                    <TableRow>
                                        <TableCell >
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="w-50 d-flex">
                                                    <div className="me-3">
                                                        <ProductImage1 className="media" />
                                                    </div>
                                                    <div className="">
                                                        <Typography variant="h5"
                                                            className="fs-16 text-black mb-2 fw-normal font-skmodernist"
                                                            component="div">Light Fixtures</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            className="fs-14 text-grey mb-2 font-gotham"
                                                        >
                                                            Best in class, stylish light fixtures.
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="w-50">
                                                    <Typography
                                                        variant="h5"
                                                        className="fs-13 text-darkgrey mb-0 font-skmodernist"
                                                    >
                                                        Starting at
                                                    </Typography>

                                                    <Typography
                                                        variant="h5"
                                                        className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                                                    >
                                                        ₹ 135/
                                                        <span className="fs-13 text-grey fw-light">
                                                            Piece
                                                        </span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </TableCell>

                                    </TableRow>
                                    {/* ))} */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <CustomPagination page={page} count={data.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} /> */}
                    </Paper>


                </Box>



            </Box>
        </SideBarContent >
    );
}

export default FreelancerProfileView;
