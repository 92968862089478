// import { LOADING } from '../../../Login/redux/types';
import {
  SAVE_OTP,
  SAVE_SIGNIN_DATA,
  SET_POST_LOGIN_ROUTE_NAME,
  RESET_OTP_DATA,
  // FILTER_NAVIGATION,
  // FILTER_SETTING_NAVIGATION,
} from '../types';


// import { postSignInUser } from '../../../../utils/APIs/endpoints';
import { Login } from '../../../../apis/LoginAPI';
import { RESET_LOGIN_DATA } from '../../../Login/redux/types';
import { RESET_SOCIAL_LINK } from '../../../SocialMediaMaster/redux/types';
import { RESET_CITY_DATA } from '../../../City/redux/types';
import { resetLoading } from '../../../../store/actions/rootActions';
import { SET_LOADING } from '../../../../store/types';
// import { navigationConfig, otherNavigationConfig } from '../../../Dashboard/SideList';



export const saveOTPNo = (otp_no) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: SAVE_OTP,
        payload: otp_no,
      });
    } catch (error) {
      console.log('Error saving mobile_no', error);
    }
  };
};

export const sendSigninData = (
  signInData,
  // history,
  successCallback = () => { }
) =>
  async function (dispatch) {
    try {
      const response = await Login.userLogin({
        mobile_number: signInData.mobile_number,
        country_code: signInData.country_code,
        otp: signInData.otp,
      });
      console.log('SUMBIT RESPONSE', response);
      if (response.status === 200) {
        await dispatch({ type: SAVE_SIGNIN_DATA, payload: response.data });
        successCallback(response.data);
        // history.replace('/');
      } else{
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        dispatch({ type: SET_LOADING, payload: false });
        alert("User doesn't exist");
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        alert(error?.message + '\n' + error?.response?.data?.message);
      }
    }
  };

export const setUserData = (usrData) =>
  function (dispatch) {
    console.log('action', usrData);
    dispatch({ type: SAVE_SIGNIN_DATA, payload: usrData });
  };


export const resetUserData = () =>
  async function (dispatch) {
    console.log("Logging out")
    await dispatch({ type: RESET_OTP_DATA });
    await dispatch({ type: RESET_LOGIN_DATA });
    await dispatch({ type: RESET_SOCIAL_LINK });
    await dispatch({ type: RESET_CITY_DATA });
    resetLoading();
  };
