export const SAVE_MOBILE_NO = "SAVE_MOBILE_NO";
export const SEND_MOBILE_NO = "SEND_MOBILE_NO";

export const LOADING = "MOBILE_LOADING";

export const SAVE_COUNTRY_CODE = "SAVE_COUNTRY_CODE";
export const SAVE_COUNTRY_CODE_NAME = "SAVE_COUNTRY_CODE_NAME";

export const RESET_LOGIN_DATA = 'RESET_LOGIN_DATA';

// export const CHECKED_IS_ADMIN = 'CHECKED_IS_ADMIN';
