import { useState, useEffect } from 'react';
import { Button, Container, Grid,InputAdornment, InputLabel, Typography, } from '@mui/material';
import "../../index.css";
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as CallLogo } from '../../assets/mobile-icon.svg';
import {
    MuiTelInput,
} from 'mui-tel-input';
import { connect } from 'react-redux';
import { loginAdminAccess, saveCountryCode, saveCountryCodeName, saveMobileNo } from './redux/actions/login-action';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import { maxMobileLengthObj } from '../../utils/countryCode'
import { useHistory } from 'react-router';



function Login({ mobile_number,
    country_code,
    country_code_name,
    saveCountryCode,
    saveCountryCodeName,
    saveMobileNo,
    pushMessage,
    loginAdminAccess
}) {
    const [mobileNumber, setMobileNumber] = useState(''); // 8793655054
    const [mobileNumberLength, setMobileNumberLength] = useState(10);
    const [error, setError] = useState();
    const history = useHistory();

    const handleChange = (newValue, info) => {
        saveCountryCode(`+${info.countryCallingCode}`);
        if (info.countryCode) {
            saveCountryCodeName(info.countryCode);
        }
        if (info?.nationalNumber?.length > mobileNumberLength) {
            pushMessage(`Only ${mobileNumberLength} digits are allowed.`);
            return;
        }
        saveMobileNo(info.nationalNumber);
        setMobileNumber(info.nationalNumber);

    };

    useEffect(() => {
        const numberLen = maxMobileLengthObj(country_code).mobileNumberLength;
        setMobileNumberLength(numberLen || 10);
    }, [country_code]);

    useEffect(() => {
        console.log(mobile_number);
        console.log(country_code);
        console.log(country_code_name);
    }, [mobile_number, country_code, country_code_name])


    const handleSave = async() => {
        console.log("Mobile_no: ", typeof mobile_number)
        if (mobile_number === "") {
            setError("Please enter your mobile number.")
        }
        else if (mobile_number.length < 10) {
            // history.replace("/login");
            setError("Please enter 10 digit number")
        }
        else {
            
            await loginAdminAccess({mobile_number:mobile_number},(admin_access) => {
                if(admin_access){
                    history.replace("/otp");
                }
                else{
                    pushMessage("Your are not Admin User")
                }
            },() => {
                pushMessage("Your are not Admin User")
            })
           
        }

    }

    return (
        <Container maxWidth="xl" className="p-0">
            <Grid container className="root" style={{ height: '100vh' }}> {/* Apply class name from CSS */}
                {/* The image half */}
                <Grid item md={7} lg={7} className="bgImage"> {/* Apply class name from CSS */}

                </Grid>

                {/* The content half */}
                <Grid item md={5} lg={5} xs={12} className="bgMain" sx={{ margin: 'auto' }}> {/* Apply class name from CSS */}
                    <div className="login">
                        {/* Demo content */}
                        <Container>
                            <Grid justifyContent="center" display={'flex'} alignContent={'center'}>

                                <Grid item xs={12} md={8} lg={8} xl={8}>
                                    <div className="d-flex align-items-end mb-2">
                                        <Typography
                                            variant="h4"
                                            align="left"
                                            alignItems="center"
                                            className="fs-34 mb-0 me-2 fw-bold text-black font-skmodernist"
                                            gutterBottom>
                                            Login to
                                        </Typography>
                                        <ElynkerLogo />
                                    </div>

                                    <Typography
                                        variant="body1"
                                        align="left"
                                        className="fs-14 text-grey fw-normal font-gotham"
                                        gutterBottom>
                                        Please enter your Mobile No.
                                    </Typography>
                                    <form style={{ marginTop: '50px' }}>
                                        <InputLabel
                                            htmlFor="inputMobile"
                                            className="text-start fs-14 text-black fw-normal font-skmodernist">
                                            Mobile Number
                                        </InputLabel>
                                        <div className="position-relative mb-2">
                                            <MuiTelInput
                                                forceCallingCode
                                                error
                                                focusOnSelectCountry
                                                color={'error'}
                                                helperText={error}
                                                // disableFormatting
                                                onlyCountries={['IN', 'AU']}
                                                defaultCountry={country_code_name}
                                                fullWidth
                                                sx={{ my: 1 }}
                                                value={mobile_number}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <div>
                                                                {mobile_number.length}/{mobileNumberLength}
                                                            </div>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>

                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            className="mt-3 py-2 primary-color-1 fs-15 font-skmodernist fw-bold"
                                            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                                            onClick={handleSave}>
                                            Login
                                        </Button>
                                    </form>
                                </Grid>

                            </Grid>

                        </Container>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

const mapStateToProps = state => {
    const { mobile_number,
        country_code,
        loading,
        error,
        country_code_name } = state.mobile;
    return {
        mobile_number,
        country_code,
        loading,
        error,
        country_code_name
    }
}

export default connect(mapStateToProps, { saveCountryCode, saveCountryCodeName, saveMobileNo, pushMessage, loginAdminAccess })(Login);
